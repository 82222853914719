import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faNewspaper, faChartPie, faUsers, faLink, faCode, faListAlt, faMailBulk, faTags, faChartBar, faRobot, faDatabase, faMoneyBill, faGift, faGamepad, faBullhorn, faTools, faThumbsUp, faDollarSign, faVial } from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

function PublishersNewsletterPage() {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>Engage Your Audience with Custom Newsletters | Glitch Gaming</title>
                <meta name="description" content="Craft vibrant custom newsletters with Glitch's built-in builder. Integrate seamlessly with your gaming community and keep players informed with analytics." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Engage Your Audience with Custom Newsletters | Glitch" />
                <meta property="og:description" content="Craft vibrant custom newsletters with Glitch's built-in builder. Integrate seamlessly with your gaming community and keep players informed with analytics." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/newsletter_banner.webp" />
            </Helmet>
            <Header />
            <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <h1 style={{ fontSize: "2.5rem" }}><br /><br />Engage Your Audience<br /><br />With Custom Newsletters</h1>
            </section>

            {/* Main Content */}
            <section className="py-5">
                <div className="container">
                    {/* Statistics Section */}
                    <div className="mb-5 text-center">
                        <h2 className="mb-4">Newsletters Have High Engagement</h2>
                        <p className="lead">Email marketing can boost a video game’s success by directly engaging players with updates, exclusive content, and event invites, keeping the game top of mind and fostering loyalty.</p>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faChartPie} size="3x" className="text-primary mb-3" />
                                <h4>32% Open Rate</h4>
                                <p>Game-related emails achieve a 32% open rate, indicating strong engagement.</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faChartBar} size="3x" className="text-success mb-3" />
                                <h4>3.75% CTR</h4>
                                <p>Higher than the average click-through rate, driving actions like downloads and purchases.</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faDollarSign} size="3x" className="text-warning mb-3" />
                                <h4>$42 ROI</h4>
                                <p>Average return of $42 for every $1 spent on email marketing.</p>
                            </div>
                        </div>
                    </div>

                    {/* Feature 1 */}
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4">Built-in Newsletter Builder</h2>
                            <p className="lead">Create vibrant and engaging newsletters using our intuitive builder. Customize templates to match your game's branding.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faEnvelope} className="me-2 text-primary" /> Easy-to-Use Editor</li>
                                <li><FontAwesomeIcon icon={faNewspaper} className="me-2 text-success" /> Customizable Templates</li>
                            </ul>
                            <form className="contact-form mt-4" id="contact-form" method="POST">
                                <div className="form-group w-100 text-center">
                                    <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Get Started for Free</span></Link>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/images/publishers/newsletter_builder.png" alt="Newsletter Builder" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* Feature 2 */}
                    <div className="row align-items-center mb-5 bg-light p-4 rounded">
                        <div className="col-lg-6 order-lg-2 text-black">
                            <h2 className="mb-4 text-black">Seamless Community Integration</h2>
                            <p className="lead">Your newsletters tie directly into your Glitch gaming community, ensuring your players stay connected and engaged.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faUsers} className="me-2 text-info" /> Engage Your Community</li>
                                <li><FontAwesomeIcon icon={faLink} className="me-2 text-danger" /> Integrate Sign-Up Forms</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <img src="/assets/images/publishers/gaming_community.jpg" alt="Community Integration" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* Feature 3 */}
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4">Flexible Sign-Up Options</h2>
                            <p className="lead">Users can sign up via standalone forms, integrated community forms, or use our APIs to create custom sign-up workflows.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faCode} className="me-2 text-warning" /> API Integration</li>
                                <li><FontAwesomeIcon icon={faListAlt} className="me-2 text-success" /> Custom Sign-Up Forms</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/images/publishers/newsletter_community.jpg" alt="Flexible Sign-Up Options" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* Feature 4 */}
                    <div className="row align-items-center mb-5 bg-light p-4 rounded">
                        <div className="col-lg-6 order-lg-2 text-black">
                            <h2 className="mb-4 text-black">Categorize Your Newsletters</h2>
                            <p className="lead">Organize your newsletters into categories like Game Updates, Patches, Events, Exclusive Content, and more to target your audience effectively.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faMailBulk} className="me-2 text-primary" /> Targeted Content</li>
                                <li><FontAwesomeIcon icon={faTags} className="me-2 text-danger" /> Manage Multiple Categories</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <img src="/assets/images/publishers/newsletter_signup_options.jpg" alt="Newsletter Categories" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* Feature 5 */}
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4">Detailed Analytics</h2>
                            <p className="lead">Track the performance of your newsletters with analytics on delivery, open rates, clicks, and spam reports.</p>
                            <ul className="lead">
                                <li><FontAwesomeIcon icon={faChartPie} className="me-2 text-success" /> Delivery and Open Rates</li>
                                <li><FontAwesomeIcon icon={faChartBar} className="me-2 text-warning" /> Click and Spam Reports</li>
                            </ul>
                            <form className="contact-form mt-4" id="contact-form" method="POST">
                                <div className="form-group w-100 text-center">
                                    <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Sign Up Now</span></Link>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/images/publishers/web_analytics.png" alt="Newsletter Analytics" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* CTA Section */}
                    <div className='pt-5 pb-5 bg-dark text-white text-center rounded' style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}>
                        <h2 className="text-danger mb-4">Enhance Player Retention and Engagement</h2>
                        <p className="lead">Regular newsletters keep players informed about updates, events, and new content, fostering a sense of community and encouraging continued engagement with the game.</p>
                        <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-lg btn-danger text-white">Start Your Newsletter Now</Link>
                        <p className="lead mt-4">Discover more about the impact of newsletters on game success.</p>
                        <a href="https://glitch.ghost.io/newsletters-boost-game-engagement/" target='_blank' className="btn btn-outline-light">Read Full Article</a>
                    </div>

                    <IndieSubmenu />

                </div>
            </section>

            <Footer />
        </>
    );
}

const bannerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/newsletter_banner.webp")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublishersNewsletterPage;
