import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet-async';

const PublisherTutorialCampaignsOverviewPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Getting Started with Glitch: A Quick Overview | Glitch Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to get started with Glitch, a platform that connects you with influencers to promote your game and build your game's community. This tutorial covers creating campaigns, inviting influencers, and increasing engagement."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="Getting Started with Glitch: A Quick Overview | Glitch Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial provides a quick overview of how to use Glitch to connect with influencers, promote your game, and build a community. Learn the basics of campaign creation and influencer management."
                />
                
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Getting Started with Glitch: A Quick Overview | Glitch Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn how to get started with Glitch, a platform that connects you with influencers to promote your game and build your game's community. This tutorial covers creating campaigns, inviting influencers, and increasing engagement."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Getting Started with Glitch: A Quick Overview</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/lKbuXSjl_GA"
                                title="Getting Started with Glitch"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Welcome to Glitch!</h3>
                        <p>
                            In this short tutorial, we’ll give you an overview of the Glitch platform and show you how to get started. Glitch is designed to connect you with influencers who can help promote your game and grow your community.
                        </p>

                        <h4>Step 1: Create a Campaign</h4>
                        <p>
                            To get started, create a campaign. You have the option to create it manually or use AI to assist you. The process is simple and straightforward, ensuring you can quickly set up your campaign.
                        </p>

                        <h4>Step 2: Invite Influencers</h4>
                        <p>
                            After setting up your campaign, browse through our database of thousands of influencers. You can invite those who you think will best represent your game and help boost its visibility.
                        </p>

                        <h4>Step 3: Review and Pay Influencers</h4>
                        <p>
                            Once influencers start promoting your game, you can review their content to ensure it aligns with your objectives. After approving their work, you can proceed to pay them directly through the platform.
                        </p>

                        <h4>Step 4: Build Your Game’s Community</h4>
                        <p>
                            In addition to working with influencers, Glitch offers a community platform where you can engage with your users and further increase engagement around your game. This helps to build a loyal fanbase and drive long-term success.
                        </p>

                        <p>
                            By following these steps, you’ll be well on your way to leveraging Glitch for successful game promotion and community building.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialCampaignsOverviewPage;
