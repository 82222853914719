import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMoneyBillWave, faBroadcastTower, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import SignUpCreators from "../../component/section/signup_creators";
import CreatorAppDownload from "../../component/section/creators/creator_app_download";
import Footer from "../../component/layout/footer";
import CreatorLinks from "../../component/section/creators/creator_links";
import { Link, useNavigate } from "react-router-dom";
import Navigate from "../../../../util/Navigate";

const CreatorsTiktokStreamPage = () => {

    const navigate = useNavigate();
    const currentUrl = window.location.href;

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>How To Stream To TikTok | Glitch Gaming</title>
                <meta name="description" content="Learn how to set up TikTok with Glitch to stream your game live." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Streaming App Optimized For Gaming | Glitch" />
                <meta property="og:description" content="Learn how to set up TikTok with Glitch to stream your game live." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/creators/tiktok_banner.webp" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Streaming App Optimized For Gaming | Glitch" />
                <meta name="twitter:description" content="Learn how to set up TikTok with Glitch to stream your game live." />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/creators/tiktok_banner.webp" />
            </Helmet>

            <Fragment>
                <Header position="relative" />
                <section className="pageheader-section-min">
                    <div className="container mt-2">
                        <div className="section-wrapper text-center">
                            <div className="pageheader-thumb mb-4"></div>
                            <h2 className="pageheader-title">How To Stream To TikTok With Glitch</h2>
                            <p className="lead">Setup Live Streams to TikTok</p>
                        </div>
                    </div>
                </section>

                <div className="container my-5">
                    <p className="lead text-center">Learn how to live stream your gameplay to TikTok from the Glitch Streaming App.</p>

                    <hr />

                    <h3 className='mt-5'>Step 1) Meet TikTok's Requirements</h3>
                    <ul>
                        <li>Ensure you have a TikTok account with live streaming enabled. This feature is currently available for users with over 1,000 followers.</li>
                    </ul>

                    <h3 className='mt-5'>Step 2) Obtain Stream Key and URL from TikTok</h3>
                    <ul>
                        <li>Open the TikTok app on your mobile device.</li>
                        <li>Tap the "+" icon at the bottom of the screen.</li>
                        <li>Select "Live" from the options.</li>
                        <li>Tap on the "Settings" or gear icon to access the streaming settings.</li>
                        <li>Copy the Stream Key and Server URL provided. You will need these for configuring the Glitch app.</li>
                    </ul>

                    <h3 className='mt-5'>Step 3) Download The Streaming App</h3>
                    <p>Start by downloading our <Link target='_blank' to={Navigate.creatorsPage()}>streaming app here</Link>. Our streaming app not only facilitates multicasting to Twitch, YouTube, and other sites but also serves as the application for creating and uploading content.</p>
                    <CreatorAppDownload />

                    <h3 className='mt-5'>Step 4) Authenticate With TikTok</h3>
                    <p>In the application, authenticate with TikTok. This is required if you want the statistics, chat messages, and live engagement of your TikTok stream.</p>
                    <img src="/assets/images/creators/auth_tiktok.gif" alt="Authenticate Tiktok" className="img-fluid" />

                    <h3 className='mt-5'>Step 5) Configure RTMP Settings in Glitch</h3>
                    <ul>
                        <li>Open the Glitch streaming app.</li>
                        <li>Navigate to the multicasting section in the app settings.</li>
                        <li>Enter the RTMP URL and Stream Key you obtained from TikTok into the respective fields.</li>
                        <li>Set the GOP, FPS, Bitrate, and Maxbitrate according to your streaming preferences and internet connection capability. Recommended settings:
                            <ul>
                                <li><strong>GOP:</strong> 2 seconds</li>
                                <li><strong>FPS:</strong> 30 or 60 (depending on your hardware and internet speed)</li>
                                <li><strong>Bitrate:</strong> 2500 - 4000 kbps for 720p, 4000 - 6000 kbps for 1080p</li>
                                <li><strong>Maxbitrate:</strong> 6000 kbps</li>
                            </ul>
                        </li>
                    </ul>

                    <img src="/assets/images/creators/add_tiktok_rtmp.gif" alt="Authenticate Tiktok" className="img-fluid" />


                    <h3 className='mt-5'>Step 6) Start Streaming</h3>
                    <ul>
                        <li>Once you have configured all the settings, click on "Start Streaming" in the Glitch app.</li>
                        <li>Go back to the TikTok app on your mobile device and start your live stream.</li>
                    </ul>

                    <h3 className='mt-5'>Step 7) Monitor and Engage</h3>
                    <ul>
                        <li>Monitor your stream's health and viewer engagement using the Glitch app's dashboard.</li>
                        <li>Engage with your viewers by responding to comments and interactions in real-time.</li>
                    </ul>

                    <img src="/assets/images/creators/engagement_streams.gif" alt="Manage Multiple Streams" className="img-fluid rounded shadow" />
                     
                </div>
                <Footer />
            </Fragment>
            
        </>
    );
}

export default CreatorsTiktokStreamPage;
