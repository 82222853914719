import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet-async';

const PublisherTutorialSchedulerCreatePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>How to Use the Social Scheduler on Glitch | Automate Your Posts</title>
                <meta
                    name="description"
                    content="Learn how to use the Social Scheduler on Glitch to automate your social media posts for games. Save time and grow your online presence with ease."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Use the Social Scheduler on Glitch | Automate Your Posts" />
                <meta
                    property="og:description"
                    content="This tutorial shows you how to set up and use the Social Scheduler on Glitch to automate your social media posts, manage updates, and maintain a consistent online presence."
                />
                
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Use the Social Scheduler on Glitch | Automate Your Posts" />
                <meta
                    name="twitter:description"
                    content="Discover how the Social Scheduler on Glitch simplifies your social media strategy. Automate posts, connect platforms, and grow your game's visibility."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Use the Social Scheduler</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/EUskbnAR40c"
                                title="Using the Social Scheduler on Glitch"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            Welcome to the Social Scheduler tutorial! This tool in Glitch helps automate your social media posts, saving time and boosting your game’s online presence. In this guide, we’ll walk you through the steps to set up your scheduler, create engaging posts, and connect social accounts seamlessly.
                        </p>

                        <h4>Getting Started</h4>
                        <p>
                            Begin by clicking the <strong>Create Schedule</strong> button. You can choose to create a schedule manually or let the AI assist you. If your game is listed in our database, AI can pre-fill the details for you.
                        </p>
                        <p>
                            If your game isn’t in the database, simply select the manual option and fill out the required fields.
                        </p>

                        <h4>Filling Out Game Details</h4>
                        <p>
                            The scheduler needs information about your game to create tailored content. While some fields are optional, providing more details ensures better-quality posts. Include your game’s name, description, and any media assets like images or videos.
                        </p>

                        <h4>Setting Up Your Schedule</h4>
                        <p>
                            Next, customize your posting schedule:
                        </p>
                        <ul>
                            <li>Enter a name for the schedule.</li>
                            <li>Decide how many posts you want to create weekly.</li>
                            <li>
                                Set email reminders to prompt updates. These updates will be converted into social content.
                            </li>
                            <li>
                                Define the tone or voice of your content. Use descriptive adjectives to match your game’s branding, ensuring consistency across all posts.
                            </li>
                        </ul>

                        <h4>Adding Hashtags</h4>
                        <p>
                            You can also add hashtags relevant to your game. Hashtags help your posts reach a broader audience and improve discoverability.
                        </p>

                        <h4>Connecting Social Media Accounts</h4>
                        <p>
                            The Social Scheduler supports multiple platforms, including Discord, Twitter, TikTok, Instagram, and Facebook. Connect the accounts where you’d like to share content to automate postings effortlessly.
                        </p>

                        <h4>Making Your First Update</h4>
                        <p>
                            As a final step, you’ll be prompted to make your first update. Keep it simple, like:  
                            <em>“Today, I fixed a bug that caused players to walk through walls.”</em>
                        </p>
                        <p>
                            Upload a video or image if desired, and you’re all set! The scheduler will take care of turning your update into a polished social media post.
                        </p>

                        <h4>Conclusion</h4>
                        <p>
                            The Social Scheduler is a powerful tool to help you maintain an active and consistent online presence with minimal effort. By following this tutorial, you’ll be well on your way to streamlining your social media strategy and growing your game’s visibility.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialSchedulerCreatePage;
