import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMoneyBillWave, faBroadcastTower, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import SignUpCreators from "../../component/section/signup_creators";
import CreatorAppDownload from "../../component/section/creators/creator_app_download";
import CreatorLinks from "../../component/section/creators/creator_links";
import Footer from "../../component/layout/footer";

class CreatorsEditingToolPage extends Component {

    render() {
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <>
                <Helmet prioritizeSeoTags>
                    <meta charSet="utf-8" />
                    <title>Built-In Editing Tools to Create Faster | Glitch Gaming</title>
                    <meta name="description" content="Become a content creator and get paid for playing games and creating content. Join Glitch's creator program and start earning today." />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Built-In Editing Tools to Create Faster | Glitch" />
                    <meta property="og:description" content="Become a content creator and get paid for playing games and creating content. Join Glitch's creator program and start earning today." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:image" content="https://www.glitch.fun/assets/images/creators/creators_3.png" />
                </Helmet>

                <Fragment>
                    <Header />
                    <section className="pageheader-section text-center text-uppercase" style={{ backgroundImage: "url(/assets/images/pageheader/bg.jpg)", padding: "100px 0" }}>
                        <div className="container">
                            <div className="section-wrapper">
                                <br /><br /><br /><br />
                                <img style={{ maxHeight: '160px' }} className="rounded mb-4" src="/assets/images/creators/edit_clips_1.jpeg" alt="Edit your clips" />
                                <h2 className="pageheader-title">Edit Your Content Quickly and Easily</h2>
                                <p className="lead">Our built-in tools make editing content quicker and easier than ever before.</p>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-dark text-white">
                        <div className="container">
                            <div className="row align-items-center mb-3">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/add_image.gif" alt="AI Creates Campaign" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4 mt-2">Add Images</h2>
                                    <p className="lead">Enhance your video content by adding images such as logos, memes, and more.</p>
                                    <form className="contact-form mt-4" id="contact-form" method="POST">
                                        <div className="form-group w-100 text-center">
                                            <a className="default-button" href="#download"><span>Download The App</span></a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <hr />
                            <div className="row align-items-center mb-5 flex-column-reverse flex-lg-row">
                                <div className="col-lg-6 order-lg-2">
                                    <img src="/assets/images/creators/add_audio.gif" alt="AI Filter Influencers" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="mb-4">Add Audio</h2>
                                    <p className="lead">Incorporate external audio, from songs to voiceovers, to enrich your videos.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black text-center">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6 mx-auto">
                                    <h2 className="mb-4 text-black">Add Text</h2>
                                    <p className="lead">Overlay text on your videos to provide additional context and meaning.</p>
                                    <img src="/assets/images/creators/add_text.gif" alt="AI Sourcing" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-3">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/add_sound_effect.gif" alt="AI Fraud Detection" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4 mt-3">Add Sound Effects</h2>
                                    <p className="lead">Add unique sound effects from our extensive library to create memorable moments in your videos.</p>
                                </div>
                            </div>
                            <hr />
                            <div className="row align-items-center mb-5 flex-column-reverse flex-lg-row">
                                <div className="col-lg-6 order-lg-2">
                                    <img src="/assets/images/creators/append_video.gif" alt="AI Fraud Detection" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="mb-4">Append Video</h2>
                                    <p className="lead">Seamlessly add external video clips to the beginning or end of your current video.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black text-center">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6 mx-auto">
                                    <h2 className="mb-4 text-black">Text to Speech</h2>
                                    <p className="lead">Convert typed text into speech and add it to your videos, choosing from various voices.</p>
                                    <img src="/assets/images/creators/add_text_to_speech.gif" alt="AI Sourcing" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                        <form className="contact-form mt-4" id="contact-form" method="POST">
                            <div className="form-group w-100 text-center">
                                <a className="default-button reverse-effect" href="#download"><span>Download For Free</span></a>
                            </div>
                        </form>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-3">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/record_audio.gif" alt="AI Fraud Detection" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4 mt-3">Record Audio</h2>
                                    <p className="lead">Capture audio directly from your computer’s microphone or another device and add it to your video.</p>
                                </div>
                            </div>
                            <hr />
                            <div className="row align-items-center mb-5 flex-column-reverse flex-lg-row">
                                <div className="col-lg-6 order-lg-2">
                                    <img src="/assets/images/creators/trim.gif" alt="AI Sourcing" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="mb-4">Trim Videos</h2>
                                    <p className="lead">Easily trim the left or right side of your video to achieve the perfect length.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black text-center">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6 mx-auto">
                                    <h2 className="mb-4 text-black">Record Video</h2>
                                    <p className="lead">Use your computer’s camera to record videos that can be appended to your current project.</p>
                                    <img src="/assets/images/creators/record_video.gif" alt="AI Fraud Detection" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-3">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/change_speed.gif" alt="AI Sourcing" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4 mt-3">Change Speed</h2>
                                    <p className="lead">Adjust the speed of your clips to create slow-motion or fast-forward effects.</p>
                                </div>
                            </div>
                            <hr />
                            <div className="row align-items-center mb-5 flex-column-reverse flex-lg-row">
                                <div className="col-lg-6 order-lg-2">
                                    <img src="/assets/images/creators/change_volume.gif" alt="AI Fraud Detection" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="mb-4">Change Volume</h2>
                                    <p className="lead">Increase or decrease the volume of a clip to balance the sound in your videos.</p>
                                    <form className="contact-form mt-4 mb-2" id="contact-form" method="POST">
                                        <div className="form-group w-100 text-center">
                                            <a className="default-button" href="#download"><span>Download Now</span></a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black text-center">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6 mx-auto">
                                    <h2 className="mb-4 text-black">Convert to Mobile</h2>
                                    <p className="lead">Effortlessly convert gaming clips from desktop or console formats to mobile-friendly formats.</p>
                                    <img src="/assets/images/creators/convert_to_mobile.gif" alt="AI Sourcing" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/combine.gif" alt="AI Fraud Detection" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4 mt-3">Combine Clips</h2>
                                    <p className="lead">Merge multiple clips from your library to create seamless content.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <CreatorLinks />

                    <CreatorAppDownload />

                    <SignUpCreators />

                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default CreatorsEditingToolPage;
