import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faUserFriends, faBullhorn, faChartLine, faUsers, faRocket, faPercentage, faThumbsUp, faTools, faGamepad, faDollarSign, faArrowRight, faRobot, faDatabase, faGift, faVial } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import PublisherSubmenu from '../../component/section/publishers/PublisherSubmenu';

function PublisherUserAcquisition() {

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Enhance Game User Acquisition with Glitch's Influencer Marketing Strategy | Glitch Gaming</title>
        <meta name="description" content="Maximize your game's user acquisition with Glitch. Benefit from lower CPI, higher engagement, targeted audience reach, and advanced analytics for impactful influencer marketing strategies in the gaming sector." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Enhance Game User Acquisition with Glitch's Influencer Marketing Strategy | Glitch" />
        <meta property="og:description" content="Maximize your game's user acquisition with Glitch. Benefit from lower CPI, higher engagement, targeted audience reach, and advanced analytics for impactful influencer marketing strategies in the gaming sector." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/banner_4.png" />
      </Helmet>
      <Header />
      <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
        <br /><br />
        <h1 style={{ fontSize: "2.5rem" }}>Reduce User Acquisition Costs <br /><br />& Boost Conversions with Influencer Marketing</h1>
      </div>
      <div className="container py-5">
        {/* Section 1: Lower CPI */}
        <div className="row mb-5 align-items-center">
          <div className="col-md-6">
            <div className="ratio ratio-16x9">
              <video className="w-100 rounded shadow" src="/assets/videos/publishers/save_money.mp4" autoPlay loop muted playsInline></video>
            </div>
          </div>
          <div className="col-md-6">
            <h2>Significantly Lower User Acquisition Costs</h2>
            <p className='lead'><FontAwesomeIcon className='text-success me-2' icon={faMoneyBillWave} /><strong>Achieve up to 50% lower UAC</strong> compared to traditional advertising methods.
              With Glitch's influencer marketing:</p>
            <ul className='lead built-in-bullets'>
              <li>Reduce your Cost Per Install (CPI) below $1-3 per user</li>
              <li>Leverage authentic influencer content for organic reach</li>
              <li>Optimize your marketing budget for better ROI</li>
            </ul>
            <form className="contact-form mt-4" id="contact-form" method="POST">
              <div className="form-group w-100 text-center">
                <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Start Lowering Costs</span></Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>

        {/* Section 2: Higher Engagement */}
        <div className='pt-4 pb-2 mb-5 text-white text-center rounded' style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}>
          <div className='container'>
            <h2>Boost User Engagement</h2>
            <img src="/assets/images/publishers/user_engagement.jpg" alt="Higher Engagement" className="img-fluid mb-4" />
            <p className='lead'><FontAwesomeIcon icon={faUserFriends} className="text-warning me-2" /> <strong>Experience over 5x ROAS</strong> with influencer marketing compared to the
              average 2x-3x from traditional ads.</p>
            <p className='lead'>Benefit from the trust and authenticity influencers bring to your game, leading to
              increased installs and long-term player engagement.</p>
          </div>
        </div>

        {/* Section 3: Targeted Audience */}
        <div className='container'>
          <div className="row mb-5 align-items-center">
            <div className="col-md-6 order-md-2">
              <img src="/assets/images/publishers/targeting.gif" alt="Targeted Audience" className="img-fluid rounded mb-3" />
            </div>
            <div className="col-md-6 order-md-1">
              <h2>Boost Install Rates and Engagement</h2>
              <p className='lead'><FontAwesomeIcon icon={faUserFriends} className="text-info me-2" />  Collaborate with influencers to achieve up to <strong>30% more downloads</strong> and
                higher player retention.</p>
              <ul className='lead list-unstyled'>
                <li><FontAwesomeIcon icon={faPercentage} /> Tap into highly engaged influencer audiences</li>
                <li><FontAwesomeIcon icon={faUsers} /> Drive meaningful interactions and community building</li>
                <li><FontAwesomeIcon icon={faRocket} /> Enhance customer lifetime value by up to 20%</li>
              </ul>
              <form className="contact-form mt-4" id="contact-form" method="POST">
                <div className="form-group w-100 text-center">
                  <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Increase Installs Now</span></Link>
                </div>
              </form>
            </div>

          </div>
        </div>

        {/* Section 4: Analytics and Performance */}
        <div className='pt-4 pb-2 mb-5 text-white text-center rounded' style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}>
          <h2>Reach Your Ideal Audience Effectively</h2>
          <img src="/assets/images/publishers/wishlist_chart.png" alt="Analytics and Performance" className="img-fluid rounded mb-4" />
          <p className="lead">
            <FontAwesomeIcon icon={faBullhorn} className="text-danger me-2" />
            Utilize Glitch's influencer matchmaking to connect with creators who align perfectly
            with your target demographics.
          </p>

          <ul className="lead list-unstyled">
            <li>
              <FontAwesomeIcon icon={faPercentage} className="text-success me-2" />
              Improve conversion rates with precise targeting
            </li>
            <li>
              <FontAwesomeIcon icon={faUsers} className="text-info me-2" />
              Access niche and broad audiences efficiently
            </li>
            <li>
              <FontAwesomeIcon icon={faRocket} className="text-warning me-2" />
              Scale your campaigns with cost-effective strategies
            </li>
          </ul>
        </div>

        <PublisherSubmenu />

        <div className='pt-5 pb-5 bg-dark text-white text-center rounded' style={{ backgroundImage: "url(/assets/images/video/bg.jpg)" }}>
          <div className="mb-5">
            <h2>Revolutionize Your Marketing with Glitch</h2>
            <p className="lead">Join Glitch now for an innovative approach to influencer marketing. Reduce costs and boost your game's success.</p>
            <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-primary">Get Started</Link>
          </div>

          <div className="mb-5">
            <p className="lead">Learn more about influencer marketing benefits.</p>
            <a href="https://glitch.ghost.io/how-influencer-marketing-can-lower-a-games-cpi/" target='_blank' className="btn btn-outline-secondary">Read Full Article</a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const bannerStyle = {
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/banner_4.png")`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '2.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublisherUserAcquisition;
