import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMoneyBillWave, faBroadcastTower, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import SignUpCreators from "../../component/section/signup_creators";
import CreatorAppDownload from "../../component/section/creators/creator_app_download";
import Footer from "../../component/layout/footer";
import CreatorLinks from "../../component/section/creators/creator_links";

class CreatorsFeaturesPage extends Component {

    render() {
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <>
                <Helmet prioritizeSeoTags>
                    <meta charSet="utf-8" />
                    <title>Feature-Rich Streaming Tools | Glitch Gaming</title>
                    <meta name="description" content="Enhance your streaming experience with Glitch's built-in tools. Engage your audience with AI co-hosts, sound effects, custom layouts, and more." />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Feature-Rich Streaming Tools | Glitch" />
                    <meta property="og:description" content="Enhance your streaming experience with Glitch's built-in tools. Engage your audience with AI co-hosts, sound effects, custom layouts, and more." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:image" content="https://www.glitch.fun/assets/images/creators/creators_3.png" />
                </Helmet>

                <Fragment>
                    <Header />
                    <section className="pageheader-section text-center text-uppercase" style={{ backgroundImage: "url(/assets/images/pageheader/bg.jpg)", padding: "100px 0" }}>
                        <div className="container">
                            <div className="section-wrapper">
                                <br /><br /><br /><br />
                                <img style={{ maxHeight: '160px' }} className="rounded mb-4" src="/assets/images/creators/live.webp" alt="Robust Feature Set" />
                                <h2 className="pageheader-title">Feature-Rich Streaming Tools</h2>
                                <p className="lead">Our built-in streaming tools are designed to make your streaming experiences more engaging and interactive.</p>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/ai_cohost.gif" alt="AI Co-Hosts" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">AI Co-Hosts</h2>
                                    <p className="lead">Enable an AI co-host to interact with during your live streams. Customize the AI's personality to suit your preferences and enhance viewer engagement.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6 order-lg-2">
                                    <img src="/assets/images/creators/on_screen_cohosts.webp" alt="Invite Real Co-Hosts" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="mb-4 text-black">Invite Real Co-Hosts</h2>
                                    <p className="lead">Invite other co-hosts to join you in real-time during gameplay. Enjoy sub-second latency for a seamless co-hosting experience.</p>
                                    <form className="contact-form mt-4" id="contact-form" method="POST">
                                        <div className="form-group w-100 text-center">
                                        <a className="default-button reverse-effect" href="#download"><span>Download Now</span></a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/sound_effects.gif" alt="Play Sound Effects" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">Play Sound Effects</h2>
                                    <p className="lead">Add sound effects like laughter, clapping, or even a scary howl during your live streams. Easily upload your own sound effects for a personalized touch.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <h2 className="mb-4 text-black">Layouts</h2>
                                    <p className="lead">Save and load custom layouts for your camera, game, and other screen elements. Switch between different layouts effortlessly.</p>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/layouts.gif" alt="Custom Layouts" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/transitions.gif" alt="Transitions" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">Transitions</h2>
                                    <p className="lead">Add smooth transitions to move between different screens during your live streams. Upload your own MP4 files for a unique transition effect.</p>
                                    <form className="contact-form mt-4" id="contact-form" method="POST">
                                        <div className="form-group w-100 text-center">
                                        <a className="default-button" href="#download"><span>Download For Free</span></a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <h2 className="mb-4 text-black">Hot Keys</h2>
                                    <p className="lead">Set up hotkeys to quickly access various functions in the application. Customize your shortcuts for a more efficient streaming workflow.</p>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/hotkeys.gif" alt="Hot Keys" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/2nd_camera.webp" alt="Second Camera" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">Second Camera</h2>
                                    <p className="lead">Add a second camera to your live stream for multiple video inputs. Perfect for showing different angles or additional content.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <h2 className="mb-4 text-black">Stream Elements</h2>
                                    <p className="lead">Integrate custom overlays, animations, and pop-ups for subscriptions and donations. Make your streams more interactive and engaging for viewers.</p>
                                    <form className="contact-form mt-4 mb-2" id="contact-form" method="POST">
                                        <div className="form-group w-100 text-center">
                                        <a className="default-button reverse-effect" href="#download"><span>Download The App</span></a>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/stream_elements.png" alt="Stream Elements" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/with_without_elements.webp" alt="Record With and Without Elements" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">Record With and Without Elements</h2>
                                    <p className="lead">Record your streams with all interactive elements in place, and optionally record a version with just the screen content.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <h2 className="mb-4 text-black">Display Webpages On Screen</h2>
                                    <p className="lead">Integrate websites into your live streams to give presentations or showcase online content directly on screen.</p>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/add_webpage.gif" alt="Display Webpages On Screen" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/on_screen_images.gif" alt="On-Screen Images" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">On-Screen Images</h2>
                                    <p className="lead">Easily drag and drop any image to appear on-screen. Ideal for logos, branding, and visual content.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <h2 className="mb-4 text-black">Tips and Earnings</h2>
                                    <p className="lead">Collect tips and earnings during your streams. Use our integrated links to receive tips from your followers and give them shoutouts.</p>
                                </div>
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/tips.webp" alt="Tips and Earnings" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <CreatorAppDownload />

                    <CreatorLinks />

                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default CreatorsFeaturesPage;
