import { Component, Fragment } from "react";
import React from 'react';
import { Link } from "react-router-dom";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import ProfileUpdateHeader from "../../component/section/profileupdate";
import Navigate from "../../../../util/Navigate";
import withRouter from "../../../../util/withRouter";
import Danger from "../../component/alerts/Danger";
import Warning from "../../component/alerts/Warning";
import Success from "../../component/alerts/Success";
import TournamentItem from "../../component/section/competitions/detail_tournament_item";
import Footer from "../../component/layout/footer";
import Glitch from 'glitch-javascript-sdk';
import Loading from "../../component/alerts/Loading";
import timeouts from "../../../../constants/timeouts";
import Wysiwyg from "../../component/form/wysiwyg";
import AccountHeader from "../../component/layout/accountheader";

class AccountUpdatePage extends Component {

    constructor(props) {
        super(props);

        // Safely check if window is available (i.e., we are in a browser and not SSR)
        const isBrowser = typeof window !== 'undefined';
        const origin = isBrowser ? window.location.origin : '';

        // Build OAuth links only if we have access to the window object
        this.OAuthLinks = {
            facebook: `${origin}${Navigate.authFacebook()}?redirect=${origin}${Navigate.authFacebookComplete()}`,
            tiktok: `${origin}${Navigate.authTikTok()}?redirect=${origin}${Navigate.authTikTokComplete()}`,
            youtube: `${origin}${Navigate.authGoogle()}?redirect=${origin}${Navigate.authGoogleComplete()}`,
            twitch: `${origin}${Navigate.authTwitch()}?redirect=${origin}${Navigate.authTwitchComplete()}`,
            twitter: `${origin}${Navigate.authTwitter()}?redirect=${origin}${Navigate.authTwitterComplete()}`,
            reddit: `${origin}${Navigate.authReddit()}?redirect=${origin}${Navigate.authRedditComplete()}`,
            stripe: `${origin}${Navigate.authStripe()}?redirect=${origin}${Navigate.authStripeComplete()}`,
        };

        this.state = {
            me: {},
            profileHeader: '',
            followers: '<h3>No Followers</h3>',
            following: '<h3>No Followers</h3>',
            communities: [],
            campaigns: [],
            errors: {},
            isLoading: false,
            availableGenres: [], // To store genres fetched from the backend
            selectedGenre: '', // To store the genre currently selected by the user for addition
        };
    }

    componentDidMount() {
        Glitch.api.Users.me().then(response => {
            this.setState({
                me: response.data.data,
                profileHeader: <ProfileUpdateHeader user={response.data.data} />
            });
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Communities.list({ roles: [1, 2] }).then(response => {
            this.setState({ communities: response.data.data });
        }).catch(error => {});

        Glitch.api.Utility.listGenres().then(response => {
            this.setState({ availableGenres: response.data.data });
        }).catch(error => {});
    }

    activateDonations() {
        Glitch.api.Users.createDonationPage().then(response => {
            this.setState({
                me: response.data.data
            });
        }).catch(error => {
            console.error(error);
        })
    }

    handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const actualValue = type === 'checkbox' ? checked : value;
        this.setState(prevState => ({
            ...prevState,
            me: { ...prevState.me, [name]: actualValue }
        }));
    }

    handleWysiwigChange = (content, field) => {
        this.setState(prevState => ({
            ...prevState,
            me: { ...prevState.me, [field]: content }
        }));
    }

    handleUpdateInfluencer = () => {
        const { me } = this.state;
        this.setState({ isLoading: true });
        Glitch.api.Users.update(me).then(() => {
            if (this.state.me.is_influencer) {
                Glitch.api.Users.syncInfluencer().then(() => {}).catch(error => {});
            }
        }).catch(error => {
            if (error.response && error.response.data) {
                this.setState({ errors: error.response.data });
                setTimeout(() => {
                    this.setState({ errors: {} });
                }, timeouts.error_message_timeout);
            }
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    handleGenreChange = (event) => {
        this.setState({ selectedGenre: event.target.value });
    }

    addGenre = () => {
        const { selectedGenre } = this.state;
        Glitch.api.Users.addGenre({ genre_id: selectedGenre }).then((response) => {
            this.setState({
                me: response.data.data
            });
        }).catch(error => console.error(error));
    }

    removeGenre = (genreId) => {
        Glitch.api.Users.removeGenre(genreId).then((response) => {
            this.setState({
                me: response.data.data
            });
        }).catch(error => console.error(error));
    }

    handleOAuthLogin = (platform) => {
        // Only call window.open if we're in the browser
        if (typeof window !== 'undefined') {
            window.open(
                this.OAuthLinks[platform.toLowerCase()],
                'OAuthWindow',
                'height=600,width=400'
            );
        }
    }

    handleOAuthLogout = (platform) => {
        const logoutFunctions = {
            Twitch: Glitch.api.Users.clearTwitchAuth,
            Facebook: Glitch.api.Users.clearFacebookAuth,
            YouTube: Glitch.api.Users.clearGoogleAuth,
            TikTok: Glitch.api.Users.clearTikTokAuth,
            Reddit: Glitch.api.Users.clearRedditAuth,
            Twitter: Glitch.api.Users.clearTwitterAuth,
            Stripe: Glitch.api.Users.clearStripeAuth
        };
        
        if (logoutFunctions[platform]) {
            logoutFunctions[platform]().then(response => {
                this.setState({ me: response.data.data });
            }).catch(error => {
                console.error(error);
            });
        }
    }

    renderOAuthButton = (platform, authToken, brandColor, iconClass) => {
        const { me } = this.state;
        const platformLower = (platform.toLowerCase() === 'youtube') ? 'google' : platform.toLowerCase();
        const link = 
            me[`${platformLower}_link`] || 
            me[`${platformLower}_page`] || 
            (platformLower === 'twitter' && `https://twitter.com/${me[`${platformLower}_username`]}`) ||
            (platformLower === 'twitch' && `https://twitch.tv/${me[`${platformLower}_username`]}`) ||
            (platformLower === 'reddit' && `https://www.reddit.com/user/${me[`${platformLower}_nickname`]}`) ||
            (platformLower === 'tiktok' && `https://www.tiktok.com/@${me[`${platformLower}_username`]}`) ||
            // For YouTube or Google, check influencer field
            (platformLower === 'google' && me.influencer && me.influencer[`${platform.toLowerCase()}_link`]) ||
            (platformLower === 'youtube' && me.influencer && me.influencer[`${platform.toLowerCase()}_link`]);

        const username =
            me[`${platformLower}_username`] ||
            me[`${platformLower}_handle`] ||
            me[`${platformLower}_name`];

        const followerCount = me.influencer &&
            (me.influencer[`${platform.toLowerCase()}_follower_count`] ||
            me.influencer[`${platform.toLowerCase()}_subscriber_count`]);

        const bio = me.influencer &&
            (me.influencer[`${platform.toLowerCase()}_biography`] ||
            me.influencer[`${platform.toLowerCase()}_description`]);

        return authToken ? (
            <div className="mb-3">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">{platform}</h5>
                        {username && <p><strong>Username:</strong> {username}</p>}
                        {(followerCount !== undefined) && <p><strong>Followers:</strong> {followerCount}</p>}
                        {bio && <p><strong>Bio:</strong> {bio}</p>}
                        {link && (
                            <p>
                                <a
                                    href={link}
                                    target="_blank"
                                    className="text-primary"
                                    rel="noopener noreferrer"
                                >
                                    Profile Link
                                </a>
                            </p>
                        )}
                        <button
                            className={`btn btn-${brandColor}`}
                            onClick={() => this.handleOAuthLogout(platform)}
                        >
                            <i className={`fas fa-trash`}></i> Disconnect {platform}
                        </button>
                    </div>
                </div>
            </div>
        ) : (
            <div className="mb-3">
                <button
                    className={`btn btn-${brandColor}`}
                    onClick={() => this.handleOAuthLogin(platform)}
                >
                    <i className={`fab fa-${iconClass}`}></i> Authenticate {platform}
                </button>
            </div>
        );
    }

    render() {
        const { me, availableGenres, selectedGenre, errors, isLoading } = this.state;

        const stripeData = !me.stripe_express_account_id ? (
            <>
                <Danger message={"No Stripe Account Connected"} />
                <p>
                    To accept donations you must connect your Stripe account. You
                    can connect your{" "}
                    <Link to={Navigate.authStripe()}>account here.</Link>
                </p>
            </>
        ) : !me.stripe_donation_purhcase_link_url ? (
            <>
                <Warning message={"Activate Donation Page"} />
                <p>
                    To finalize your ability to accept donations, you must
                    activate your donation page.
                </p>
                <div className="form-group text-center">
                    <button
                        className="d-block default-button"
                        onClick={() => this.activateDonations()}
                    >
                        <span> Activate</span>
                    </button>
                </div>
            </>
        ) : (
            <>
                <Success message={"Active Donation Page"} />
                <p>
                    Your Stripe Account and Donations page is active. You will
                    now be able to accept donations in your stream. You can view
                    your donations{" "}
                    <a target={"_blank"} href={me.stripe_donation_purhcase_link_url} rel="noreferrer">
                        page here.
                    </a>
                </p>
            </>
        );

        return (
            <Fragment>
                <AccountHeader />
                <PageHeader title={'Manage Account'} curPage={'Settings'} />
                <div className="container">
                    <ul className="nav nav-tabs lead mt-2" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#home"
                                type="button"
                                role="tab"
                                aria-controls="home"
                                aria-selected="true"
                            >
                                Update Profile
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#profile"
                                type="button"
                                role="tab"
                                aria-controls="profile"
                                aria-selected="false"
                            >
                                Manage Communities
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="influencer-profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#influencerprofile"
                                type="button"
                                role="tab"
                                aria-controls="influencerprofile"
                                aria-selected="false"
                            >
                                Influencer Profile
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="oauth-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#oauth"
                                type="button"
                                role="tab"
                                aria-controls="oauth"
                                aria-selected="false"
                            >
                                Social Accounts
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="stripe-setup-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#stripe-tab"
                                type="button"
                                role="tab"
                                aria-controls="stripe-tab"
                                aria-selected="false"
                            >
                                Stripe (Payouts)
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content mt-3" id="myTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                        >
                            <div className="shop-single">
                                {this.state.profileHeader}
                            </div>
                        </div>
                        <div
                            className="tab-pane fade mt-3"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                        >
                            <div className="row g-4 match-grid GameListStyleTwo">
                                <h2>Communities</h2>
                                {this.state.communities &&
                                    this.state.communities.map((elem) => {
                                        const {
                                            id,
                                            name,
                                            description,
                                            tagline,
                                            logo,
                                        } = elem;
                                        return (
                                            <div className="col-12" key={id}>
                                                <div className="match-item item-layer">
                                                    <div className="match-inner">
                                                        <div className="match-header d-flex flex-wrap justify-content-between align-items-center">
                                                            <p className="match-team-info">
                                                                {tagline}
                                                            </p>
                                                        </div>
                                                        <div className="match-content">
                                                            <div className="row gy-4 align-items-center justify-content-center">
                                                                <div className="col-xl-4 col-md-6 order-md-2">
                                                                    <div className="match-game-team"></div>
                                                                </div>
                                                                <div className="col-xl-4 col-md-6 order-md-1">
                                                                    <div className="match-game-info">
                                                                        <h4>
                                                                            <Link
                                                                                to={Navigate.communitiesManagePage(
                                                                                    elem.id
                                                                                )}
                                                                            >
                                                                                {name}
                                                                            </Link>{" "}
                                                                        </h4>
                                                                        <p className="d-flex flex-wrap justify-content-center  justify-content-md-start">
                                                                            <span className="match-date">
                                                                                {
                                                                                    description
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-md-6 order-md-3">
                                                                    <div className="match-game-social">
                                                                        <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center justify-content-xl-start">
                                                                            <li>
                                                                                <a
                                                                                    href={Navigate.communitiesManagePage(
                                                                                        elem.id
                                                                                    )}
                                                                                    className="default-button reverse-effect"
                                                                                >
                                                                                    <span>
                                                                                        Manage
                                                                                        <i className="icofont-play-alt-1"></i>
                                                                                    </span>{" "}
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div
                            className="tab-pane fade mt-3 mb-3"
                            id="influencerprofile"
                            role="tabpanel"
                            aria-labelledby="influencer-profile-tab"
                        >
                            <div className="row g-4 match-grid GameListStyleTwo">
                                <h2>Influencer Profile</h2>
                                <p className="lead">
                                    Below are different fields to manage your
                                    influencer profile. Gaming publishers and
                                    indie developers will use the information
                                    when deciding to work with you. Update the
                                    fields that best represent your ability as a
                                    content creator.
                                </p>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label
                                            htmlFor="isInfluencer"
                                            className="form-label"
                                        >
                                            Activate Influencer Profile
                                        </label>
                                        &nbsp;
                                        <input
                                            type="checkbox"
                                            className="form-check-input ml-2"
                                            id="isInfluencer"
                                            name="is_influencer"
                                            checked={this.state.me?.is_influencer}
                                            onChange={this.handleChange}
                                        />
                                        <div className="form-text text-white">
                                            Use the checkbox for activating and
                                            deactivating your influencer profile.
                                        </div>
                                        {this.state.errors &&
                                            this.state.errors.is_influencer &&
                                            this.state.errors.is_influencer.map(
                                                (name, index) => {
                                                    return (
                                                        <Danger
                                                            message={name}
                                                            key={index}
                                                        />
                                                    );
                                                }
                                            )}
                                    </div>
                                </div>
                                <hr />
                                <div className="col-md-12">
                                    <h3>Manage Your Genres</h3>
                                    <p className="lead">
                                        Select the genre of games you enjoy
                                        playing to be matched with games that you
                                        most enjoy.
                                    </p>
                                    <div>
                                        <strong>Your Genres:</strong>
                                    </div>
                                    {this.state.me.genres &&
                                        this.state.me.genres.map((genre) => (
                                            <span
                                                key={genre.id}
                                                className="badge bg-secondary me-2"
                                            >
                                                {genre.name}
                                                <button
                                                    className="btn btn-danger btn-sm ms-2"
                                                    onClick={() =>
                                                        this.removeGenre(
                                                            genre.id
                                                        )
                                                    }
                                                >
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </span>
                                        ))}
                                    <div className="mt-3">
                                        <div className="input-group w-auto">
                                            <select
                                                className="form-select"
                                                value={selectedGenre}
                                                onChange={this.handleGenreChange}
                                            >
                                                <option value="">
                                                    Select Genre
                                                </option>
                                                {availableGenres.map((genre) => (
                                                    <option
                                                        key={genre.id}
                                                        value={genre.id}
                                                    >
                                                        {genre.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <button
                                                className="btn btn-primary"
                                                onClick={this.addGenre}
                                            >
                                                <i className="fas fa-plus"></i>{" "}
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="col-md-12">
                                    <h3>Your Profile</h3>
                                    <p className="lead">
                                        Use the questions below to further expand
                                        on your influencer profile and give
                                        gaming publishers and indie developers a
                                        more personal view of you.
                                    </p>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="influencer_games_why"
                                            className="form-label"
                                        >
                                            What Kind of Games and Why?
                                        </label>
                                        <div className="text-white">
                                            Describe the kind of games you like
                                            to play and why do you like to play
                                            them.
                                        </div>
                                        <Wysiwyg
                                            id="influencer_games_why"
                                            name="influencer_games_why"
                                            onChange={(content) => {
                                                this.handleWysiwigChange(
                                                    content,
                                                    "influencer_games_why"
                                                );
                                            }}
                                        >
                                            {me?.influencer_games_why || ""}
                                        </Wysiwyg>
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="influencerContentType"
                                            className="form-label"
                                        >
                                            Content Type
                                        </label>
                                        <div className="text-white">
                                            Describe the type of content you
                                            like to create.
                                        </div>
                                        <Wysiwyg
                                            id="influencerContentType"
                                            name="influencer_content_type"
                                            onChange={(content) => {
                                                this.handleWysiwigChange(
                                                    content,
                                                    "influencer_content_type"
                                                );
                                            }}
                                        >
                                            {me?.influencer_content_type || ""}
                                        </Wysiwyg>
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="influencerContentTheme"
                                            className="form-label"
                                        >
                                            Content Theme
                                        </label>
                                        <div className="text-white">
                                            Discuss how you theme your content.
                                        </div>
                                        <Wysiwyg
                                            id="influencerContentTheme"
                                            name="influencer_content_theme"
                                            onChange={(content) => {
                                                this.handleWysiwigChange(
                                                    content,
                                                    "influencer_content_theme"
                                                );
                                            }}
                                        >
                                            {me?.influencer_content_theme || ""}
                                        </Wysiwyg>
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="influencerContentUnique"
                                            className="form-label"
                                        >
                                            Content Uniqueness
                                        </label>
                                        <div className="text-white">
                                            What makes your content unique?
                                        </div>
                                        <Wysiwyg
                                            id="influencerContentUnique"
                                            name="influencer_content_unique"
                                            onChange={(content) => {
                                                this.handleWysiwigChange(
                                                    content,
                                                    "influencer_content_unique"
                                                );
                                            }}
                                        >
                                            {me?.influencer_content_unique || ""}
                                        </Wysiwyg>
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="influencerBrandApproach"
                                            className="form-label"
                                        >
                                            Brand Approach
                                        </label>
                                        <div className="text-white">
                                            How do you approach collaborations
                                            with brands?
                                        </div>
                                        <Wysiwyg
                                            id="influencerBrandApproach"
                                            name="influencer_brand_approach"
                                            onChange={(content) => {
                                                this.handleWysiwigChange(
                                                    content,
                                                    "influencer_brand_approach"
                                                );
                                            }}
                                        >
                                            {me?.influencer_brand_approach || ""}
                                        </Wysiwyg>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mb-3">
                                    {Object.keys(errors).length > 0 && (
                                        <Danger
                                            message={
                                                "There are error(s) in updating your profile. Please check the form above."
                                            }
                                        />
                                    )}
                                    <div className="d-grid gap-2 mt-4">
                                        <button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={this.handleUpdateInfluencer}
                                        >
                                            <i className="fas fa-save"></i> Update
                                            Profile{" "}
                                            {isLoading && <Loading />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Synced Social Media Accounts */}
                        <div
                            className="tab-pane fade mt-3 mb-3"
                            id="oauth"
                            role="tabpanel"
                            aria-labelledby="oauth-tab"
                        >
                            <div className="row g-4 match-grid GameListStyleTwo">
                                <h2>Synced Social Media Accounts</h2>
                                <p className="lead">
                                    Below are synced social media that can be
                                    used by influencers to post on various other
                                    social media accounts.
                                </p>
                                <div className="col-md-12">
                                    {this.renderOAuthButton(
                                        "Twitch",
                                        me.twitch_auth_token,
                                        "primary",
                                        "twitch"
                                    )}
                                    {this.renderOAuthButton(
                                        "Facebook",
                                        me.facebook_auth_token,
                                        "primary",
                                        "facebook-f"
                                    )}
                                    {this.renderOAuthButton(
                                        "YouTube",
                                        me.google_auth_token,
                                        "danger",
                                        "youtube"
                                    )}
                                    {this.renderOAuthButton(
                                        "TikTok",
                                        me.tiktok_auth_token,
                                        "info",
                                        "tiktok"
                                    )}
                                    {this.renderOAuthButton(
                                        "Reddit",
                                        me.reddit_auth_token,
                                        "danger",
                                        "reddit"
                                    )}
                                    {this.renderOAuthButton(
                                        "Twitter",
                                        me.twitter_auth1_token,
                                        "info",
                                        "twitter"
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Stripe - Stripe For Payouts */}
                        <div
                            className="tab-pane fade mt-3 mb-3"
                            id="stripe-tab"
                            role="tabpanel"
                            aria-labelledby="stripe-tab"
                        >
                            <div className="row g-4 match-grid GameListStyleTwo">
                                <h2>Stripe (For Payouts)</h2>
                                <p className="lead">
                                    To have your earnings transferred to you,
                                    connect your Stripe account below. If you do
                                    not have a Stripe account, clicking the
                                    button will help you set one up. Once you
                                    do, payments can automatically be
                                    transferred into your account through
                                    Stripe.
                                </p>
                                <div className="col-md-12">
                                    {this.renderOAuthButton(
                                        "Stripe",
                                        me.stripe_express_account_id,
                                        "primary",
                                        "stripe"
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade mt-3"
                            id="tournament"
                            role="tabpanel"
                            aria-labelledby="tournament-tab"
                        >
                            <div className="row g-4 match-grid GameListStyleTwo">
                                {me &&
                                    me.competitions &&
                                    me.competitions.map((tournament, index) => {
                                        return (
                                            <TournamentItem
                                                tournament={tournament}
                                                key={index}
                                                is_admin={true}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="contact"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                        >
                            <h3>Accepting Donations</h3>
                            <p className="lead">
                                In your streams, you have the ability to accept
                                donations from your followers. To do so, make
                                sure your donations page is active below.
                            </p>
                            {stripeData}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}

export default withRouter(AccountUpdatePage);
