import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet-async';

const PublisherTutorialInviteNegotiationsPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>How to Negotiate and Customize Deals with Influencers | Glitch Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to effectively negotiate and customize deals with influencers on Glitch. This tutorial covers setting terms, updating scope, and managing content rights."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Negotiate and Customize Deals with Influencers | Glitch Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial walks you through the process of negotiating with influencers and tailoring deals to meet specific campaign needs on Glitch."
                />
                
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Negotiate and Customize Deals with Influencers | Glitch Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn how to effectively negotiate and customize deals with influencers on Glitch. This tutorial covers setting terms, updating scope, and managing content rights."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Negotiate and Customize Deals with Influencers</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/r1vG_qC4k5M"
                                title="Negotiating and Customizing Deals with Influencers"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            Hey there! In this tutorial, we’re going to dive into the process of negotiating and customizing deals with influencers on Glitch. Negotiations are a common part of working with influencers, especially when they have agents or managers involved. Let’s explore how to navigate these negotiations and customize deals to fit your campaign needs.
                        </p>

                        <h4>Understanding Negotiations</h4>
                        <p>
                            As you start inviting influencers to your campaign, you may find that not all of them will immediately agree to your terms. Many influencers, particularly those represented by agents, will want to negotiate aspects of the deal. This process typically happens outside of the Glitch platform through emails or meetings.
                        </p>

                        <h4>Customizing the Deal</h4>
                        <p>
                            Once you’ve reached an agreement, it’s time to customize the deal on the platform. Navigate to the ‘Invites’ section and locate the influencer with whom you’ve negotiated. Click on the ‘Update Scope’ button to begin tailoring the deal.
                        </p>

                        <h4>Scope of Work</h4>
                        <p>
                            The ‘Scope of Work’ defines what the influencer will do for your campaign. You can modify the following sections:
                        </p>

                        <ul>
                            <li>
                                <strong>Deliverables:</strong> Specify what content the influencer will create, whether it's live streams, tutorial videos, social media posts, etc.
                            </li>
                            <li>
                                <strong>Days to Complete:</strong> Set the timeframe for when the influencer must deliver the agreed content.
                            </li>
                            <li>
                                <strong>Content Usage Rights:</strong> Determine who owns the created content, how it can be used by each party, and for how long.
                            </li>
                            <li>
                                <strong>Platform Restrictions:</strong> By default, Glitch allows content creation across all platforms. You can restrict the influencer to specific platforms if needed.
                            </li>
                            <li>
                                <strong>Payment Terms:</strong> Set the payment structure, whether it’s a flat fee, performance-based, or a combination of both.
                            </li>
                        </ul>

                        <h4>Finalizing the Deal</h4>
                        <p>
                            After updating the deal details, the influencer will be notified and asked to review the new terms. If they agree, they simply need to accept the invite, and your campaign with them will officially begin!
                        </p>

                        <p>
                            That’s how you can negotiate and customize deals with influencers on Glitch. This flexibility ensures that both parties are satisfied with the terms, paving the way for a successful campaign.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialInviteNegotiationsPage;
