import {
    faDatabase,
    faDollarSign,
    faGamepad,
    faRobot,
    faThumbsUp,
    faTools,
    faUsers,
    faVial,
    faEnvelope,
    faFileAlt,
    faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import Navigate from "../../../../../util/Navigate";
import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PublisherSubmenu = () => {
    const location = useLocation();

    return (
        <div className="container text-center mb-4">
            <h4>Learn More About Glitch's Influencer Marketing</h4>
            <div className="d-flex justify-content-center align-items-center flex-wrap">
                <Link to={Navigate.publishersAIPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faRobot} className="me-1" /> AI
                </Link>
                <Link to={Navigate.publishersCRMPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faUsers} className="me-1" /> CRM
                </Link>
                <Link to={Navigate.publishersDatabasePage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faDatabase} className="me-1" /> Database
                </Link>
                <Link to={Navigate.publishersBenefitsPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faThumbsUp} className="me-1" /> Performance-Based
                </Link>
                <Link to={Navigate.publishersOptimizationPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faTools} className="me-1" /> Optimization
                </Link>
                <Link to={Navigate.publishersContentPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faGamepad} className="me-1" /> Diverse Content
                </Link>
                <Link to={Navigate.publishersAcquisitionPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faDollarSign} className="me-1" /> Acquisition Costs
                </Link>
                <Link to={Navigate.publishersPlayTesterPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faVial} className="me-1" /> Play Testers
                </Link>
            </div>
        </div>
    );
};

export default PublisherSubmenu;
