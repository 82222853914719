import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faVideo, faFileAlt, faInfoCircle, faUserCircle, faEnvelope, faGlobe, faRobot, faDatabase, faMoneyBill, faGift, faGamepad, faBullhorn, faTools, faThumbsUp, faDollarSign, faSyncAlt, faCloudUploadAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

function PublishersMediaKitPage() {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>Create a Professional Media Kit for Your Game | Glitch Gaming</title>
                <meta name="description" content="Use Glitch's free media kit builder to create a comprehensive media kit for your game. Include logos, screenshots, trailers, press releases, and more." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Create a Professional Media Kit for Your Game | Glitch" />
                <meta property="og:description" content="Use Glitch's free media kit builder to create a comprehensive media kit for your game. Include logos, screenshots, trailers, press releases, and more." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/media_kit_banner.webp" />
            </Helmet>
            <Header />
            <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <h1 style={{ fontSize: "2.5rem" }}><br /><br />Create a Professional Media Kit<br /><br />For Your Game</h1>
            </section>

            {/* Main Content */}
            <section className="py-5">
                <div className="container">

                    {/* Statistics Section */}
                    <div className="mb-5 text-center">
                        <h2 className="mb-4">Why a Media Kit is Essential</h2>
                        <p className="lead">A comprehensive media kit boosts your game's visibility and provides essential assets for marketing and press coverage.</p>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faGlobe} size="3x" className="text-primary mb-3" />
                                <h4>Increased Exposure</h4>
                                <p>Games with media kits are 70% more likely to be featured in media outlets.</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faInfoCircle} size="3x" className="text-success mb-3" />
                                <h4>Streamlined Communication</h4>
                                <p>Provide all essential information in one place, making it easier for influencers and journalists.</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faUserCircle} size="3x" className="text-warning mb-3" />
                                <h4>Professional Image</h4>
                                <p>Enhance your game's credibility with a polished and organized media kit.</p>
                            </div>
                        </div>
                    </div>

                    {/* Feature 1 */}
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <img src="/assets/images/publishers/media_kit_builder.png" alt="Media Kit Builder" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="mb-4">Free Media Kit Builder</h2>
                            <p className="lead">Use our intuitive builder to create a comprehensive media kit for your game, including all the essential components.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faFileAlt} className="me-2 text-primary" /> Easy-to-Use Interface</li>
                                <li><FontAwesomeIcon icon={faTools} className="me-2 text-success" /> Customizable Templates</li>
                                <li><FontAwesomeIcon icon={faImages} className="me-2 text-info" /> Logos and Icons</li>
                                <li><FontAwesomeIcon icon={faVideo} className="me-2 text-danger" /> Trailers and Videos</li>
                                <li><FontAwesomeIcon icon={faFileAlt} className="me-2 text-secondary" /> Press Releases and Fact Sheets</li>
                                <li><FontAwesomeIcon icon={faCloudUploadAlt} className="me-2 text-warning" /> Easy Asset Management</li>
                            </ul>
                          
                        </div>
                    </div>

  

                    {/* CTA Section */}
                    <div className='pt-5 pb-5 bg-dark text-white text-center rounded' style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}>
                        <h2 className="text-danger mb-4">Boost Your Game's Marketing Efforts</h2>
                        <p className="lead">A professional media kit is the cornerstone of your marketing strategy, aiding in advertising, PR, and influencer outreach.</p>
                        <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-lg btn-danger text-white">Create Your Media Kit Now</Link>

                    </div>

                    <IndieSubmenu />
                </div>
            </section>

            <Footer />
        </>
    );
}

const bannerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/media_kit_banner.webp")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublishersMediaKitPage;
