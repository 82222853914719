import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Glitch from 'glitch-javascript-sdk';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

const PublisherGameInfluencersPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [game, setGame] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        if (name && email) {
            setLoading(true); // Show spinner
            Glitch.api.Influencers.workbook({ name, email, game, format: 'xls' })
                .then(() => {
                    setModalVisible(false);
                    setSuccessMessage('The workbook has been sent to your email.');
                })
                .catch(error => {
                    console.error('Error generating download link:', error);
                })
                .finally(() => {
                    setLoading(false); // Hide spinner
                });
        }
    };

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>Free Influencer Marketing Tools & Database | Glitch Gaming</title>
                <meta name="description" content="Access free influencer marketing tools and a curated influencer database to boost your game's visibility. Find and reach out to gaming influencers easily." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Free Influencer Marketing Tools & Database | Glitch" />
                <meta property="og:description" content="Browse and download contact details of gaming influencers for free. Get tools to manage influencer outreach and campaigns." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/influencers/influencers.webp" />
            </Helmet>
            <Header />

            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Find Gaming Influencers for Free</h1>
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>

            <div className="container mt-5">
                <p className='lead'>Download our influencer marketing workbook to help manage your outreach and collaborations with gaming influencers.</p>

                {successMessage && (
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                )}

                <div className='mb-3'>
                    <button className="btn btn-success" onClick={() => setModalVisible(true)}>
                        <FontAwesomeIcon icon={faDownload} /> Download Workbook
                        {loading && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                    </button>
                </div>

                <p className='lead'>
                    You can use Glitch's influencer database for free. <Link to={Navigate.publishersOnboardingStep1Page()}>Sign up here</Link> to access our growing list of gaming influencers.
                </p>

                <p className='lead'>
                    New to influencer marketing? Download our workbook and watch the video below to learn how to effectively manage the influencer outreach process.
                </p>

                <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                    <iframe
                        src="https://www.youtube.com/embed/faIw7vgukHs"
                        title="How to Send Invites to Influencers"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                
            </div>

            {/* Comprehensive SEO Tutorial Blog Post */}
            <div className="container mt-5">
                <h2 className="mb-4">A Comprehensive Guide to Influencer Marketing for Game Developers and Publishers</h2>

                <p className="lead">
                    As a game developer or publisher, you've invested countless hours, resources, and passion into creating a game you're proud of. Now comes the pivotal challenge: getting it into the hands of players who will appreciate it as much as you do. One of the most effective strategies to achieve this is through influencer marketing. In this comprehensive guide, we'll explore how influencer marketing works and provide you with actionable steps to execute a successful campaign.
                </p>

                <p className="lead">
                    Before deciding to pay for a platform like ours, or pay a consultant or agency, you should read this document to understand the ins and outs of gaming and influencer marketing.
                </p>

                <h3 className="mt-5 mb-4">Understanding the Reality of Influencer Marketing</h3>
                <img src="/assets/images/influencers/influencers-brands-work-with.png" className='img-fluid mb-3 rounded' />

                <p>
                    <strong>Breaking the Myth:</strong> There's a common misconception that simply reaching out to one popular influencer will result in immediate success for your game. The fantasy involves the one mega influencer falls in love with your game, streaming it to their massive audience, and you reaping instant rewards in the form of thousands of downloads and wishlists. However, the reality is more nuanced.
                </p>
                <p>
                    Influencer marketing isn't a magical solution that guarantees overnight success. It's a strategic process that requires careful planning, relationship building, and consistent effort. Think of it as planting seeds in a garden; with proper care and attention, they'll grow over time into something fruitful.
                </p>

                <p>
                    You likely have to work with multiple influencers to find a select few that bring positive and meaningful results to your game. And you will likely have to manage the influencers to achieve a successful outcome for your game.
                </p>

                <p>
                    <strong>Most Brands Use 10+ Influencers:</strong> One common misconception, based on a misinterpretation of the graph above, is that most brands use 10 or fewer influencers. However, when you combine the four bottom sections of the graph, it tells a different story: 62% of brands use more than 10 influencers. This highlights the importance of leveraging multiple influencers in your campaigns.
                </p>

                <h3 className="mt-5">Step 1: Finding the Right Influencers for Your Game</h3>
                <p>
                    <strong>Think of Influencer Sourcing Like Dating:</strong> Just as you wouldn't propose marriage on a first date, you shouldn't rush into a partnership with an influencer without proper consideration. Start by identifying influencers who align with your game's genre, style, and target audience.
                </p>

                <p>You can search social media sites for influencers to find ones that are a good fit for you. It might be challenging to find their contact information to reach out. Alternatively, you can use a database like the one Glitch has, and others, to more easily search through a list of influencers with their contact information readily available.</p>

                <p>
                    <strong>Budget Determines Your "League":</strong> In the dating world, people often talk about dating within their "league." In influencer marketing, your budget plays a similar role. If you have a modest budget of $1,000, you'll likely be working with nano-influencers (1K-10K followers) or micro-influencers (10K-100K followers). Larger budgets open doors to mid-level or even top-tier influencers. Here's a general guideline:
                </p>
                <ul>
                    <li><strong>Nano-influencers:</strong> 1K-10K followers, costing $0 to $100</li>
                    <li><strong>Micro-influencers:</strong> 10K-100K followers, costing $0 to $450</li>
                    <li><strong>Mid-level influencers:</strong> 100K-500K followers, costing $150 to $2,500</li>
                    <li><strong>Macro-influencers:</strong> 500K-1M followers, costing $250 to $7,000</li>
                    <li><strong>Mega-influencers:</strong> Over 1M followers, costing $1,500+</li>
                </ul>
                <p>
                    <strong>Set Your Standards:</strong> Beyond follower counts, consider the influencer's engagement rate, content quality, and audience demographics. An influencer with a smaller but highly engaged audience can be more valuable than one with a large but disengaged following.
                </p>

                <h3 className="mt-5 mb-4">Step 2: Assessing Influencer Engagement</h3>

                <img src="/assets/images/influencers/TikTok-Engagement-Rate.webp" className='img-fluid mb-3 rounded' />

                <p>A common mistake is thinking that just because an influencer has a large following, they are worth more. This is absolutely false and can lead to paying for an influencer who does not give your campaign any ROI (Return On Investment).</p>
                <p>
                    <strong>Quality Over Quantity:</strong> An influencer's engagement rate is a key indicator of how connected they are with their audience. For example, an influencer with 100,000 followers but only 1,000 views per video has an engagement rate of 1%, which may suggest a less engaged audience or even fake followers.
                </p>
                <p>
                    <strong>Aim for Higher Engagement:</strong> Look for influencers with engagement rates of 5% or higher. These influencers have audiences that are more likely to interact with their content, increasing the chances that they'll respond positively to your game.
                </p>

                <p>To calculate the engagement rate, you can use: Engagement Rate = (Total # of Post Engagements / # of Followers) x 100. For example, if the number of likes, views, and shares on a post is 1,000, and their follower count is 50,000, the engagement rate is 2%. Calculate this number and use it when deciding who to work with and during negotiations.</p>

                <h3 className="mt-5 mb-4">Step 3: Reaching Out to Influencers</h3>

                <img src="/assets/images/influencers/influencer_contact.jpg" className='img-fluid mb-3 rounded' />

                <p>Once you think you have found someone that is a good fit for your game—their brand and audience align with your game, the follower size is what you are looking for, and the engagement rate is acceptable—then you have to reach out.</p>
                <p>
                    <strong>Finding Contact Information:</strong> Once you've identified potential influencers, you'll need to find the best way to contact them. Some list their business email on their profiles, while others may require a bit of detective work. Professional influencer platforms can also provide contact details.
                </p>
                <p>
                    <strong>Crafting a Direct Message:</strong> When reaching out, personalize your message and be direct. Mention specific content they've created that you enjoyed, and explain why you believe your game would resonate with their audience. A personalized approach increases the likelihood of a response.
                </p>
                <p>
                    <strong>Persistence is Key:</strong> Influencers receive numerous messages daily. If you don't hear back immediately, don't be discouraged. Follow up respectfully, perhaps sending three to six messages over a period of time before moving on.
                </p>

                <p className='lead'>Here is an example email:</p>

                <pre>
{`Hi [first_name],

I see you are into [some_topic], and your followers seem to enjoy content about [content_type]. I am releasing a game called [game_name], and I wanted to see if there might be an alignment in streaming it to your followers.

[game_name] is about [one_line_description]. It's available on:

- [app_store_link]
- [website]

We are looking for you to stream on Twitch and TikTok, but also create content on:

- YouTube
- Reddit
- Bluesky

We are willing to pay [x_amount] for the deliverables. Can we have a conversation to see if this might be a fit?`}
</pre>

<p><strong>Set realistic expectations:</strong> out of every 15 influencers you invite, only about 1 will actually follow through and create content for your game. Even if influencers respond, the deal or negotiation phase might not work out, and a percentage of those who initially agree may never follow through. This means you’ll need to reach out to a large number of influencers to secure the ones you need for a successful campaign.</p>


<img src="/assets/images/influencers/infuencer-invite-ratio.png" className='img-fluid mb-3 rounded' />


                <h3 className="mt-5">Step 4: Negotiating Terms</h3>

                <p>Now, once an influencer responds, they rarely say yes on the first email. And if they have an agency or manager representing them, they almost never say yes, and you will have to negotiate your terms. During the negotiation process, there are several things you want to consider and take into account:</p>
                <p>
                    <strong>Discuss Deliverables:</strong> Clearly outline what you expect from the collaboration. Do you want the influencer to stream your game for a certain number of hours, create a review video, or promote it on their social media channels? Being specific helps avoid misunderstandings.
                </p>
                <p>
                    <strong>Set a Realistic Time Frame:</strong> Establish deadlines for when the content should be created and published. This ensures that your promotional efforts align with your marketing calendar, especially if you're aiming to build momentum around a game launch.
                </p>
                <p>
                    <strong>Define Content Usage Rights:</strong> It's crucial to clarify who owns the content and how it can be used. Can you share their videos on your social media channels? Are you allowed to use snippets in advertisements? Defining these rights prevents legal complications down the line.
                </p>
                <p>
                    <strong>Agree on Compensation:</strong> Discuss payment terms upfront. Influencers may have standard rates, but there may be room for negotiation based on the scope of work and mutual benefits. Some may require full payment upfront, while others might accept a deposit with the remainder paid upon completion.
                </p>

                <h3 className="mt-5">Step 5: Formalizing the Agreement</h3>
                <p>Now, those negotiations you had can go into a formal document and be sent over. A signed contract becomes a binding agreement of work and is a sign that you are about to get content created for your game. Make sure that you do the following:</p>
                <p>
                    <strong>Legal Protections for Both Parties:</strong> Once you've agreed on terms, put everything into a formal contract. This document should detail all aspects of the agreement, protecting both you and the influencer.
                </p>
                <p>
                    <strong>Key Elements to Include:</strong> Ensure the contract covers deliverables, timelines, compensation, content rights, confidentiality clauses, and termination conditions. It's wise to consult with a legal professional to ensure all bases are covered.
                </p>

                <h3 className="mt-5">Step 6: Overseeing Content Production</h3>
                <p>As the influencer starts to create content, you want to make sure you are watching the quality of the content. The content needs to best represent your game and meet the deliverables discussed. While some influencers create great content, there have been other influencers that have produced content that hurts brands. Consider the following:</p>
                <p>
                    <strong>Ensuring Quality and Alignment:</strong> Maintain open communication during the content creation process. Provide the influencer with game assets, branding guidelines, and any key messages you want highlighted.
                </p>
                <p>
                    <strong>Approval Processes:</strong> Depending on your agreement, you may have the opportunity to review the content before it goes live. Use this time to ensure the content accurately represents your game and aligns with your brand values.
                </p>

                <h3 className="mt-5 mb-4">Step 7: Exploring Various Content Formats</h3>

                <img src="/assets/images/influencers/content_ltv.png" className='img-fluid mb-3 rounded' />

                <p>
                    <strong>Beyond Live Streams:</strong> While live streams offer real-time engagement, they have a limited lifespan. Consider diversifying the content types to maximize exposure:
                </p>
                <ul>
                    <li><strong>Short-Form Videos:</strong> Platforms like TikTok or Instagram Reels can generate viral content that reaches new audiences.</li>
                    <li><strong>Long-Form Content:</strong> Detailed reviews or gameplay series on YouTube provide in-depth exposure.</li>
                    <li><strong>Social Media Posts:</strong> Tweets, Instagram posts, or Facebook updates can drive traffic and engagement.</li>
                    <li><strong>Blog Posts:</strong> Written content can improve SEO and provide lasting value to potential players researching your game.</li>
                </ul>
                <p>
                    <strong>Maximizing Longevity and Reach:</strong> Different content formats have varying lifespans and audience reach. By leveraging multiple formats, you increase the chances of sustaining interest over time.
                </p>

                <h3 className="mt-5 mb-4">Step 8: Monitoring Performance</h3>
                <img src="/assets/images/publishers/performance_based.gif" className='img-fluid mb-3 rounded'  />
                <p>Once all the content has been created, we need to track the impact the content has and how it will affect your game. This will be all about numbers in views, shares, likes, comments, and how all of that affects installs.</p>

                <p>
                    <strong>Tracking Deliverables:</strong> Keep a detailed record of all agreed-upon deliverables and their statuses. Tools like spreadsheets or project management software can help you stay organized.
                </p>
                <p>
                    <strong>Measuring Impact:</strong> While it can be challenging to attribute game downloads directly to influencer activity, monitor indicators such as website traffic spikes, increases in social media followers, and engagement metrics during and after the influencer's promotion.
                </p>

                <h3 className="mt-5 mb-4">Step 9: Recognizing Buyer Behavior</h3>
                <img src="/assets/images/influencers/game_marketing_funnel_influencers.png" className='img-fluid mb-3 rounded' />

                <p>In our <Link to={Navigate.publishersToolsGuidePage()}>marketing guide</Link>, we discussed the marketing funnel and how a potential player moves through it, ultimately deciding to buy or install your game. Influencer marketing primarily impacts the top of the funnel by raising awareness about the game, but it also influences the interest and, especially, the evaluation stages of the buying process.</p>

                <p>A common misconception is that if a user doesn’t use the affiliate link provided to an influencer, the influencer had no impact. This is false. People's behavior when deciding to buy a game is more complex than that, and affiliate links only partially capture how players move through the funnel.</p>

                <p>
                    <strong>Attribution Challenges:</strong> Consumers often interact with multiple touchpoints before making a purchase decision. They might see the influencer's content, then research your game independently, read reviews, and eventually make a purchase days or weeks later.
                </p>
                <p>
                    <strong>Building Brand Awareness:</strong> Influencer marketing is a powerful tool for building brand recognition and trust. Even if immediate sales aren't apparent, you're planting seeds that can lead to future conversions.
                </p>

                <h3 className="mt-5">Step 10: Streamlining the Process with Tools or Outsourcing</h3>
                <p>After going through this document and understanding what goes into influencer marketing, you have the option of doing it all yourself. But you may find you want better tools that optimize your game or someone who has more experience than you executing a campaign.</p>
                <p>
                    <strong>Platforms Like Glitch:</strong> Managing influencer campaigns can be time-consuming. Platforms like Glitch offer solutions to simplify the process:
                </p>
                <ul>
                    <li><strong>Influencer Databases:</strong> Access to a curated list of influencers suitable for your game.</li>
                    <li><strong>Campaign Management:</strong> Tools to manage communications, contracts, and deliverables.</li>
                    <li><strong>Automated Contracts:</strong> Generate and manage legal agreements efficiently.</li>
                    <li><strong>Performance Tracking:</strong> Analytics to measure the success of your campaigns.</li>
                </ul>
                <p>
                    <strong>Efficiency and Scalability:</strong> Utilizing such tools allows you to focus on strategic decisions while automating administrative tasks, making your influencer marketing efforts more effective.
                </p>
                <p>
                    <strong>Outsourcing:</strong> You can also find a consultant or agency to manage your influencer campaigns. Often these organizations use a retainer fee and manage the entire process. It is best that you set up clear expectations and deliverables if you outsource influencer work.
                </p>
                
                <h3 className="mt-5">Your Path to Successful Influencer Marketing</h3>
                <p>
                    Influencer marketing is a dynamic and powerful strategy to enhance your game's visibility. By understanding the process, setting realistic expectations, and building genuine relationships with influencers, you can create impactful campaigns that resonate with your target audience. Remember, success in influencer marketing doesn't happen overnight; it's about consistent effort, adaptability, and strategic planning.
                </p>
                <p>
                    We hope this guide has provided valuable insights to help you navigate the world of influencer marketing. Now, it's time to put these strategies into action and watch your game reach new heights!
                </p>
            </div>

            {modalVisible && (
                <div className="modal show d-block" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Download Workbook (Emailed to You)</h5>
                                <button type="button" className="btn-close" onClick={() => setModalVisible(false)}></button>
                            </div>
                            <div className="modal-body text-black">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" id="name" className="form-control" value={name} onChange={e => setName(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" id="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="game" className="form-label">Game (Optional)</label>
                                    <input type="text" id="game" className="form-control" value={game} onChange={e => setGame(e.target.value)} />
                                </div>
                                <p className="text-muted">Note: The list will be emailed to you and updated monthly.</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={handleDownload}>
                                    {loading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        'Download'
                                    )}
                                </button>
                                <button className="btn btn-secondary" onClick={() => setModalVisible(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <IndieSubmenu />
            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/influencers/influencers.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublisherGameInfluencersPage;
