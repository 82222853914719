import React from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

/**
 * Example investor data. You can add or edit as needed.
 */
const investorsData = [
  {
    name: 'Techstars',
    description: 'Techstars is the world\'s most active pre-seed investor having invested in more than 3,800 companies.',
    imageUrl: '/assets/images/investors/techstarslogo.jpg',
  },
  {
    name: 'HustleFund',
    description: 'Hustle Fund is a venture capital fund investing in hustlers at the pre-seed and seed stages.',
    imageUrl: '/assets/images/investors/hustlefund.jpg',
  },
  {
    name: 'VisibleHands',
    description: 'Visible Hands is the most trusted platform for early-stage, underrepresented founders building high-growth startups.',
    imageUrl: '/assets/images/investors/visiblehands.jpg',
  },
  {
    name: 'Google Black Founders Fund',
    description: 'Designed to help Black-led startups in Europe with up to $150K in capital, Google Cloud credits, and custom support from Google.',
    imageUrl: '/assets/images/investors/blackfoundersfund.jpg',
  },
];

/**
 * Modify or extend with real data about the leadership team
 */
const leadershipTeam = [
  {
    name: 'Devin (CEO & Founder)',
    role: 'CEO & Founder, Glitch',
    description: `
      Devin is a developer that brings years of experience in raising capitl and building innovative startups. 
      He leads our mission to simplify game marketing for all developers, from solo to AAA. 
    `,
    imageUrl: '/assets/images/about/devin_dixon.jpg', // Example only: add or replace with a real image
  },
  // Add more team members as needed
];

function PublisherPressPage() {
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet prioritizeSeoTags>
        {/* ========== SEO Optimization ========== */}
        <meta charSet="utf-8" />
        <title>Glitch Press Kit | Game Marketing Made Simple</title>
        <meta
          name="description"
          content="Glitch Press Kit: Empowering game developers with revolutionary marketing tools. Learn about our mission, features, investors, and contact info."
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Glitch Press Kit" />
        <meta
          property="og:description"
          content="Glitch Press Kit: Empowering game developers with revolutionary marketing tools. Learn about our mission, features, investors, and contact info."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta
          property="og:image"
          content="https://www.glitch.fun/assets/images/publishers/banner_4.png"
        />
      </Helmet>

      <Header />

      {/* 
        =========================================================================
        BANNER / HERO SECTION
        =========================================================================
      */}
      <section
        className="container-fluid text-center text-white py-5"
        style={bannerStyle}
      >
        <h1 className="mt-5" style={{ fontSize: '2.5rem' }}>
          <br />
          Glitch Gaming <br /><br /><small style={{ textTransform: 'none' }}>Allowing Solo and Indie Devs To Market Like AAA Games</small>
        </h1>

      </section>

      {/* 
        =========================================================================
        COMPANY OVERVIEW
        =========================================================================
      */}
      <section className="py-5" style={{ backgroundColor: '#232a5c' }}>
        <div className="container">
          {/* Mission Statement */}
          <h2 className="text-center mb-4" style={{ textTransform: 'uppercase' }}>
            Mission
          </h2>
          <p>
            Our mission is to create a marketing solution that is both powerful and simple,
            enabling solo developers and indie studios to market their games at the level of
            AAA publishers. From social media campaigns to influencer collaborations, our
            goal is to empower developers to achieve enough success to sustain themselves and
            a small team, all while continuing to do what they love: making games.
          </p>

          {/* About Us / Short Overview */}
          <h3 className="mt-5" style={{ textTransform: 'uppercase' }}>
            About Glitch
          </h3>
          <p>
            Glitch was founded with a core belief: that game marketing should be straightforward,
            scalable, and accessible to all developers, regardless of their size. Our platform
            streamlines social scheduling, influencer outreach, playtesting, and more. With
            Glitch, you can focus on crafting immersive worlds while we handle the heavy
            lifting of connecting you to the right audience.
          </p>

          {/* Founders and Leadership Bios */}
          <h3 className="mt-5" style={{ textTransform: 'uppercase' }}>
            Founders & Leadership
          </h3>
          <div className="row">
            {leadershipTeam.map((member, idx) => (
              <div key={idx} className="col-md-4 mb-4">
                <div className="card bg-transparent border-0 text-white h-100">
                  <div className="card-body">
                    {member.imageUrl && (
                      <img
                        src={member.imageUrl}
                        alt={member.name}
                        className="img-fluid rounded-circle mb-3"
                        style={{ width: '120px', height: '120px', objectFit: 'cover' }}
                      />
                    )}
                    <h5>{member.name}</h5>
                    <p><em>{member.role}</em></p>
                    <p>{member.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Investors & Funders */}
          <h3 className="mt-3 mb-3" style={{ textTransform: 'uppercase' }}>
            Investors and Funders
          </h3>
          {investorsData.map((investor, index) => (
            <div key={index} className="d-flex align-items-start mb-4">
              <img
                src={investor.imageUrl}
                alt={investor.name}
                className="me-3 rounded-circle"
                style={{ width: '80px', height: '80px', objectFit: 'cover' }}
              />
              <div>
                <h3>{investor.name}</h3>
                <p>{investor.description}</p>
              </div>
            </div>
          ))}

        </div>
      </section>

      {/* 
        =========================================================================
        WHY NOW (KEY FACTS & DATA) + OPPORTUNITIES
        =========================================================================
      */}
      <section className="py-5 bg-dark text-white">
        <div className="container">
          <h2 className='text-center mb-3' style={{ textTransform: 'uppercase' }}>Key Facts & Why Now</h2>

          <h3 className="mt-4" style={{ textTransform: 'uppercase' }}>
            The Problem With The Game Industry
          </h3>
          <div className="row">
            <div className="col-md-7 order-2 order-md-1">
              <p className="lead">
                The gaming industry is facing a crisis: massive layoffs, studio downsizing, and
                canceled projects have left many professionals searching for new opportunities.
                In 2023 alone, over 10,500 jobs were lost, followed by an additional 14,600 in 2024.
                As a result, many of those affected have turned to indie game development,
                creating their own titles. However, the skills required to develop a game are vastly different from those
                needed to market one, leaving many developers struggling to connect their IP
                with the right audience.
              </p>
            </div>
            <div className="col-md-5 order-1 order-md-2">
              <img
                src="/assets/images/publishers/how_devs_feel_about_marketing.jpg"
                className="img-fluid rounded mb-2"
                alt="Developers and marketing"
              />
            </div>
          </div>



          <div className="row mt-5">
            {/* Title Section */}
            <div className="col-12 col-md-7 order-1 order-md-2">
              <h3 className="mt-0" style={{ textTransform: 'uppercase' }}>
                The Opportunity For Solo And Indie Devs
              </h3>
              <p className="lead">
                With 3 billion gamers worldwide and nearly 17,000 new games released every year, 54% of
                developers still do no marketing for their games, leading to the failure of the vast
                majority of titles. Fortunately, advancements in marketing technology now make it possible
                to automate game marketing, offering a path forward for developers.
              </p>
            </div>

            {/* Image Section */}
            <div className="col-12 col-md-5 order-2 order-md-1">
              <img
                src="/assets/images/publishers/game_devs_dont_market.jpg"
                className="img-fluid rounded mb-2"
                alt="Game Developers Don't Market"
              />
            </div>
          </div>


        </div>

        <div className="container">
          <h3 className="mt-5" style={{ textTransform: 'uppercase' }}>What Developers Are Saying About Glitch</h3>

          <ul>
            <li><p className="lead">
              <em>
                "Glitch redefines how indies approach marketing—less grunt work, more creativity, and
                better results."
              </em>{' '}
              - Jeff Of Ari Baktu
            </p></li>

            <li><p className="lead">
              <em>
                "I love what you've put together here as a resource for testing, and the big
                differentiator... marketing the hook into influencers and the help you have
                around media kits are big gaps in the indie space...."
              </em>{' '}
              - /cls333 (reddit) Of A Handful of Dust
            </p></li>
          </ul>
        </div>
      </section>


      {/* 
        =========================================================================
        PRESS COVERAGE & INVESTORS
        =========================================================================
      */}
      <section className="py-5" style={{ backgroundColor: '#232a5c' }}>
        <div className="container">

          <div className="container">
            <h2 className="text-center text-white mb-5" style={{ textTransform: 'uppercase' }}>
              Product & Key Features
            </h2>

            {/* Feature Grid */}
            <div className="row text-center">
              {/* Influencer CRM */}
              <div className="col-md-4 mb-4">
                <div className="card bg-transparent border-0 h-100 text-white">
                  <div className="card-body">
                    <h4>Influencer CRM & Database</h4>
                    <img
                      src="/assets/images/publishers/overview-crm.png"
                      style={{ maxHeight: '200px' }}
                      className="img-fluid rounded"
                      alt="Influencer CRM"
                    />
                    <p>
                      Discover, track, and manage thousands of creator relationships with outreach
                      tools and performance analytics.
                    </p>
                    <Link
                      className="default-button reverse-effect mt-3"
                      to={Navigate.publishersCRMPage()}
                    >
                      <span>Read More</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* Social Scheduler */}
              <div className="col-md-4 mb-4">
                <div className="card bg-transparent border-0 h-100 text-white">
                  <div className="card-body">
                    <h4>Social Scheduler & Devlogs</h4>
                    <img
                      src="/assets/images/publishers/overview-social.jpg"
                      style={{ maxHeight: '200px' }}
                      className="img-fluid rounded"
                      alt="Social Scheduler"
                    />
                    <p>
                      Automate your social content with our weekly scheduler. Turn dev logs into
                      multiple social posts in just minutes.
                    </p>
                    <Link
                      className="default-button reverse-effect mt-3"
                      to={Navigate.publishersSocialPage()}
                    >
                      <span>Learn More</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* Play Tester Database */}
              <div className="col-md-4 mb-4">
                <div className="card bg-transparent border-0 h-100 text-white">
                  <div className="card-body">
                    <h4>Play Tester Database</h4>
                    <img
                      src="/assets/images/publishers/overview-testers.png"
                      style={{ maxHeight: '200px' }}
                      className="img-fluid rounded"
                      alt="Play Tester Database"
                    />
                    <p>
                      Gather honest feedback before launch with our built-in network of avid
                      gamers eager to playtest.
                    </p>
                    <Link
                      className="default-button reverse-effect mt-3"
                      to={Navigate.publishersPlayTesterPage()}
                    >
                      <span>Details Here</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="row text-center mt-4">
              {/* Media Kits */}
              <div className="col-md-4 mb-4">
                <div className="card bg-transparent border-0 h-100 text-white">
                  <div className="card-body">
                    <h4>Media Kits</h4>
                    <img
                      src="/assets/images/publishers/overview-press.jpg"
                      style={{ maxHeight: '200px' }}
                      className="img-fluid rounded"
                      alt="Media Kits"
                    />
                    <p>
                      Impress journalists and podcasters with sleek media kits for your game,
                      created in a matter of minutes.
                    </p>
                    <Link
                      className="default-button reverse-effect mt-3"
                      to={Navigate.publishersMediakitPage()}
                    >
                      <span>View Media Kits</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* Community Platform */}
              <div className="col-md-4 mb-4">
                <div className="card bg-transparent border-0 h-100 text-white">
                  <div className="card-body">
                    <h4>Community Platform</h4>
                    <img
                      src="/assets/images/publishers/overview-community.jpg"
                      style={{ maxHeight: '200px' }}
                      className="img-fluid rounded"
                      alt="Community Platform"
                    />
                    <p>
                      Build your fanbase with dedicated community tools—live streaming,
                      short-form content, user engagement, and more.
                    </p>
                    <Link
                      className="default-button reverse-effect mt-3"
                      to={Navigate.publishersCommunityPage()}
                    >
                      <span>Check It Out</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* Newsletter */}
              <div className="col-md-4 mb-4">
                <div className="card bg-transparent border-0 h-100 text-white">
                  <div className="card-body">
                    <h4>Newsletter</h4>
                    <img
                      src="/assets/images/publishers/overview-newsletter.jpg"
                      style={{ maxHeight: '200px' }}
                      className="img-fluid rounded"
                      alt="Newsletter"
                    />
                    <p>
                      Keep players in the loop with newsletters, with direct access to their
                      contact information and inboxes.
                    </p>
                    <Link
                      className="default-button reverse-effect mt-3"
                      to={Navigate.publishersNewsletterPage()}
                    >
                      <span>See Newsletters</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            {/* Product Videos */}
            {/* Product Videos */}
            <h2 className="text-center text-white mb-5" style={{ textTransform: 'uppercase' }}>
              Product Videos
            </h2>
            <div className="row g-4">
              {/* DevLog */}
              <div className="col-12 col-md-6">
                <h5 className="text-white">DevLog To Social</h5>
                <div className="ratio ratio-16x9 border border-white border-2">
                  <iframe
                    src="https://www.youtube.com/embed/7yKPRmbr66Y"
                    allowFullScreen
                    title="Dev Log Promo Video"
                  ></iframe>
                </div>
              </div>
              {/* Influencer CRM */}
              <div className="col-12 col-md-6">
                <h5 className="text-white">Influencer CRM</h5>
                <div className="ratio ratio-16x9 border border-white border-2">
                  <iframe
                    src="https://www.youtube.com/embed/gqyMALpUcNQ"
                    allowFullScreen
                    title="Influencer CRM Video"
                  ></iframe>
                </div>
              </div>
              {/* Gaming Influencers */}
              <div className="col-12 col-md-6">
                <h5 className="text-white">Gaming Influencers</h5>
                <div className="ratio ratio-16x9 border border-white border-2">
                  <iframe
                    src="https://www.youtube.com/embed/OvxX2jy0erw"
                    allowFullScreen
                    title="Gaming Influencers Video"
                  ></iframe>
                </div>
              </div>
              {/* Gaming Influencers */}
              <div className="col-12 col-md-6">
                <h5 className="text-white">Pipeline Management</h5>
                <div className="ratio ratio-16x9 border border-white border-2">
                  <iframe
                    src="https://www.youtube.com/embed/O2v-Joie56U"
                    allowFullScreen
                    title="Pipeline Management"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>

      {/* 
        =========================================================================
        VISUAL ASSETS (LOGOS, ETC.)
        =========================================================================
      */}
      <section className="py-5 bg-dark text-white">
        <div className="container text-center">
          <h2 style={{ textTransform: 'uppercase' }}>Logos & Visual Assets</h2>
          <p>Use these official Glitch logos for news coverage or promotional materials.</p>
          <div className="d-flex flex-wrap justify-content-center">
            <img
              src="/assets/images/logo/glitch_1.jpg"
              alt="Glitch Logo 1"
              className="m-3"
              style={{ maxWidth: '200px' }}
            />
            <img
              src="/assets/images/logo/glitch_2.jpg"
              alt="Glitch Logo 2"
              className="m-3"
              style={{ maxWidth: '200px' }}
            />
            <img
              src="/assets/images/logo/glitch_3.png"
              alt="Glitch Logo 3"
              className="m-3"
              style={{ maxWidth: '200px' }}
            />
          </div>
        </div>
      </section>

      {/* 
        =========================================================================
        CONTACT INFORMATION
        =========================================================================
      */}
      <section className="py-5">
        <div className="container text-center">
          <h2 style={{ textTransform: 'uppercase' }}>Contact & Press Inquiries</h2>
          <p>
            For media requests, interviews, or additional information, please reach out:
          </p>
          <p>
            <strong>Email CEO Devin:</strong>{' '}
            <Link to="mailto:devin@glitch.fun">devin@glitch.fun</Link>
          </p>
          <p className="mt-4">
            <em>We invite members of the press to schedule demos, interviews, or request
              early previews of our product. We look forward to collaborating with you!</em>
          </p>

          {/* Latest Press Releases / Blog */}
          <h3 className="mt-5" style={{ textTransform: 'uppercase' }}>
            Press & Coverage
          </h3>
          <p>
            Check out our latest announcements, press releases, and industry insights on our blog.
          </p>
          <Link
            className="default-button reverse-effect mt-3 me-3"
            to="https://glitch.ghost.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Visit Our Blog</span>
          </Link>
          <Link
            className="default-button reverse-effect mt-3"
            to="https://bsky.app/profile/glitchinfluencers.bsky.social"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>BlueSky</span>
          </Link>
        </div>
      </section>

      <Footer />
    </>
  );
}

/**
 * Inline styling for the banner/hero background
 */
const bannerStyle = {
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/banner_4.png")',
  height: '450px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export default PublisherPressPage;
