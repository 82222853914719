import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Dynamically import Swiper only on the client side
const DynamicSwiper = React.lazy(() => import('swiper/react').then(module => ({ default: module.Swiper })));
const DynamicSwiperSlide = React.lazy(() => import('swiper/react').then(module => ({ default: module.SwiperSlide })));

let BannerListContent = [
    {
        bgImgUrl: '/assets/images/banner/home-2/bg.jpg',
        title: "Glitch",
        subtitle: "Build, Play, Profit: Your Gaming Community Begins Here!",
        desc: "Transform your gaming hobby into a paying gig! Connect with game publishers, contribute to game promotion, and cultivate your gaming community.",
        btnText: 'join us today',
    }
];

const BannerTwo = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true); // Detect client-side rendering
    }, []);

    if (!isClient) {
        return null; // Render nothing during SSR
    }

    return (
        <div className="banner__slider overflow-hidden">
            <React.Suspense fallback={<div>Loading...</div>}>
                <DynamicSwiper spaceBetween={30} slidesPerView={1} loop={true}>
                    {BannerListContent.map((val, i) => (
                        <DynamicSwiperSlide key={i}>
                            <div
                                className="banner"
                                style={{ backgroundImage: `url(${val.bgImgUrl})` }}
                            >
                                <div className="container">
                                    <div className="row g-0">
                                        <div className="col-lg-6 col-12">
                                            <div className="banner__content-1">
                                                <h1>{val.title}</h1>
                                                <h2>{val.subtitle}</h2>
                                                <p>{val.desc}</p>
                                                <Link
                                                    to="#goal"
                                                    onClick={(e) => {
                                                        window.scrollBy(0, 600);
                                                    }}
                                                    className="default-button"
                                                >
                                                    <span>
                                                        Read More{" "}
                                                        <i className="icofont-play-alt-1"></i>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DynamicSwiperSlide>
                    ))}
                </DynamicSwiper>
            </React.Suspense>
        </div>
    );
};

export default BannerTwo;
