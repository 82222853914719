import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUsers, faDollarSign, faGamepad, faVideo, faComments } from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

function PublishersCommunityPage() {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Build and Monetize Your Gaming Community | Glitch Gaming</title>
        <meta
          name="description"
          content="Learn how to create a vibrant gaming community with tools for live streaming, revenue generation, user engagement, and more."
        />
        <meta property="og:title" content="Build and Monetize Your Gaming Community | Glitch" />
        <meta property="og:description" content="Learn how to create a vibrant gaming community with tools for live streaming, revenue generation, user engagement, and more." />
        <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/publishers/community_banner.webp" />
      </Helmet>
      <Header />
      <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
        <h1 className="display-4">Build and Monetize Your Gaming Community</h1>
        <p className="lead">Combine tools for streaming, user engagement, and revenue generation into one powerful platform.</p>
      </section>

      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-5">Our Community Platform Is Optimized For Gaming</h2>
          <div className="row text-center">
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faGamepad} size="3x" className="text-primary mb-3" />
              <h4>Live Streaming</h4>
              <p>Enable users to live stream their gameplay, connecting with fans and building their audience.</p>
            </div>
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faXTwitter} size="3x" className="text-success mb-3" />
              <h4>Short Form Content</h4>
              <p>Have your fans create engaging short-form content such as memes, clips, questions, advice and more.</p>
            </div>
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faUsers} size="3x" className="text-warning mb-3" />
              <h4>User Engagement</h4>
              <p>Features like live chat, private messaging, and user profiles boost interaction.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-5 text-black">Leverage User-Generated Content</h2>
          <div className="row text-center text-black">
            <div className="col-md-4 mb-4">
              <img src="/assets/images/features/live-streaming.png" alt="Live Streaming" className="img-fluid mb-3" />
              <h4 className='text-black'>Retention</h4>
              <p>Increase retention by having user engage with content outside the game.</p>
            </div>
            <div className="col-md-4 mb-4">
              <img src="/assets/images/features/trophy.png" alt="Competitions" className="img-fluid mb-3" />
              <h4 className='text-black'>Highlight Members</h4>
              <p>Highlight and promote your super fans, making them feel special and apprecited.</p>
            </div>
            <div className="col-md-4 mb-4">
              <img src="/assets/images/features/social-media.png" alt="Social Media" className="img-fluid mb-3" />
              <h4 className='text-black'>Re-Share The Content</h4>
              <p>Re-share your users content to help drive growth and brand awareness.</p>
            </div>
          </div>
        </div>
      </section>

      

      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-5">Increase Your Reach</h2>
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2">
              <img
                src="/assets/images/marketing/tiktok_instagram.jpg"
                alt="Increase Reach"
                className="img-fluid rounded shadow mb-3"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h3>Multicast to Multiple Platforms</h3>
              <p>Stream to multiple platforms simultaneously, including Twitch, YouTube, and Facebook, to expand your audience.</p>
              <p>Share recorded content on social media and create short-form videos for more engagement.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container">
          <h2 className="text-center mb-5 text-black">Monetize Your Streams</h2>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src="/assets/images/revenue/sponsored_1.jpg"
                alt="Monetize Streams"
                className="img-fluid rounded shadow mb-3"
              />
            </div>
            <div className="col-lg-6 text-black">
              <h3 className='text-black'>Stream Monetization</h3>
              <p>Generate revenue through ads, donations, in-app purchases, and live shopping experiences.</p>
              <ul>
                <li>Banner Ads</li>
                <li>Video Pre-Roll, Mid-Roll, and Post-Roll</li>
                <li>On-Screen Donations</li>
                <li>Interactive Ads</li>
              </ul>
              <Link to="/revenue" className="btn btn-primary mt-3">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>

      

      <section className="cta-section py-5">
        <div className="container text-center">
          <h2 className="mb-4">Ready to Build Your Community?</h2>
          <p className="lead">Join our platform to create, engage, and monetize your gaming community.</p>
          <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Get Started Now</span></Link>
        </div>
      </section>

      <Footer />
    </>
  );
}

const bannerStyle = {
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/community_banner.webp")',
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
};

export default PublishersCommunityPage;
