import { 
    faDatabase, 
    faDollarSign, 
    faGamepad, 
    faRobot, 
    faThumbsUp, 
    faTools, 
    faUsers, 
    faVial, 
    faEnvelope, 
    faFileAlt, 
    faCalendarAlt 
} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import Navigate from "../../../../../util/Navigate";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IndieSubmenu = () => {
    const location = useLocation();

    return (
        <div className="container text-center my-5">
            <h4>See More of Glitch's Indie Tools</h4>
            <div className="d-flex justify-content-center align-items-center flex-wrap">
                <Link
                    to={Navigate.publishersToolsPage()}
                    className="btn btn-outline-primary mx-2 mb-2"
                >
                    <FontAwesomeIcon icon={faRobot} className="me-1" /> Free Tools
                </Link>
                <Link
                    to={Navigate.publishersDevlogsPage()}
                    className="btn btn-outline-primary mx-2 mb-2"
                >
                    <FontAwesomeIcon icon={faUsers} className="me-1" /> Dev Logs To Social
                </Link>
                <Link
                    to={Navigate.publishersNewsletterPage()}
                    className="btn btn-outline-primary mx-2 mb-2"
                >
                    <FontAwesomeIcon icon={faEnvelope} className="me-1" /> Newsletter
                </Link>
                <Link
                    to={Navigate.publishersMediakitPage()}
                    className="btn btn-outline-primary mx-2 mb-2"
                >
                    <FontAwesomeIcon icon={faFileAlt} className="me-1" /> Media Kit
                </Link>
                <Link
                    to={Navigate.publishersPlayTesterPage()}
                    className="btn btn-outline-primary mx-2 mb-2"
                >
                    <FontAwesomeIcon icon={faVial} className="me-1" /> Testers
                </Link>
                <Link 
                    to={Navigate.publishersSocialPage()} 
                    className="btn btn-outline-primary mx-2 mb-2"
                >
                    <FontAwesomeIcon icon={faCalendarAlt} className="me-1" /> Social Scheduler
                </Link>
                <Link
                    to={Navigate.publishersCommunityPage()}
                    className="btn btn-outline-primary mx-2 mb-2"
                >
                    <FontAwesomeIcon icon={faDollarSign} className="me-1" /> Community Platform
                </Link>
            </div>
        </div>
    );
};

export default IndieSubmenu;
