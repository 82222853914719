import React, { useEffect, useState } from "react";

let ReactQuill = null;

if (typeof window !== "undefined") {
  ReactQuill = require("react-quill");
  require("react-quill/dist/quill.snow.css");
}

export default function Wysiwyg({
  children,
  name,
  placeholder,
  className,
  value,
  id,
  onChange,
  toolbarOptions = [],
}) {
  // Ensure children is properly sanitized
  const safeChildren =
    typeof children === "object" || typeof children === "function"
      ? JSON.stringify(children)
      : children;

  const defaultToolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ];

  const toolbar = toolbarOptions.length > 0 ? toolbarOptions : defaultToolbarOptions;

  if (ReactQuill) {
    return (
      <ReactQuill
        theme="snow"
        modules={{ toolbar }}
        style={{ backgroundColor: "white", color: "black" }}
        name={name}
        placeholder={placeholder || ""}
        className={className}
        id={id}
        value={safeChildren || ""}
        onChange={onChange}
      />
    );
  }

  // Fallback to textarea if ReactQuill is unavailable
  return (
    <textarea
      name={name}
      placeholder={placeholder || ""}
      className={className}
      id={id}
      value={safeChildren || ""}
      onChange={(e) => onChange(e.target.value)}
      style={{ width: "100%", minHeight: "200px" }}
    />
  );
}
