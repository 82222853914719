import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUserCheck, faCommentDots, faVideo, faFileAlt, faGamepad, faUsers, faFilter, faUpload, faClipboardList, faEdit, faThumbsUp, faDatabase, faRobot, faMoneyBill, faGift, faEnvelope, faBullhorn, faTools, faDollarSign, faBug, faChartLine, faClipboardCheck, faVial } from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

function PublishersPlayersPage() {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>Find Play Testers for Your Game | Glitch Gaming</title>
                <meta name="description" content="Discover dedicated play testers to test your game. Utilize Glitch's platform to search, sort, and engage with players who provide valuable feedback through text, video, and audio." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Find Play Testers for Your Game | Glitch" />
                <meta property="og:description" content="Discover dedicated play testers to test your game. Utilize Glitch's platform to search, sort, and engage with players who provide valuable feedback through text, video, and audio." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/play_testers_banner.webp" />
            </Helmet>
            <Header />
            <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <h1 style={{ fontSize: "2.5rem" }}><br /><br />Find Dedicated Play Testers<br /><br />To Perfect Your Game</h1>
            </section>

            {/* Main Content */}
            <section className="py-5">
                <div className="container">

                    {/* Statistics Section */}
                    <div className="mb-5 text-center">
                        <h2 className="mb-4">Why Play Testing Matters</h2>
                        <p className="lead">Ensure your game delivers an exceptional experience by identifying and fixing issues before launch.</p>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faBug} size="3x" className="text-danger mb-3" />
                                <h4>Reduce Negative Reviews</h4>
                                <p>Games with extensive play testing see a 30% decrease in negative reviews related to bugs.</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faChartLine} size="3x" className="text-success mb-3" />
                                <h4>Improve Retention</h4>
                                <p>Well-tested games have a 25% higher player retention rate after the first week.</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faClipboardCheck} size="3x" className="text-primary mb-3" />
                                <h4>Enhance Player Satisfaction</h4>
                                <p>Games that incorporate player feedback during development see increased player satisfaction scores.</p>
                            </div>
                        </div>
                    </div>

                    {/* Feature 1 */}
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4">Search Through Our Play Tester Database</h2>
                            <p className="lead">Access a vast database of play testers eager to test your game. Filter and sort by their gaming preferences, expertise, and more.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faSearch} className="me-2 text-primary" /> Advanced Search Options</li>
                                <li><FontAwesomeIcon icon={faFilter} className="me-2 text-success" /> Filter by Game Genres and Preferences</li>
                                <li><FontAwesomeIcon icon={faUsers} className="me-2 text-warning" /> Review In-Depth Bios</li>
                            </ul>
                            <form className="contact-form mt-4" id="contact-form" method="POST">
                                <div className="form-group w-100 text-center">
                                    <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Get Started for Free</span></Link>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/images/publishers/player_tester_bio.png" alt="Search Play Testers" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* Feature 2 */}
                    <div className="row align-items-center mb-5 bg-light p-4 rounded">
                        <div className="col-lg-6 order-lg-2 text-black">
                            <h2 className="mb-4 text-black">Collect Valuable Feedback</h2>
                            <p className="lead">Receive detailed feedback from play testers in text, video, and audio formats. Understand their experiences to improve your game.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faCommentDots} className="me-2 text-info" /> Text Feedback</li>
                                <li><FontAwesomeIcon icon={faVideo} className="me-2 text-danger" /> Video Feedback</li>
                                <li><FontAwesomeIcon icon={faFileAlt} className="me-2 text-secondary" /> Audio Feedback</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <div className="ratio ratio-16x9 rounded shadow mb-3 border border-white border-2">
                                <video className="w-100 rounded shadow" src="/assets/videos/publishers/gamer_bugs.mp4" autoPlay loop muted playsInline></video>
                            </div>
                        </div>
                    </div>

                    {/* Feature 3 */}
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4">Customize Your Play Test</h2>
                            <p className="lead">Choose from a list of predefined questions or create your own to gather specific insights from testers.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faClipboardList} className="me-2 text-success" /> Predefined Question Templates</li>
                                <li><FontAwesomeIcon icon={faEdit} className="me-2 text-warning" /> Create Custom Questions</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/images/publishers/play_tester_questions.png" alt="Customize Questions" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* Feature 4 */}
                    <div className="row align-items-center mb-5 bg-light p-4 rounded">
                        <div className="col-lg-6 order-lg-2 text-black">
                            <h2 className="mb-4 text-black">Review Gameplay Footage</h2>
                            <p className="lead">Play testers can upload their gameplay sessions, allowing you to observe their interactions and identify areas for improvement.</p>
                            <ul className="lead list-unstyled">
                                <li><FontAwesomeIcon icon={faUpload} className="me-2 text-primary" /> Upload Gameplay Videos</li>
                                <li><FontAwesomeIcon icon={faGamepad} className="me-2 text-danger" /> Observe Player Interactions</li>
                            </ul>
                            <form className="contact-form mt-4" id="contact-form" method="POST">
                                <div className="form-group w-100 text-center">
                                    <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Sign Up Now</span></Link>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <div className="ratio ratio-16x9 rounded shadow mb-3 border border-white border-2">
                                <video className="w-100 rounded shadow" src="/assets/videos/publishers/game_development.mp4" autoPlay loop muted playsInline></video>
                            </div>
                        </div>
                    </div>

                    {/* CTA Section */}
                    <div className='pt-5 pb-5 bg-dark text-white text-center rounded' style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}>
                        <h2 className="text-danger mb-4">Perfect Your Game Before Launch</h2>
                        <p className="lead">Engage with dedicated play testers and refine your game based on real player feedback. Avoid costly post-launch fixes and negative reviews.</p>
                        <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-lg btn-danger text-white">Start Play Testing Now</Link>
                        <p className="lead mt-4">Learn more about the importance of play testing in game development.</p>
                        <a href="https://glitch.ghost.io/play-testing-benefits/" target='_blank' className="btn btn-outline-light">Read Full Article</a>
                    </div>

                    <IndieSubmenu />

                </div>
            </section>

            <Footer />
        </>
    );
}

const bannerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/play_testers_banner.webp")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublishersPlayersPage;
