import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGift,
  faStar,
  faShoppingCart,
  faSnowflake,
  faPercentage,
  faUsers,
  faRobot,
  faDatabase,
  faThumbsUp,
  faTools,
  faGamepad,
  faDollarSign,
  faMoneyBill,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function PublishersHolidayPage() {

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Boost Your Game Sales This Holiday Season with Glitch | Limited Time Offer!</title>
        <meta name="description" content="Maximize your game's revenue this holiday season with Glitch. Get 50% off all subscription packages before December 25th. Harness the power of influencer marketing during the most lucrative time of the year." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Boost Your Game Sales This Holiday Season with Glitch | Limited Time Offer!" />
        <meta property="og:description" content="Maximize your game's revenue this holiday season with Glitch. Get 50% off all subscription packages before December 25th. Harness the power of influencer marketing during the most lucrative time of the year." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/holiday.webp" />
      </Helmet>
      <Header />
      <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
        <h1 style={{ fontSize: "2.5rem" }}><br />Boost Your Game Sales This Holiday Season<br /><br />With Influencer Marketing</h1>
        <Link className="btn btn-lg btn-danger mt-3" to={Navigate.publishersOnboardingStep1Page()}><FontAwesomeIcon icon={faGift} className="me-2" />Grow Your Wishlist Now</Link>
      </section>

      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-5">The Holiday Season is Crucial for Game Developers</h2>
          <div className="row text-center">
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faShoppingCart} size="3x" className="text-danger mb-3" />
              <h4>30% of Annual Revenue</h4>
              <p>The holiday season accounts for up to 30% of annual gaming revenue.</p>
            </div>
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faStar} size="3x" className="text-danger mb-3" />
              <h4>$13.4 Billion in Sales</h4>
              <p>In 2020, holiday spending on video games in the U.S. reached $13.4 billion.</p>
            </div>
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faUsers} size="3x" className="text-danger mb-3" />
              <h4>52% Plan to Buy Games</h4>
              <p>Over half of Americans aged 18-34 plan to purchase video games as holiday gifts.</p>
            </div>
          </div>
          <div className="text-center mt-4">
            <Link className="btn btn-primary btn-lg" to={Navigate.publishersOnboardingStep1Page()}>Start Your Holiday Campaign</Link>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light text-black">
        <div className="container">
          <h2 className="text-center mb-5 text-black">Maximize Holiday Sales With Influencers</h2>
          <div className="row align-items-center mb-5">
            <div className="col-lg-6">
              <img src="/assets/images/publishers/campaign_statistics.gif" alt="Holiday Campaign" className="img-fluid rounded shadow mb-3" />
            </div>
            <div className="col-lg-6">
              <h3 className="mb-4 text-black">Grow Your Wishlist Sign-Ups and Installs</h3>
              <p className="lead text-black">Expand your reach this buying season with unique influencer marketing strategies to highly engaged communities.</p>
              <ul>
                <li>Host holiday stream giveaways to attract new players.</li>
                <li>Boost conversions with captivating short-form content.</li>
                <li>Encourage purchases with bundled game offers.</li>
                <li>Gain exposure in special game showcases.</li>
                <li>Increase retention with specialized content.</li>
              </ul>
              <Link className="btn btn-danger mt-3" to={Navigate.publishersOnboardingStep1Page()}><FontAwesomeIcon icon={faGift} className="me-2" />Start Growing Now</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 text-white" style={{ backgroundColor: '#343a40' }}>
        <div className="container">
          <h2 className="text-center mb-5">Limited Time Offer: 70% Off All Subscriptions</h2>
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <p className="lead">Sign up by November 8th to get 70% off, by November 29th for 50% off, and by December 25th for 30% off any of our subscription packages. Don’t miss this exclusive holiday deal to boost your game’s success!</p>
              <Link className="btn btn-lg btn-danger mt-3" to={Navigate.publishersOnboardingStep1Page()}><FontAwesomeIcon icon={faPercentage} className="me-2" />Claim Your Discount</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light text-black">
        <div className="container">
          <h2 className="text-center mb-5 text-black">Glitch Will Make Running Your Holiday Campaign Seamless</h2>
          <div className="row text-center">
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faRobot} size="3x" className="text-primary mb-3" />
              <h4 className='text-black'>AI-Powered Solutions</h4>
              <p>Let our AI create and manage your influencer campaigns efficiently.</p>
            </div>
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faDatabase} size="3x" className="text-primary mb-3" />
              <h4 className='text-black'>Extensive Influencer Database</h4>
              <p>Access thousands of influencers across various platforms.</p>
            </div>
            <div className="col-md-4 mb-4">
              <FontAwesomeIcon icon={faThumbsUp} size="3x" className="text-primary mb-3" />
              <h4 className='text-black'>Performance-Based Marketing</h4>
              <p>Pay only for results, ensuring a high ROI on your marketing spend.</p>
            </div>
          </div>
          <div className="text-center mt-4">
            <Link className="btn btn-primary btn-lg" to={Navigate.publishersOnboardingStep1Page()}>Start Your Holiday Campaign</Link>
          </div>
        </div>
      </section>

      <div className="container text-center mb-4 pt-3">
        <h4>Explore Our Features</h4>
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <Link to={Navigate.publishersAIPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faRobot} className="me-1" /> AI
          </Link>
          <Link to={Navigate.publishersCRMPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faUsers} className="me-1" /> CRM
          </Link>
          <Link to={Navigate.publishersDatabasePage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faDatabase} className="me-1" /> Database
          </Link>
          <Link to={Navigate.publishersBenefitsPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faThumbsUp} className="me-1" /> Performance-Based
          </Link>
          <Link to={Navigate.publishersOptimizationPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faTools} className="me-1" /> Optimization
          </Link>
          <Link to={Navigate.publishersContentPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faGamepad} className="me-1" /> Diverse Content
          </Link>
          <Link to={Navigate.publishersAcquisitionPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faDollarSign} className="me-1" /> Acquisition Costs
          </Link>
          <Link to={Navigate.publishersPlayTesterPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faVial} className="me-1" /> Play Testers
          </Link>
          <Link to={Navigate.publishersToolsPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faGift} className="me-1" /> Free Tools
          </Link>
          <Link to={Navigate.publishersPricingPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faMoneyBill} className="me-1" /> Pricing
          </Link>
        </div>
      </div>

      <section className="py-5 text-white" style={{ backgroundColor: '#343a40' }}>
        <div className="container text-center">
          <h2 className="mb-4">Don't Miss Out on the Holiday Rush!</h2>
          <p className="lead">Join Glitch today and make this holiday season your most profitable yet.</p>
          <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-lg btn-danger"><FontAwesomeIcon icon={faGift} className="me-2" />Get Started Now</Link>
        </div>
      </section>

      <Footer />
    </>
  );
}

const bannerStyle = {
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/holiday.webp")',
  height: '600px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)',
  padding: '0 15px',
};

export default PublishersHolidayPage;
