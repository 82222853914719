import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet-async';

const PublisherTutorialCampaignsRateCardPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Understanding the Rate Card for Influencer Campaigns | Glitch Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to use the Rate Card in Glitch for influencer campaigns. This tutorial covers compensation methods, including flat fees, performance-based pay, and hybrid models."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="Understanding the Rate Card for Influencer Campaigns | Glitch Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial explains how to manage compensation for influencers using the Rate Card in Glitch. Learn about different compensation models and how to set up your campaign's Rate Card effectively."
                />
                
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Understanding the Rate Card for Influencer Campaigns | Glitch Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn how to use the Rate Card in Glitch for influencer campaigns. This tutorial covers compensation methods, including flat fees, performance-based pay, and hybrid models."
                />
                <meta name="twitter:image" content="https://yourwebsite.com/path-to-image.jpg" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Understanding the Rate Card for Influencer Campaigns</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/g758dHwkYrU"
                                title="Understanding the Rate Card for Influencer Campaigns"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Welcome to the Glitch Rate Card Tutorial</h3>
                        <p>
                            Welcome! In this tutorial, we’ll walk you through how the Rate Card works for influencer campaigns on Glitch. The Rate Card is a powerful tool that allows you to manage how influencers are compensated for promoting your game.
                        </p>

                        <h4>Accessing the Rate Card</h4>
                        <p>
                            Start by clicking on the Edit button for the campaign you’re working on. Then, navigate to the ‘Rate Card’ section to view and customize your campaign’s Rate Card.
                        </p>

                        <h4>Compensation Models</h4>
                        <p>
                            Influencers can be compensated in various ways for their time and effort. The three main types of compensation are:
                        </p>
                        <ul>
                            <li><strong>Flat Fee:</strong> A fixed amount paid either before the job starts or after it is completed.</li>
                            <li><strong>Performance-Based Compensation:</strong> Payment based on the results that the influencer's content produces, such as clicks, views, or engagement.</li>
                            <li><strong>Hybrid Model:</strong> A combination of both flat fees and performance-based compensation.</li>
                        </ul>

                        <h4>Performance-Based Compensation</h4>
                        <p>
                            In the Rate Card, you’ll find fields for metrics like Clicks, Views, Engagement, Shares, Comments, and Installs. These metrics allow you to compensate influencers based on specific actions, such as paying $0.001 per view or $0.10 per comment.
                        </p>
                        <p>
                            You can tailor these payments to align with your campaign goals. For example, if your focus is on community building, you might pay more for comments. For brand awareness, you might prioritize views and shares.
                        </p>

                        <h4>Platform-Specific Rate Cards</h4>
                        <p>
                            The Rate Card also allows you to set compensation rates for different platforms. The General Rate Card acts as the default, but you can override it by setting specific rates for individual platforms. This feature lets you guide influencers toward the platforms that matter most to your campaign.
                        </p>
                        <p>
                            Additionally, if you want to exclude a platform, simply disable it by clicking the corresponding ‘Disable’ button. Disabling a platform means influencers will not be rewarded for content created on that platform.
                        </p>

                        <h4>Flat Fee Compensation</h4>
                        <p>
                            The flat fee is a fixed payment made to influencers for their work on your campaign. In the Rate Card, you’ll find an option for a multiplier, which can dynamically calculate the flat fee based on the influencer’s follower count. For instance, if an influencer has 100,000 followers and you set the rate at $0.01 per follower, the influencer would earn $1,000 for that campaign.
                        </p>

                        <h4>Hybrid Compensation Model</h4>
                        <p>
                            The hybrid model combines flat fees with performance-based metrics. This approach is beneficial because it encourages influencers to leave their content up longer, increasing the chances of ongoing brand awareness and engagement.
                        </p>

                        <p>
                            That’s how you can effectively use the Rate Card on Glitch to manage influencer compensation. By strategically setting up your Rate Card, you can ensure your campaign is both fair to influencers and aligned with your marketing goals.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialCampaignsRateCardPage;
