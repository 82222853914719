import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet-async';

const PublisherTutorialCampaignsCreatePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>How to Create a Campaign on Glitch | Step-by-Step Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to create and manage a campaign on Glitch. This step-by-step tutorial covers everything from basic setup to targeting and influencer management."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Create a Campaign on Glitch | Step-by-Step Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial walks you through creating a campaign on Glitch, covering everything from setup to targeting and influencer management."
                />
                
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Create a Campaign on Glitch | Step-by-Step Tutorial" />
                <meta
                    name="twitter:description"
                    content="Follow this step-by-step guide to create and manage campaigns on Glitch, ensuring effective targeting and influencer engagement."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Create a Campaign on Glitch</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/o__I2UF7Dyw"
                                title="Creating a Campaign on Glitch"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            Welcome! In this tutorial, we’ll guide you through the process of creating a campaign on the Glitch platform. Campaigns are central to how you manage influencer marketing on Glitch, encompassing tools such as the database, invite system, and key statistics.
                        </p>

                        <h4>Getting Started with Your Campaign</h4>
                        <p>
                            To start a campaign, navigate to the 'Campaigns' section and click on the ‘Create’ button. You have the option to create a campaign manually or use AI to assist you. If your app is already in the Steam Store or published on Apple or Android, Glitch can automatically import relevant information.
                        </p>

                        <p>
                            Both manual and AI-assisted routes lead to the same form. However, with AI, much of the information will be pre-filled, saving you time.
                        </p>

                        <h4>Basic Information</h4>
                        <p>
                            The first step in campaign creation involves entering basic information: the campaign name, description, objectives, and selecting the associated business account.
                        </p>

                        <h4>Targeting Information</h4>
                        <p>
                            Next, you’ll input targeting information to identify your game’s audience. Start by describing the target audience in full sentences, followed by selecting the types of games they might enjoy—such as FPS, adventure, RPG, etc. You’ll also enter the countries where your influencers' followers are located, specify any gender targeting, and set the age range for your game.
                        </p>

                        <h4>Game Information</h4>
                        <p>
                            The next section focuses on your game. While the game name is the only mandatory field, providing a detailed description and other relevant information is crucial. This data helps AI match your campaign with the right influencers.
                        </p>

                        <p>
                            The most important field in this section is the media. Influencers are highly visual, so including engaging images and videos is essential to generating excitement for your campaign.
                        </p>

                        <h4>Campaign Budget</h4>
                        <p>
                            The campaign budget section is where you define your financial parameters. The key field here is the maximum budget per influencer. Setting a cap is crucial, especially when offering performance-based payments, as it ensures your budget remains manageable.
                        </p>

                        <h4>Campaign Dates</h4>
                        <p>
                            Setting dates for your campaign is optional but can be useful to limit the timeframe during which influencers can apply through the Glitch platform.
                        </p>

                        <h4>Influencer Management</h4>
                        <p>
                            The Influencers section is perhaps the most critical part of your campaign setup. Here, you’ll specify deliverables, assign hashtags, highlight key product features, list prohibited content, and outline the requirements for influencers to participate in the campaign.
                        </p>

                        <p>
                            The three most crucial fields are deliverables, days to deliver, and content usage rights. Deliverables specify what you expect from the influencer, such as streaming for 2 hours, creating a TikTok post, or writing an article. Days to deliver gives the influencer a clear timeframe, and content usage rights outline who owns the content and how it can be used.
                        </p>

                        <h4>Campaign Management Options</h4>
                        <p>
                            In the management section, you’ll decide how different aspects of the campaign will be managed. You can choose to manage the campaign yourself, delegate tasks to AI, or hire a third-party agency.
                        </p>

                        <p>
                            This includes managing influencer sourcing, application approval, and content review.
                        </p>

                        <h4>Rate Card</h4>
                        <p>
                            The final section covers the rate card, which defines how you’ll compensate influencers. You can offer a flat fee, performance-based payments, or a combination of both. Rate cards can be generalized across all platforms or customized per platform.
                        </p>

                        <p>
                            Congratulations! You’ve successfully created your campaign. Remember, you can always return to modify the campaign as needed.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialCampaignsCreatePage;
