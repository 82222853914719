import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Glitch from 'glitch-javascript-sdk';
import { HelmetProvider } from 'react-helmet-async';

// Configure Glitch SDK
Glitch.config.Config.setBaseUrl(process.env.REACT_APP_API_URL, true);
Glitch.config.Config.setRootDomain(process.env.REACT_APP_SITE_DOMAIN);
Glitch.config.Config.setAuthToken(Glitch.util.Storage.getAuthToken());

const rootElement = document.getElementById('root');

// Retrieve the SSR data from the global window object, if present
const serverAppData = window.__APP_DATA__ || null;
const serverRouteData = window.__ROUTE_DATA__ || {};

// Wrap the application with HelmetProvider
const AppWrapper = (
  <HelmetProvider>
    <BrowserRouter>
      <App data={serverAppData} routeData={serverRouteData} />
    </BrowserRouter>
  </HelmetProvider>
);

if (process.env.NODE_ENV === "development") {

  const root = ReactDOM.createRoot(rootElement);
  root.render(
    AppWrapper
  );
  
} else {
  // Hydrate the app with React 18
  ReactDOM.hydrateRoot(rootElement, AppWrapper);
}

reportWebVitals();
