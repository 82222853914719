/**
 * The routes to various pages in the React application
 */

 const app_routes = {
   
    //misc
    home : '/home',
    privacy : '/privacy',
    terms : '/terms',
    contact : '/contact',
    dataremoval : '/dataremoval',
    gdpr : '/gdpr',
    access_denied : '/accessdenied',
    revenue : '/revenue',
    marketing : '/marketing',
    features : '/features',
    installation: '/installation',
    benefits: '/benefits',
    about: '/about',
    crawl: '/crawl',

    //Campaigns
    campaigns: '/campaigns',
    campaigns_create : '/campaigns/create',
    campaigns_view : '/campaigns/:id/view',
    campaigns_update : '/campaigns/:id/update',
    campaigns_delete : '/campaigns/:id/delete',
    campaigns_start : '/campaigns/:id/start',

    campaigns_update_media : '/campaigns/:id/updatemedia',
    campaigns_update_links : '/campaigns/:id/updatelinks',

    campaigns_register : '/campaigns/:id/register',
    campaigns_influencers : '/campaigns/:id/influencers',
    campaigns_find_influencer : '/campaigns/:id/findinfluencer',
    campaigns_invite_influencer : '/campaigns/:id/inviteinfluencer',
    campaigns_recommended_influencers : '/campaigns/:id/recommendedinfluencers',
    campaigns_content : '/campaigns/:id/content',
    campaigns_manage_influencer : '/campaigns/:campaign_id/campaign/:user_id',
    campaigns_research_influencer : '/campaigns/:id/research/:user_id',
    campaigns_performance_influencer : '/campaigns/:id/performance/:user_id',
    campaigns_contracts_influencer : '/campaigns/:id/contracts/:user_id',
    campaigns_payment_influencer : '/campaigns/:id/payment/:user_id',
    campaigns_message_influencer : '/campaigns/:id/message/:user_id',
    campaigns_view_influencer : '/campaigns/:id/viewinfluencer/:influencer_id',
    campaigns_update_influencer : '/campaigns/:id/updateinfluencer/:influencer_id',
    campaigns_update_influencer_invite_compensation : '/campaigns/:id/updateinfluencerInvite/:influencer_id/compenstation',
    campaigns_update_influencer_invite_contract : '/campaigns/:id/updateinfluencerInvite/:influencer_id/contract',
    campaigns_start : '/campaigns/:id/start',
    campaigns_invites : '/campaigns/:id/invites',
    campaigns_influencer_invite : '/campaigns/:id/invites/:influencer_id',
    campaigns_ledger : '/campaigns/:id/ledger',
    campaigns_statistics : '/campaigns/:id/statistics',

    creators: '/creators',
    creators_overview: '/creators/overview',
    creators_micro: '/creators/micro',
    creators_black: '/creators/black',
    creator_influencers: '/creators/influencers',
    creator_influencers_mac: '/creators/macinfluencers',
    creator_influencers_linux: '/creators/linuxinfluencers',
    creator_publishers: '/creators/publishers',
    creator_publishers_mac: '/creators/macpublishers',
    creator_publishers_linux: '/creators/linuxpublishers',
    creator_calculators : '/creators/calculator',
    creator_editing : '/creators/editing',
    creator_features : '/creators/features',
    creator_getting_started : '/creators/gettingstarted',
    creators_multistreaming : '/creators/multistreaming',
    creators_optimization : '/creators/optimization',
    creators_tiktokstream : '/creators/tiktokstream',
    creators_playtesting :'/creators/playtesting',

    creator_onboarding_step_1 : '/creators/onboarding/step1',
    creator_onboarding_step_2 : '/creators/onboarding/step2',
    creator_onboarding_step_3 : '/creators/onboarding/step3',
    creator_onboarding_step_4 : '/creators/onboarding/step4',
    creator_onboarding_step_5 : '/creators/onboarding/step5',


    //authentication
    auth_login : '/login',
    auth_register : '/register',
    auth_forgot_password : '/auth/forgotpassword',
    auth_reset_password : '/auth/resetpassword',
    auth_facebook : '/auth/facebook',
    auth_youtube : '/auth/youtube',
    auth_twitch : '/auth/twitch',
    auth_stripe : '/auth/stripe',
    auth_google : '/auth/google',
    auth_microsoft : '/auth/microsoft',
    auth_microsoft_teams : '/auth/teams',
    auth_tiktok : '/auth/tiktok',
    auth_twitter : '/auth/twitter',
    auth_reddit : '/auth/reddit',
    auth_instagram : '/auth/instagram',
    auth_docusign : '/auth/docusign',
    auth_simplesign : '/auth/simplesign',
    
    auth_facebook_complete : '/auth/facebook/complete',
    auth_youtube_complete : '/auth/youtube/complete',
    auth_twitch_complete : '/auth/twitch/complete',
    auth_stripe_complete : '/auth/stripe/complete',
    auth_google_complete : '/auth/google/complete',
    auth_microsoft_complete : '/auth/microsoft/complete',
    auth_microsoft_teams_complete : '/auth/teams/complete',
    auth_tiktok_complete : '/auth/tiktok/complete',
    auth_twitter_complete : '/auth/tiktok/complete',
    auth_reddit_complete : '/auth/reddit/complete',
    auth_instagram_complete : '/auth/instagram/complete',
    auth_docusign_complete : '/auth/reddit/complete',
    auth_simplesign_complete : '/auth/reddit/complete',

    //accounts route
    account_update : '/accounts/update',
    acount_register_page_2 : '/accounts/register/step2',
    account_verify : '/accounts/:id/verify',
    join : '/join',

    //Communities
    communities : '/communities',
    communities_admin : '/communities/admin',
    communities_create : '/communities/create',
    
    communities_view : '/communities/:id/view',
    communities_delete : '/communities/:id/delete',

    communities_overview : '/communities/:id/overview',
    communities_manage : '/communities/:id/manage',
    communities_update : '/communities/:id/update',
    communities_features : '/communities/:id/features',
    communities_accessibility : '/communities/:id/accessibility',
    communities_newsletter : '/communities/:id/newsletter',
    communities_cname : '/communities/:id/cname',
    communities_social : '/communities/:id/social',
    communities_media : '/communities/:id/media',
    communities_css : '/communities/:id/css',
    communities_users : '/communities/:id/users',
    communities_user : '/communities/:id/user/:user_id',
    communities_invite : '/communities/:id/invite',
    communities_contracts : '/communities/:id/contracts',
    communities_email_templates : '/communities/:id/emailtemplates',
    communities_getting_started : '/communities/:id/gettingstarted',

    communities_invited_login : '/invites/login', 
    communities_invited_register : '/invites/register', 

    communities_subscribe : '/communities/:id/subscribe',
    communities_subscriptions : '/communities/:id/subscriptions',
    communities_cards : '/communities/:id/cards',

    //influencers
    influencer_campaigns : '/influencers/campaigns',
    influencer_my_campaigns : '/influencers/campaigns/mine',
    influencer_find_campaigns : '/influencers/findcampaigns', 
    influencer_view_campaign : '/influencers/:campaign_id/campaign',
    influencer_manage_campaign : '/influencers/:campaign_id/campaign/:user_id',  
    influencer_campaign_invite : '/influencers/:campaign_id/invite/:influencer_id', 
    influencer_campaign_payouts : '/influencers/:campaign_id/payouts',  
    influencer_campaign_begin : '/influencers/:campaign_id/begin',
    influencer_campaign_apply : '/influencers/:campaign_id/apply',  
    influencer_campaign_posts_create : '/influencers/:campaign_id/createpost',   
    influencer_campaign_posts_list : '/influencers/:campaign_id/posts',    
    influencer_campaign_posts_update : '/influencers/:campaign_id/posts/:post_id/update',
    influencer_campaign_posts_view : '/influencers/:campaign_id/posts/:post_id/view',    
    

    
    influencer_messages : '/influencers/messages',
    influencer_message_thread : '/influencers/messages/thread/:id',
    influencer_message_create : '/influencers/messages/create',
    influencer_message_read : '/influencers/messages/read/:id ',

    influencer_download_app : '/influencers/downloadapp',
    influencer_getting_started : '/influencers/gettingstarted',

    influencer_tutorials : '/influencers/tutorials',
    influencer_tutorials_invites : '/influencers/tutorials/invites',
    influencer_tutorials_games : '/influencers/tutorials/games',
    influencer_tutorials_starting : '/influencers/tutorials/starting',

    //Tips
    tips_emojis : '/tips/:id/emojis', 
    tips_watch_emojis : '/tips/:id/watch', 

    //Posts
    posts : '/posts',
    posts_create : '/posts/create',
    posts_view : '/posts/:id/view',
    posts_delete : '/posts/:id/delete',
    posts_update : '/communities/:id/update',
    

    publishers : '/publishers',
    publishers_organizations : '/publishers/organizations',
    publishers_pilots : '/publishers/pilots',
    publishers_gamifications : '/publishers/gamification',
    publishers_gamifications_2 : '/p/gamification',
    publishers_optimization : '/publishers/optimization',
    publishers_optimization_2 : '/p/optimization',
    publishers_benefits : '/publishers/benefits',
    publishers_benefits_2 : '/p/benefits',
    publishers_acquisition : '/publishers/acquisition',
    publishers_acquisition_2 : '/p/acquisition',
    publishers_register : '/publishers/register',
    publishers_pricing : '/publishers/pricing',
    publishers_database : '/publishers/database',
    publishers_database_2 : '/p/database',
    publishers_ai : '/publishers/ai',
    publishers_ai_2 : '/p/ai',
    publishers_crm : '/publishers/crm',
    publishers_crm_2 : '/p/crm',
    publishers_content : '/publishers/content',
    publishers_holidays : '/publishers/holidays',
    publishers_playtesters : '/publishers/playtesters',
    publishers_newsletter : '/publishers/newsletter',
    publishers_showcases : '/publishers/showcases',
    publishers_mediakit : '/publishers/mediakit',
    publishers_devlogs : '/publishers/devlogs',
    publishers_social : '/publishers/social',
    publishers_community : '/publishers/community',
    publishers_press : '/publishers/presskit',
    publishers_overview : '/publishers/overview',

    publishers_tools : '/publishers/tools/home',
    publishers_tools_pricing : '/publishers/tools/pricing',
    publishers_tools_media : '/publishers/tools/mediakit',
    publishers_tools_pr : '/publishers/tools/pr',
    publishers_tools_database : '/publishers/tools/database',
    publishers_tools_influencers : '/publishers/tools/influencers',
    publishers_tools_wiki : '/publishers/tools/wiki',
    publishers_tools_showcases : '/publishers/tools/showcases',
    publishers_tools_forums : '/publishers/tools/forums',
    publishers_tools_guide : '/publishers/tools/guide',
    publishers_tools_email : '/publishers/tools/email',
    publishers_dashboard : '/publishers/dashboard',

    publishers_onboarding_step_1 : '/publishers/onboarding/step1',
    publishers_onboarding_step_2 : '/publishers/onboarding/step2',
    publishers_onboarding_step_3 : '/publishers/onboarding/step3',
    publishers_onboarding_step_4 : '/publishers/onboarding/step4',

    publishers_messages : '/publishers/messages',
    publishers_message_thread : '/publishers/messages/thread/:id',
    publishers_message_create : '/publishers/messages/create',
    publishers_message_read : '/publishers/messages/read/:id ',

    publishers_gameshows_mine : '/showcases/publishers/mine',
    publishers_gameshows_create : '/showcases/publishers/create',
    publishers_gameshows_update : '/showcases/publishers/:id/update',
    publishers_gameshows_view : '/showcases/publishers/:id/view',
    publishers_gameshows_delete : '/showcases/publishers/:id/delete',

    publishers_gameshows_register : '/showcases/publishers/:id/register',
    publishers_gameshows_games : '/showcases/publishers/:id/games',
    publishers_gameshows_users : '/showcases/publishers/:id/users',
    publishers_gameshows_viewgame : '/showcases/publishers/:id/viewgame',
    publishers_gameshows_viewuser : '/showcases/publishers/:id/viewuser',
    publishers_gameshows_updategame : '/showcases/publishers/:id/updategames',
    publishers_gameshows_promote : '/showcases/publishers/:id/promote',

    publishers_community_gameshows_view_standalone : '/gameshows/:id/view/standalone',
    publishers_community_gameshows_view_community : '/gameshows/:id/view/community',
    publishers_community_gameshows_register_developer_standalone : '/gameshows/:id/register/developer/standalone',
    publishers_community_gameshows_register_developer_community : '/gameshows/:id/register/developer/community',
    publishers_community_gameshows_register_user_standalone : '/gameshows/:id/register/developer/standalone',
    publishers_community_gameshows_register_user_community : '/gameshows/:id/register/developer/form',


    publishers_tutorials : '/publishers/tutorials',
    publishers_tutorials_campaigns_overview : '/publishers/tutorials/campaignsoverview',
    publishers_tutorials_campaigns_create : '/publishers/tutorials/campaignscreate',
    publishers_tutorials_campaigns_checklist : '/publishers/tutorials/campaignschecklist',
    publishers_tutorials_campaigns_ratecard : '/publishers/tutorials/campaignsratecard',


    publishers_tutorials_invites_pipelines : '/publishers/tutorials/invitepipelines',
    publishers_tutorials_invites_notes : '/publishers/tutorials/invitenotes',
    publishers_tutorials_invites_invites : '/publishers/tutorials/invites',
    publishers_tutorials_invites_custom_invites : '/publishers/tutorials/custominvites',
    publishers_tutorials_invites_recommended : '/publishers/tutorials/recommends',
    publishers_tutorials_invites_custom_negotiations : '/publishers/tutorials/negotiations',
    publishers_tutorials_invites_database : '/publishers/tutorials/database',

    publishers_tutorials_schedulers_create : '/publishers/tutorials/schedulerscreate',

    mediakits_publisher_list: '/mediakits',
    mediakits_publisher_create: '/mediakits/create',
    mediakits_publisher_update: '/mediakits/:id/update',
    mediakits_publisher_view: '/mediakits/:id/view',
    mediakits_publisher_delete: '/mediakits/:id/delete',
    mediakits_publisher_share: '/mediakits/:id/promote',
    

    mediakits_community_standalone: '/mediakits/:id/standalone',
    mediakits_community_community: '/mediakits/:id/community',

    //Publisher Routes
    playtesting_publisher_list: '/playtesting',
    playtesting_publisher_create: '/playtesting/create',
    playtesting_publisher_update: '/playtesting/:id/update',
    playtesting_publisher_view: '/playtesting/:id/view',
    playtesting_publisher_invite: '/playtesting/:id/invite',
    playtesting_publisher_viewtest: '/playtesting/:id/details/:playtest_id',
    playtesting_publisher_questions: '/playtesting/:id/questions/:playtest_id',
    playtesting_publisher_review: '/playtesting/:id/review/:playtest_id',
    playtesting_publisher_messages: '/playtesting/:id/messages/:playtest_id',
    playtesting_publisher_testers: '/playtesting/:id/testers',
    playtesting_publisher_delete: '/playtesting/:id/delete',
    playtesting_publisher_share: '/playtesting/:id/promote',
    playtesting_publisher_preview: '/playtesting/:id/preview',

    //Tester Routers
    playtesting_user_games: '/playtests/games',
    playtesting_user_tests: '/playtests/tests',
    playtesting_user_game: '/playtests/:id/game',
    playtesting_user_info: '/playtests/:id/test/:playtest_id',
    playtesting_user_answers: '/playtests/:id/answers/:playtest_id',

    //reports
    reports_main_page: '/reports',
    reports_list_titles_page: '/reports/titles',
    reports_list_communities_page: '/reports/communities',
    reports_view_title_page: '/reports/:id/title',
    reports_upload_title_page: '/reports/:id/title/upload',
    reports_view_community_page: '/reports/:id/community',
    reports_code_title_page: '/reports/:id/title/code',
    reports_tokens_title_page: '/reports/:id/title/token',
    reports_oauth_title_page: '/reports/:id/title/oauth',

    //schedulers
    schedulers_list : '/schedulers',
    schedulers_create : '/schedulers/create',
    schedulers_update : '/schedulers/:id/update',
    schedulers_view : '/schedulers/:id/view',
    schedulers_posts : '/schedulers/:id/posts',
    schedulers_socials : '/schedulers/:id/socials',
    schedulers_users : '/schedulers/:id/users',
    schedulers_invite : '/schedulers/:id/invite',
    schedulers_tone : '/schedulers/:id/tone',
    schedulers_reports : '/schedulers/:id/reports',
    schedulers_branding : '/schedulers/:id/branding',
    schedulers_goals : '/schedulers/:id/goals',

    schedulers_update_list : '/schedulers/:id/updates/list',
    schedulers_update_create : '/schedulers/:id/update/create',
    schedulers_update_view : '/schedulers/:id/update/:update_id/view',
    schedulers_update_update : '/schedulers/:id/update/:update_id/update',
    schedulers_posts_update : '/schedulers/:id/posts/:post_id/update',
    schedulers_posts_view : '/schedulers/:id/posts/:post_id/view',



    //streams route
    streams : '/streams',
    streams_create : '/streams/create',
    streams_update : '/streams/:id/update',
    streams_watch : '/streams/:id/watch',
    streams_broadcast : '/streams/:id/broadcast',
    streams_delete : '/streams/:id/delete',
    streams_cohost_password : '/streams/:id/cohostpassword?passcode=:passcode',
    streams_cohost_watch : '/streams/:id/cohostwatch',
    streams_recording_watch : '/streams/:id/watchrecording/:subid',
    streams_recording_update : '/streams/:id/updaterecording/:subid',

    //messages route
    messages : '/messages',
    message_thread : '/messages/thread/:id',
    message_create : '/messages/create',
    message_read : '/messages/read/:id ',

    //Teams
    teams : '/teams',
    teams_create : '/teams/create',
    teams_view : '/teams/:id/view',
    teams_update : '/teams/:id/update',
    teams_delete : '/teams/:id/delete',
    teams_register : '/teams/:id/register',
    teams_invite : '/teams/:id/register',


    //Tournaments
    tournaments : '/tournmanets',
    tournaments_create : '/tournaments/create',
    tournaments_view : '/tournmanets/:id/view',
    tournaments_update : '/tournmanets/:id/update',
    tournaments_update_waivers : '/tournmanets/:id/waivers',
    tournaments_delete : '/tournmanets/:id/delete',
    tournaments_register_user : '/tournmanets/:id/registerUser',
    tournaments_register_team : '/tournmanets/:id/registerTeam',
    tournaments_teams : '/tournmanets/:id/register',
    tournaments_participants : '/tournmanets/:id/register',
    tournaments_manage : '/tournmanets/:id/manage',
    tournaments_invite : '/tournmanets/:id/invite',
    tournaments_update_media : '/tournmanets/:id/media',

    tournaments_venues : '/tournmanets/:id/venues',
    tournaments_venues_create : '/tournaments/:id/venues/create',
    tournaments_venues_view : '/tournmanets/:id/venues/:venue_id/view',
    tournaments_venues_update : '/tournmanets/:id/venues/:venue_id/update',
    tournaments_venues_delete : '/tournmanets/:id/venues/:venue_id/delete',

    tournaments_users : '/tournmanets/:id/users',
    tournaments_users_create : '/tournaments/:id/users/create',
    tournaments_users_view : '/tournmanets/:id/users/:user_id/view',
    tournaments_users_update : '/tournmanets/:id/users/:user_id/update',
    tournaments_users_delete : '/tournmanets/:id/users/:user_id/delete',

    tournaments_teams : '/tournmanets/:id/teams',
    tournaments_teams_create : '/tournaments/:id/teams/create',
    tournaments_teams_view : '/tournmanets/:id/teams/:team_id/view',
    tournaments_teams_update : '/tournmanets/:id/teams/:team_id/update',
    tournaments_teams_delete : '/tournmanets/:id/teams/:team_id/delete',

    tournaments_participants : '/tournmanets/:id/users',
    tournaments_participants_create : '/tournaments/:id/participants/create',
    tournaments_participants_view : '/tournmanets/:id/participants/:user_id/view',
    tournaments_participants_update : '/tournmanets/:id/participants/:user_id/update',
    tournaments_participants_delete : '/tournmanets/:id/participants/:user_id/delete',

    tournaments_rounds : '/tournmanets/:id/rounds',
    tournaments_rounds_create : '/tournaments/:id/rounds/create',
    tournaments_rounds_view : '/tournmanets/:id/rounds/:round_id/view',
    tournaments_rounds_update : '/tournmanets/:id/rounds/:round_id/update',
    tournaments_rounds_delete : '/tournmanets/:id/rounds/:round_id/delete',

    tournaments_brackets : '/tournmanets/:id/rounds/:round_id/brackets',
    tournaments_brackets_create : '/tournaments/:id/rounds/:round_id/brackets/create',
    tournaments_brackets_view : '/tournmanets/:id/rounds/:round_id/brackets/:bracket_id/view',
    tournaments_brackets_update : '/tournmanets/:id/rounds/:round_id/brackets/:bracket_id/update',
    tournaments_brackets_delete : '/tournmanets/:id/rounds/:round_id/brackets/:bracket_id/delete',

    tournaments_types : '/tournmanets/types',

    newsletters_list : '/newsletters',
    newsletters_create : '/newsletters/create',
    newsletters_update : '/newsletters/:id/update/communities/:community_id',
    newsletters_view : '/newsletters/:id/view/:community_id',
    newsletters_subscribe : '/newsletters/:id/subscribe/:community_id',
    newsletters_subscribers : '/newsletters/:id/subscribers/:community_id',
    newsletters_import : '/newsletters/:id/import/:community_id',
    newsletters_reports : '/newsletters/:id/reports/:community_id',


    newsletters_campaigns_list : '/newsletters/:id/communities/:community_id/campaigns/:newsletter_id',
    newsletters_campaigns_create : '/newsletters/:id/communities/:community_id/campaigns/create',
    newsletters_campaigns_update : '/newsletters/:id/communities/:community_id/campaigns/:campaign_id/update',
    newsletters_campaigns_view : '/newsletters/:id/communities/:community_id/campaigns/:campaign_id/view',
    newsletters_campaigns_send : '/newsletters/:id/communities/:community_id/campaigns/:campaign_id/send',

    newsletters_community_subscribe_standalone : '/newsletters/:id/standalone',
    newsletters_community_subscribe_form : '/newsletters/:id/form',


    //users routes
    users_list : '/users',
    users_profile : '/users/:id/profile',
    users_followers: '/users/followers',
    users_following : '/users/following',
    users_invites : '/users/invites',
    users_payouts : '/users/payouts'
}

export default app_routes;