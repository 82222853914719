import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMoneyBillWave, faBroadcastTower, faCheckCircle, faUserEdit, faEnvelopeOpenText, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import SignUpCreators from "../../component/section/signup_creators";
import CreatorLinks from "../../component/section/creators/creator_links";
import Footer from "../../component/layout/footer";
import Navigate from "../../../../util/Navigate";
import { Link } from "react-router-dom";

class CreatorsPlayTestersPage extends Component {

    render() {
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <>
                <Helmet prioritizeSeoTags>
                    <meta charSet="utf-8" />
                    <title>Earn Money Playing Games | Glitch Gaming</title>
                    <meta name="description" content="Join Glitch's creator program to get paid for playing games and giving feedback. Become a play tester today!" />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Earn Money Playing Games | Glitch" />
                    <meta property="og:description" content="Join Glitch's creator program to get paid for playing games and providing feedback. Become a play tester today!" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:image" content="https://www.glitch.fun/assets/images/creators/creators_feedback.webpf" />
                </Helmet>

                <Fragment>
                    <Header />
                    <section className="pageheader-section text-center text-uppercase" style={{ backgroundImage: "url(/assets/images/pageheader/bg.jpg)", padding: "100px 0" }}>
                        <div className="container">
                            <div className="section-wrapper">
                                <br /><br /><br /><br />
                                <h2 className="pageheader-title mt-5">Free Access to the Newest Games</h2>
                                <p className="lead">Become a play tester and experience new games first!</p>
                                <form className="contact-form mt-4" id="contact-form" method="POST">
                            <div className="form-group w-100 text-center">
                                <Link className="default-button reverse-effect small" to={Navigate.creatorsOnboardingStep1Page()}><span>Register To Play</span></Link>
                            </div>
                        </form>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/guide/game_bug.gif" alt="One Click Stream Setup" className="img-fluid rounded shadow mb-2" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">Gaming Developers Need Your Help</h2>
                                    <p className="lead">All games require thorough testing before going live. Developers seek individuals who are genuinely interested in playing their games and providing authentic feedback—not those solely in it for a paycheck. This is where you come in!</p>
                                    <form className="contact-form mt-4" id="contact-form" method="POST">
                                        <div className="form-group w-100 text-center">
                                        <Link className="default-button" to={Navigate.creatorsOnboardingStep1Page()}><span>Register Now</span></Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="container my-5 text-center">
                        <h2 className='mb-3'>In Exchange For Testing<br />You Get First Access To New Games</h2>
                        <div className="ratio ratio-16x9 border border-white border-2">
                            <video src="/assets/videos/creators/first_place.mp4" autoPlay loop muted playsInline></video>

                        </div>
                    </div>


                    <section className="how-it-works-section py-5 bg-light text-black">
                        <div className="container">
                            <h2 className="text-center text-black mb-4">How It Works</h2>
                            <div className="row g-4">
                                {[
                                    { icon: faGamepad, title: 'Register As A Tester', text: 'During registration, just indicate that you’d like to be a play tester.', img: 'register_play_tester.jpg' },
                                    { icon: faUserEdit, title: 'Complete Your Profile', text: 'Tell us what games you enjoy and share your gaming experiences.', img: 'tester_profile.jpg' },
                                    { icon: faEnvelopeOpenText, title: 'Receive Invites', text: 'Games will contact you; simply say yes or no to work with them.', img: 'promote_1.webp' },
                                    { icon: faThumbsUp, title: 'Provide Feedback', text: 'Record your gameplay and provide feedback to developers.', img: 'feedback.webp' }
                                ].map((step, index) => (
                                    <div className="col-md-6 col-lg-3" key={index}>
                                        <div className="card text-center h-100">
                                            <div className="card-body">
                                                <FontAwesomeIcon icon={step.icon} size="3x" className="text-primary mt-4 mb-4" />
                                                <h5 className="card-title">{step.title}</h5>
                                                <p className="card-text">{step.text}</p>
                                                <img src={`/assets/images/creators/${step.img}`} alt={step.title} className="img-fluid rounded" style={{ width: "100%", height: "200px", objectFit: "cover" }} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <form className="contact-form mt-4" id="contact-form" method="POST">
                            <div className="form-group w-100 text-center">
                                <Link className="default-button reverse-effect" to={Navigate.creatorsOnboardingStep1Page()}><span>Sign Up Now</span></Link>
                            </div>
                        </form>
                    </section>

                    <section className="py-5 bg-dark text-white">
                        <div className="container">
                            <div className="section-wrapper">
                                <div className="row g-4 justify-content-center">
                                    <div className="col-12">
                                        <div className="blog-item d-flex flex-wrap align-items-center">
                                            <div className="blog-thumb w-xl-50 w-100">
                                                <img src="/assets/images/creators/interracial-gaming.jpg" alt="Earnings Based On Engagement" className="w-100 rounded" />
                                            </div>
                                            <div className="blog-content p-4 w-xl-50 w-100">
                                                <h3>Will You Be Paid?</h3>
                                                <p className="lead">Unlike our influencer marketing services, we do not officially support paid playtesting. However, you’re free to negotiate directly with developers. To get paid as a creator, sign up for our influencer account.</p>
                                                <Link className="default-button reverse-effect" to={Navigate.creatorsInfluencersPage()}><span>Influencers Program</span></Link>

                                     
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="section-wrapper">
                                <div className="row g-4 justify-content-center">
                                    <div className="col-12">
                                        <div className="blog-item-1 d-flex flex-wrap align-items-center">
                                            <div className="blog-content p-4 w-xl-50 w-100">
                                                <h3 className="text-black">Have Fun And Be Authentic</h3>
                                                <p className="lead">Playtesting is about having fun and helping developers identify areas for improvement. Don’t hold back with your feedback—just be your authentic self!</p>
                                              
                                            </div>
                                            <div className="blog-thumb w-xl-50 w-100">
                                                <div className="ratio ratio-16x9 border border-white border-2">
                                                    <video src="/assets/videos/creators/vote_down.mp4" autoPlay loop muted playsInline></video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <CreatorLinks />

                    <SignUpCreators />

                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default CreatorsPlayTestersPage;
