import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import PageHeader from '../../component/layout/pageheader';
import CommunityFormBasic from '../../component/section/communities/form_community_basic';
import Loading from '../../component/alerts/Loading';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Navigate from '../../../../util/Navigate';
import withRouter from '../../../../util/withRouter';
import PublisherHeader from '../../component/layout/publisherheader';
import { Link, useNavigate } from 'react-router-dom';

const PublisherOnboardinStep3Page = (props) => {
    const [data, setData] = useState({});
    const [templates, setTemplates] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let token = params.loginToken;

        if (token) {
            Glitch.api.Auth.oneTimeLogin(token).then(response => {
                Glitch.util.Storage.setAuthToken(response.data.data.token.access_token);
                Glitch.util.Storage.set('user_id', response.data.data.id);

                window.location.reload();
                
            }).catch(error => {
                console.error(error); // Handle any errors
            });
        }

    }, []);

    const handleNameChange = (e) => {
        const name = e.target.value;
        const subdomain = name.toLowerCase().replace(/[^a-z0-9\s-]/g, '').replace(/\s+/g, '-');
        setData({ ...data, name, subdomain });
    };

    return (
        <Fragment>
            <div className="padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Step 3 of 4 <hr /><br />Discuss Marketing <br />Best Practices In Our Discord</h3>
                        
                        <form className="text-center" style={{ textAlign: "left" }} >
                            <p className='lead text-center'>This step is <strong>NOT required</strong>, but it is highly recommended to join our Discord server for free marketing help, advice, and support.</p>
                           
                           
                           <div className='mb-2'>
                                <a href="https://discord.gg/RPYU9KgEmU" target='_blank' ><img src="/assets/images/publishers/discord_1.jpg" className='img-fluid rounded mb-1' /></a>
                                 <a href="https://discord.gg/RPYU9KgEmU" target='_blank' className='btn btn-primary'>Join Disord</a>
                           </div>

                           <hr />

                            <div className="form-group text-center">
                                <Link to={Navigate.publishersOnboardingStep4Page()} className="d-block default-button"><span> Next Step</span></Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default PublisherOnboardinStep3Page;
