import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet-async';

const PublisherTutorialInvitesPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>How to Send Invites to Influencers and Manage the Process | Glitch Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to send invites to influencers and effectively manage the invite process on Glitch. This tutorial covers everything from outreach to tracking responses."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Send Invites to Influencers and Manage the Process | Glitch Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial explains how to send invites to influencers and manage follow-ups, negotiations, and contracts on Glitch."
                />
                
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Send Invites to Influencers and Manage the Process | Glitch Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn how to send invites to influencers and effectively manage the invite process on Glitch. This tutorial covers everything from outreach to tracking responses."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Send Invites to Influencers and Manage the Process</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/iJj62z1xYHk"
                                title="How to Send Invites to Influencers"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            In this tutorial, we’ll cover the process of sending invites to influencers and managing the entire invite process effectively. Influencer marketing is a numbers game, and success often requires reaching out to many influencers to find the best match for your campaign.
                        </p>

                        <h4>Understanding the Invite Process</h4>
                        <p>
                            Influencer outreach is similar to a sales process—you'll need to contact many influencers to find the ones that fit your game. It’s not uncommon for influencers to ignore the first message, so follow-up is crucial. Our platform is designed to manage this process efficiently, sending up to 5 emails to encourage a response. On average, influencers respond by the 3rd email.
                        </p>

                        <h4>Sending Invites</h4>
                        <p>
                            Once you’ve created a campaign, you can find potential influencers by clicking either the ‘Find’ button or the ‘Recommended’ button. Both options will guide you to a list of influencers where you can click the ‘Invite’ button to send them an invitation.
                        </p>

                        <h4>Managing Invites</h4>
                        <p>
                            After sending invites, you can track their status by navigating to the Invites tab. Here, you’ll see a list of influencers you’ve invited, with details on the 5 possible follow-up emails that can be sent. These emails are automatically generated but can be customized before sending.
                        </p>

                        <h4>Tracking Responses</h4>
                        <p>
                            When an influencer responds, you’ll receive an email with their reply. At the bottom of this email, you’ll find a link to the original invite details, which will take you to the influencer's profile. This allows you to review their information before crafting a response. It’s important to respond promptly to keep the conversation going.
                        </p>

                        <h4>Additional Features</h4>
                        <p>
                            Within each invite, there are additional tools to help you manage the process. You can negotiate custom rates and deliverables, leave notes, manage contracts, and track the influencer through various stages of the collaboration. These advanced features help ensure a smooth and successful influencer marketing campaign.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialInvitesPage;
