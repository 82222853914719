import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';

const GallerySection = ({ mediaFiles }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const videoRef = useRef(null);
    const currentMedia = mediaFiles[currentIndex];

    useEffect(() => {
        let timer;

        const goToNext = () => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaFiles.length);
        };

        if (currentMedia.mime_type.startsWith('image/')) {
            // Auto-rotate images after 5 seconds
            timer = setTimeout(goToNext, 5000);
        } else if (currentMedia.mime_type.startsWith('video/')) {
            if (videoRef.current) {
                videoRef.current.currentTime = 1; // Set preview to 1 second into the video
                videoRef.current.play().catch(() => {
                    // If autoplay fails, fallback to switching after 10 seconds
                    timer = setTimeout(goToNext, 10000);
                });
                videoRef.current.onended = goToNext;
            }
        }

        return () => {
            if (timer) clearTimeout(timer);
            if (videoRef.current) {
                videoRef.current.onended = null;
            }
        };
    }, [currentIndex, mediaFiles]);

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

    const handleThumbnailVideoLoaded = (videoElement) => {
        if (videoElement) {
            videoElement.currentTime = 1; // Set thumbnail preview frame to 1 second
        }
    };

    return (
        <div>
            <div className="gallery-container d-flex flex-column align-items-center">
                <div className="main-media mb-3 w-100">
                    {currentMedia.mime_type.startsWith('image/') ? (
                        <img
                            src={currentMedia.url}
                            className="img-fluid rounded shadow-lg"
                            alt={currentMedia.title || `Media ${currentIndex + 1}`}
                            style={{ maxHeight: '500px', objectFit: 'contain' }}
                        />
                    ) : currentMedia.mime_type.startsWith('video/') ? (
                        <video
                            key={currentMedia.url} // Force re-rendering when the media changes
                            ref={videoRef}
                            controls
                            muted
                            className="w-100 rounded shadow-lg"
                            style={{ maxHeight: '500px' }}
                        >
                            <source src={currentMedia.url} type={currentMedia.mime_type} />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
                </div>
                <div className="thumbnails d-flex flex-wrap justify-content-center">
                    {mediaFiles.map((mediaItem, index) => (
                        <div
                            key={index}
                            className={`thumbnail-item mx-2 mb-2 ${
                                index === currentIndex ? 'border border-primary' : ''
                            }`}
                            onClick={() => handleThumbnailClick(index)}
                            style={{ cursor: 'pointer' }}
                        >
                            {mediaItem.mime_type.startsWith('image/') ? (
                                <img
                                    src={mediaItem.url}
                                    className="rounded"
                                    alt={mediaItem.title || `Thumbnail ${index + 1}`}
                                    style={{ width: '100px', height: '75px', objectFit: 'cover' }}
                                />
                            ) : mediaItem.mime_type.startsWith('video/') ? (
                                <video
                                    className="rounded"
                                    style={{ width: '100px', height: '75px', objectFit: 'cover' }}
                                    muted
                                    playsInline
                                    onLoadedMetadata={(e) => handleThumbnailVideoLoaded(e.target)}
                                >
                                    <source src={mediaItem.url} type={mediaItem.mime_type} />
                                </video>
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GallerySection;
