import { Component, Fragment } from "react";
import React from 'react';
import Header from '../../component/layout/header'
import PageHeader from '../../component/layout/pageheader'
import AboutSection from '../../component/section/about'
import VideoTestimonial from "../../component/section/videotestimonial";
import SponsorSection from "../../component/section/sponsor";
import ProductSection from "../../component/section/product";
import CtaSection from "../../component/section/cta";
import HrShape from "../../component/layout/hrshape";
import Footer from "../../component/layout/footer";


class TermsPage extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Terms Of Service'} curPage={'About Us'} />
                <div className="container mt-5">
                    <h1 className="text-center mb-4">TERMS OF SERVICE</h1>
                    <p>
                        By visiting this website and accessing the information, services, and products provided at Glitch, you understand and agree to accept and adhere to these Terms of Use for content creators ("Terms of Use").
                    </p>

                    <h3>Description of Glitch</h3>
                    <p>
                        Glitch is an influencer marketing platform that connects gaming influencers and content creators. Our mission is to deliver the best ecosystem that connects all major stakeholders in gaming for commercial and creative growth.
                    </p>

                    <h3>You and Your Account</h3>
                    <p>
                        When creating an account at Glitch ("Account"), you warrant that the information provided by you is complete and accurate. You are furthermore solely responsible for safeguarding the password you use to access your Account at Glitch and for any activities or actions in your Account. You agree to notify us without delay in the event there is a risk of unauthorized access or use of your Account.
                    </p>

                    <h3>Minimum Age Requirement</h3>
                    <p>
                        You must be at least 13 years old to create an Account at Glitch or such minimum required age in your country which allows (I) for the collection of your personal data or (II) for you to agree to these Terms of Use. If we learn that personal data of persons less than 13 years old or the lesser age required in your country, has been collected on Glitch, we will take the appropriate steps to delete this information and delete your Account at Glitch.
                    </p>

                    <h3>Provided Services</h3>
                    <p>
                        Glitch provides various services and tools for you as a content creator ("Content Creator") such as:
                    </p>
                    <ul>
                        <li>Hosted Creator Page</li>
                        <li>Social media, stream, and video analytics</li>
                        <li>AI-marketing on Glitch</li>
                        <li>Access to Campaigns</li>
                    </ul>
                    <p>
                        It is noted by you that we may use your data in an aggregate manner for statistical purposes when we provide some of the above-mentioned services and tools. If you are interested in more information about how we process your data, please read our Privacy Policy.
                    </p>

                    <h3>User Content</h3>
                    <p>
                        Glitch presents user-generated content in the form of streams, videos, social media posts, and text news from users’ streaming, video, and social media networks and other information ("Content").
                    </p>
                    <p>
                        You represent that you have the right to use and share Content which you make available at Glitch, and that such Content, or its use by us as contemplated, does not violate these Terms of Use, the Terms of Use of any third party (such as YouTube), applicable law, or the intellectual property rights of others. By making the Content available, you especially represent and warrant that:
                    </p>
                    <ul>
                        <li>The Content does not contain or install any viruses, worms, malware, Trojan horses, or other harmful or destructive content;</li>
                        <li>The Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to third party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing); and</li>
                        <li>The Content is not pornographic, does not contain threats or incite violence, and does not violate the privacy or publicity rights of any third party.</li>
                    </ul>

                    <h3>Responsible Use and Conduct</h3>
                    <p>
                        Any action by you that, in our sole discretion: (i) violates the Terms of Use; (ii) restricts, inhibits or prevents any access, use, or enjoyment of Glitch; or (iii) through the use of your Account, defames, abuses, harasses, offends, or threatens, shall not be permitted, and may result in your loss of the right to access and use Glitch and/or your Account.
                    </p>
                    <p>
                        Glitch reserves the right to suspend or discontinue the availability of Glitch and its products and services, including but not limited to the Creator Program, and/or your Account or any portion or feature of the same at any time in our sole discretion and without prior notice. Your access to Glitch and/or your Account may furthermore be interrupted from time to time for any of several reasons, including, without limitation, the malfunction of equipment, periodic updating, maintenance or repair of Glitch or other actions that Glitch, in its sole discretion, may elect to take.
                    </p>

                    <h3>Campaigns</h3>
                    <p>
                        Glitch allows companies, such as publishers and developers, ("Companies") to submit requests for services to create content with Companies’ products or services on a platform provided on Glitch ("Campaigns"). Content Creators will have the possibility to accept or reject for performing the requested services within Campaigns. After joining a campaign, a Content Creator has 30 days to create and submit coverage for the game. If no content is submitted during the 30 days, the completion rate will decrease. The higher the completion rate is, the more likely it is to receive new game keys.
                    </p>

                    <h3>Hybrid Model</h3>
                    <p>
                        Our platform uses a hybrid model where content creators are paid based on performance metrics and/or a flat fee. Performance-based marketing means that content creators are paid on the results they produce. This includes views, shares, clicks, likes, installs, and other social engagement metrics that their content produces. The rates are determined by the publisher, game developer, or advertiser.
                    </p>
                    <p>
                        The hybrid model combines a flat fee and performance-based earnings. A flat fee can either be a set amount or calculated by multiplying the users' follower count by the rate per follower. This flat fee can be set by the publisher and/or game developer. The hybrid model includes the flat fee plus the performance-based earnings. Alternatively, the advertiser can set a flat fee without any performance-based earnings.
                    </p>

                    <h3>Rate Card</h3>
                    <p>
                        Advertisers set the rates for performance-based and flat fees using what is known as a rate card. The rate card has a default amount for all platforms and content created or streamed, but advertisers can also set different rates for different platforms such as TikTok, Reddit, Instagram, Facebook, Twitter, Twitch, and YouTube. The rate card is used for both short-form content (e.g., memes, short videos) and live streaming content.
                    </p>

                    <h3>Payment Terms</h3>
                    <p>
                        Payment is made to the influencer 7 days after the influencer has marked their campaign complete, or on a monthly basis for ongoing campaigns. If the influencer does not mark their campaign as complete, payment from the publisher to the influencer will be executed every 30 days from the day the influencer started the campaign. The pay will be based on the content created in the previous month and its performance. During this period, the advertiser can review and dispute content. The influencer will not be paid for content that is found to be fraudulent. Advertisers may be required to make a deposit of up to 75% of the campaign's total cost when they decide to hire an influencer through the platform. All payments to the influencer will take place through the platform.
                    </p>

                    <h3>Shared Ownership Rights</h3>
                    <p>
                        The advertiser will have shared ownership rights with the influencer of the content created through the platform and for campaigns. The advertiser can re-use and distribute the content as they wish.
                    </p>

                    <h3>Confidentiality</h3>
                    <p>
                        After performing the services or at the advertiser's request, the influencer shall deliver to the advertiser all materials in the influencer's possession relating to the advertiser's business. Any confidential information that the influencer accesses or develops in connection with the services shall be subject to the terms and conditions of this clause. The influencer agrees to treat all confidential information as strictly confidential, not to disclose confidential information or permit it to be disclosed in whole or part to any third party without the prior written consent of the advertiser, and not to use any confidential information for any purpose except as required in the performance of the services. The influencer shall notify the advertiser immediately in the event they become aware of any loss or disclosure of any confidential information.
                    </p>

                    <h3>Limitation of Warranties</h3>
                    <p>
                        You understand and agree that Glitch and its products and services, including but not limited to the Creator Program, are provided "as is" and "as available". This means that we do not represent or warrant to you that:
                    </p>
                    <ul>
                        <li>The use of Glitch will meet your needs or requirements;</li>
                        <li>The use of Glitch will be uninterrupted, timely, or free from errors;</li>
                        <li>The information obtained by using Glitch will be accurate or reliable, and any defects in the operation or functionality of any Glitch we provide will be repaired or corrected.</li>
                    </ul>

                    <h3>Limitation of Liability</h3>
                    <p>
                        Under no circumstances will Glitch be liable for your content, including, but not limited to, any errors or omissions in any content, any loss or damage of any kind incurred as a result of any content posted, emailed, transmitted, or otherwise made available by you in your account or elsewhere. Glitch will furthermore not be liable for that Glitch and its products and services (I) will meet your needs or requirements, (II) will be uninterrupted, timely, or free from errors, (III) accurate or reliable, or (IV) is changed and/or dissolved due to that a platform such as Twitch and YouTube have changed and/or restricted Glitch’s use of applicable public APIs to create the content.
                    </p>
                    <p>
                        Glitch shall furthermore under no circumstances be liable to you for any indirect damages, including but not limited to, loss of profit, loss of goodwill or reputation, loss of opportunity, or loss of data. The total liability of Glitch shall also be limited to a maximum amount equivalent to the fees paid under these Terms of Use during the twelve-month period preceding the action giving rise to the applicable damages.
                    </p>

                    <h3>Indemnification</h3>
                    <p>
                        You agree to indemnify and defend Glitch from and against all claims, actions, proceedings, costs (including reasonable legal fees), expenses, losses, damages (other than consequential damages), and liabilities arising from any claim by a third party regarding the content publicly posted by you in your account or elsewhere that violates any civil or criminal law or regulation or infringes such third party’s intellectual property rights.
                    </p>

                    <h3>Links</h3>
                    <p>
                        Glitch may contain links to third-party websites or resources. Under no circumstances will Glitch be responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by Glitch of such websites or resources or the content, products, or services available from such websites or resources.
                    </p>
                    <p>
                        You are solely responsible for all risks arising from any use of such websites or resources and/or any amendment of links to such third-party websites or resources to your account.
                    </p>

                    <h3>Privacy</h3>
                    <p>
                        Your privacy is very important to us. Please read our Privacy Policy where we explain in detail how we process your personal data.
                    </p>

                    <h3>Entire Agreement</h3>
                    <p>
                        These Terms of Use are the entire and exclusive agreement between Glitch and you regarding Glitch.
                    </p>
                    <p>
                        Glitch reserves the right to update, amend, and/or change these Terms of Use at any time in our sole discretion and without notice. Updates to these Terms of Use will be posted on www.glitch.com. Amendments will take effect immediately upon us posting the updated Terms of Use. You are encouraged to revisit the Terms of Use from time to time in order to review if any changes have been made.
                    </p>

                    <h3>Termination of Terms of Use</h3>
                    <p>
                        These Terms of Use will continue to apply until terminated by either you, through deactivating your account and/or discontinuing your use of Glitch, or by Glitch at any time in its sole discretion and without prior notice.
                    </p>

                    <h3>Governing Law</h3>
                    <p>
                        This Agreement shall be governed by and construed in accordance with the laws of Delaware, excluding its conflict of laws principles providing for the application of the laws of any other jurisdiction.
                    </p>

                    <h3>Contact Information</h3>
                    <p>
                        If you have any questions about these Terms of Use, you can contact us at support@glitch.com.
                    </p>

                </div>
            </Fragment>
        );
    }
}

export default TermsPage;