import { Component } from "react";
import React from 'react';
import { Link } from "react-router-dom";
import Navigate from "../../../../util/Navigate";


const subtitle = "For Publishers and Indie Developers";

const title = "All-In-One Game Marketing Solution";
const desc = "From influencers to social media and engaging the press, get all your game marketing needs met.";


let AboutListContent = [
    {
        imgUrl: '/assets/images/about/icon-1.png',
        imgAlt: 'Market Across All Social Platforms',
        title: 'Market Across All Social Platforms',
        desc: 'Leverage our automated marketing tools to grow your presence on X (formerly Twitter), Instagram, TikTok, Reddit, BlueSky, and more, driving consistent wishlists and game purchases.',
    },
    {
        imgUrl: '/assets/images/about/artificial-intelligence.png',
        imgAlt: 'Connect With Influencers & Creators',
        title: 'Connect With Influencers & Creators',
        desc: 'Achieve explosive growth and exposure in your game marketing by connecting with influencers and creators who will promote your game across all social platforms.',
    },
    {
        imgUrl: '/assets/images/about/chart-circle.png',
        imgAlt: 'Media Kits, Newsletters & Testers',
        title: 'Press Kits, Newsletters & Testers',
        desc: 'Effortlessly create sleek press kits to engage with journalists, blogs, and podcasts. Let your fans sign up for your newsletter, and recruit testers to perfect your game.',
    },
]



class AboutSectionPublishers extends Component {
    render() { 
        const {imgUrl} = this.props;
        return (
            <section className="about-section">
                <div className="container">
                    <div className="section-wrapper padding-top">
                        <div className="row">
                            
                            <div className="col-lg-6 col-md-10">
                                <div className="about-wrapper">
                                    <div className="section-header">
                                        <p>{subtitle}</p>
                                        <h2>{title}</h2>
                                    </div>
                                    <div className="about-content">
                                        <p>{desc}</p>
                                        <ul className="about-list">
                                            {AboutListContent.map((val, i) => (
                                                <li className="about-item d-flex flex-wrap" key={i}>
                                                    <div className="about-item-thumb">
                                                        <img 
                                                            src={`${val.imgUrl}`} 
                                                            alt={`${val.imgAlt}`}
                                                        />
                                                    </div>
                                                    <div className="about-item-content">
                                                        <h5>{val.title}</h5>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="text-start mt-4 mb-4">
                                            <Link to={Navigate.publishersOverviewPage()} className="default-button reverse-effect mr-2" data-rel="lightcase"><span>Learn More <i className="icofont-play-alt-1"></i></span> </Link>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Link to={Navigate.publishersOnboardingStep1Page()} className="default-button"><span>Register <i className="icofont-circled-right"></i></span> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-image">
                                    <img src={imgUrl} alt="about-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default AboutSectionPublishers;