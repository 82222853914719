import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMoneyBillWave, faBroadcastTower, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import SignUpCreators from "../../component/section/signup_creators";
import CreatorAppDownload from "../../component/section/creators/creator_app_download";
import Footer from "../../component/layout/footer";
import CreatorLinks from "../../component/section/creators/creator_links";

class CreatorsOptimizationPage extends Component {

    render() {
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <>
                <Helmet prioritizeSeoTags>
                    <meta charSet="utf-8" />
                    <title>Optimized Streaming App for Gamers | Glitch Gaming</title>
                    <meta name="description" content="Discover the ultimate streaming app optimized for gamers with advanced features and settings. Take your streaming to the next level with Glitch." />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Optimized Streaming App for Gamers | Glitch" />
                    <meta property="og:description" content="Discover the ultimate streaming app optimized for gamers with advanced features and settings. Take your streaming to the next level with Glitch." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:image" content="https://www.glitch.fun/assets/images/creators/customization_banner.webp" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Optimized Streaming App for Gamers | Glitch" />
                    <meta name="twitter:description" content="Discover the ultimate streaming app optimized for gamers with advanced features and settings. Take your streaming to the next level with Glitch." />
                    <meta name="twitter:image" content="https://www.glitch.fun/assets/images/creators/customization_banner.webp" />
                </Helmet>

                <Fragment>
                    <Header />
                    <section className="pageheader-section text-center text-uppercase" style={{ backgroundImage: "url(/assets/images/pageheader/bg.jpg)", padding: "100px 0" }}>
                        <div className="container">
                            <div className="section-wrapper">
                                <br /><br /><br /><br />
                                <img style={{ maxHeight: '160px' }} className="rounded mb-4" src="/assets/images/creators/custom_computer.webp" alt="Multicasting Feature" />
                                <h2 className="pageheader-title">Master Your Streaming Experience</h2>
                                <p className="lead">Unlock complete control over your streams with our advanced features.</p>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-dark text-white">
                        <div className="container">
                            <div className="section-header text-center">
                                <h2>Customize Your Streaming Settings</h2>
                                <p className="lead">Perfect for power users, fine-tune every aspect of your streaming setup.</p>
                            </div>
                            <div className="row text-center mt-4">
                                <div className="col-md-4 mb-4">
                                    <img src="/assets/images/creators/4k.png" alt="Stream Resolutions" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                                    <h4>Resolutions</h4>
                                    <p>Choose from 720p, 1080p, 1440p, and more for optimal quality.</p>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <img src="/assets/images/creators/desktop-computer.png" alt="FPS Settings" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                                    <h4>Frames Per Second</h4>
                                    <p>Adjust your FPS from 10 to 120 for smooth streaming.</p>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <img src="/assets/images/creators/quality.png" alt="Stream Quality" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                                    <h4>Definition</h4>
                                    <p>Stream in HD and High Dynamic Range (HDR) for stunning visuals.</p>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <img src="/assets/images/creators/encoding.png" alt="Encoding Options" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                                    <h4>Multiple Encoders</h4>
                                    <p>Utilize various encoders like libopenh264, libx264, Nvenc, and more.</p>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <img src="/assets/images/creators/gpu.png" alt="GPU or CPU Usage" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                                    <h4>GPU or CPU</h4>
                                    <p>Optimize resource usage by selecting either GPU or CPU for streaming.</p>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <img src="/assets/images/creators/audio.png" alt="Audio Codecs" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                                    <h4>Audio Codecs</h4>
                                    <p>Choose between PCM and OPUS for superior sound quality.</p>
                                </div>
                            </div>
                            <form className="contact-form mt-4" id="contact-form" method="POST">
                                <div className="form-group w-100 text-center">
                                    <a className="default-button" href="#download"><span>Download The App</span></a>
                                </div>
                            </form>
                            <div className="text-center mt-4">
                                <p>Adjust your stream settings to perfection.</p>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/low_cpu.gif" alt="Low Resource Utilization" className="img-fluid rounded shadow mb-2" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">Low Resource Utilization</h2>
                                    <p className="lead">With around 1% CPU utilization, our app frees up resources for your game.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6 order-lg-2">
                                    <img src="/assets/images/creators/codecs.gif" alt="Various Codecs" className="img-fluid rounded shadow" />
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="mb-4 text-black">Versatile Codec Support</h2>
                                    <p className="lead">From AVI, VP8, VP9, and more, choose the best codec for your streams.</p>
                                    <form className="contact-form mt-4" id="contact-form" method="POST">
                                        <div className="form-group w-100 text-center">
                                            <a className="default-button reverse-effect" href="#download"><span>Download For Free</span></a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-gradient text-white">
                        <div className="container">
                            <div className="section-header text-center mb-5">
                                <h2>Customize Graphics Layers</h2>
                                <p className="lead">Support your games with a variety of graphic libraries like GL, Swift Shader, Angle, Gles Metal, X11, Wayland, and more.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <img src="/assets/images/creators/customization.gif" alt="Custom Graphics Layers" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <CreatorLinks />

                    <CreatorAppDownload />

                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default CreatorsOptimizationPage;
