import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet-async';

const PublisherTutorialInvitePipelinesPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>How to Manage Influencer Stages in Your Pipeline | Influencer Marketing Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to effectively manage influencer stages in your marketing pipeline with this step-by-step tutorial. Understand the stages from contacting to finalizing, ensuring a smooth process."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Manage Influencer Stages in Your Pipeline | Influencer Marketing Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial covers managing influencer stages within a marketing pipeline, guiding you from the initial contact to the finalization of details."
                />
                
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Manage Influencer Stages in Your Pipeline | Influencer Marketing Tutorial" />
                <meta
                    name="twitter:description"
                    content="Step-by-step tutorial on managing influencer stages in your pipeline, ensuring effective communication and project finalization."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Manage Influencer Stages in Your Pipeline</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/O2v-Joie56U"
                                title="Influencer Marketing Tutorial"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            Welcome! In this tutorial, we’ll walk you through the essential steps to effectively manage influencer stages within your marketing pipeline.
                            Securing influencers to promote your game involves a structured process, often referred to as a pipeline or sales funnel. Each stage in this process
                            represents how close an influencer is to starting their marketing efforts on your behalf.
                        </p>

                        <h4>Stage 1: Contacting</h4>
                        <p>
                            The first stage is 'Contacting.' Here, you'll reach out to influencers to initiate a conversation. Keep in mind that it may take multiple attempts to get a response,
                            if they respond at all. Patience and persistence are key in this initial stage.
                        </p>

                        <h4>Stage 2: Negotiation</h4>
                        <p>
                            Once an influencer responds, they move to the 'Negotiation' stage. Typically, the influencer will either express interest, decline the offer, or request more information. 
                            During this stage, you'll discuss the details of the collaboration, ensuring both parties are clear on expectations.
                        </p>

                        <h4>Stage 3: Contracting</h4>
                        <p>
                            The 'Contracting' stage involves formalizing the agreement. While Glitch's terms of service cover most agreements, some influencers may request a separate contract. 
                            In these cases, you will negotiate and finalize the contract to ensure it's suitable for both parties. 
                        </p>

                        <h4>Stage 4: Finalizing the Details</h4>
                        <p>
                            Even after agreeing on the contract, there might be additional details to finalize. This could involve coordinating timelines, asset delivery, or any last-minute adjustments. 
                            Ensuring all details are clear will help avoid any misunderstandings later.
                        </p>

                        <h4>Stage 5: Finished</h4>
                        <p>
                            The 'Finished' stage signifies that the influencer has started working on the content. At this point, you can focus on monitoring the campaign's progress and making any necessary adjustments.
                        </p>

                        <h4>Stage 6: Lost</h4>
                        <p>
                            The 'Lost' stage is where an influencer declines the opportunity or when both parties cannot reach an agreement. While this can be disappointing, it's a natural part of the process.
                            Understanding where and why an influencer was lost can help improve your approach in future campaigns.
                        </p>

                        <p>
                            By managing these stages, you can efficiently track influencer progress, identify potential drop-off points, and optimize your strategy for better results. Happy hunting!
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialInvitePipelinesPage;
