import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMoneyBillWave, faBroadcastTower, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import SignUpCreators from "../../component/section/signup_creators";
import CreatorAppDownload from "../../component/section/creators/creator_app_download";
import Footer from "../../component/layout/footer";
import CreatorLinks from "../../component/section/creators/creator_links";

class CreatorsMulticatingPage extends Component {

    render() {
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <>
                <Helmet prioritizeSeoTags>
                    <meta charSet="utf-8" />
                    <title>Multicast to Multiple Platforms | Glitch Gaming</title>
                    <meta name="description" content="Easily stream to Twitch, YouTube, TikTok, and more simultaneously with our one-click setup. Engage your audience across multiple platforms without the hassle of complex RTMP configurations." />
                    <meta name="robots" content="index, follow" />
                    <meta property="og:title" content="Multicast to Multiple Platforms | Glitch" />
                    <meta property="og:description" content="Easily stream to Twitch, YouTube, TikTok, and more simultaneously with our one-click setup. Engage your audience across multiple platforms without the hassle of complex RTMP configurations." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={currentUrl} />
                    <meta property="og:image" content="https://www.glitch.fun/assets/images/creators/multicasting_2.webp" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Multicast to Multiple Platforms | Glitch" />
                    <meta name="twitter:description" content="Easily stream to Twitch, YouTube, TikTok, and more simultaneously with our one-click setup. Engage your audience across multiple platforms without the hassle of complex RTMP configurations." />
                    <meta name="twitter:image" content="https://www.glitch.fun/assets/images/creators/multicasting_2.webp" />
                </Helmet>

                <Fragment>
                    <Header />
                    <section className="pageheader-section text-center text-uppercase" style={{ backgroundImage: "url(/assets/images/pageheader/bg.jpg)", padding: "100px 0" }}>
                        <div className="container">
                            <div className="section-wrapper">
                                <br /><br /><br /><br />
                                <img style={{ maxHeight: '160px' }} className="rounded mb-4" src="/assets/images/creators/mutlicasting_1.webp" alt="Multicasting Feature" />
                                <h2 className="pageheader-title">Multicast To Many Social Platforms</h2>
                                <p className="lead">Engage audiences on multiple different platforms by restreaming your content</p>
                            </div>
                        </div>
                    </section>

                    <section className="py-5">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6">
                                    <img src="/assets/images/creators/one_click_multistreamig.gif" alt="One Click Stream Setup" className="img-fluid rounded shadow mb-2" />
                                </div>
                                <div className="col-lg-6">
                                    <h2 className="mb-4">One Click Stream Setup</h2>
                                    <p className="lead">Create a stream to any social platform with one click, avoiding complex RTMP setups.</p>
                                    <form className="contact-form mt-4" id="contact-form" method="POST">
                                        <div className="form-group w-100 text-center">
                                        <a className="default-button" href="#download"><span>Download The App</span></a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-light text-black">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-6 order-lg-2">
                                    {['twitch', 'facebook', 'youtube', 'trovo', 'kick', 'tiktok'].map(platform => (
                                        <img src={`/assets/images/creators/${platform}.png`} alt={`${platform} Icon`} className="img-fluid mb-2 m-2" style={{ width: '4rem', height: '4rem' }} key={platform} />
                                    ))}
                                </div>
                                <div className="col-lg-6 order-lg-1">
                                    <h2 className="mb-4 text-black">Stream Up To 5 Platforms Simultaneously</h2>
                                    <p className="lead">Stream to multiple platforms at the same time to engage your audience wherever they choose to watch and interact.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="py-5 bg-gradient text-white">
                        <div className="container">
                            <div className="section-header text-center mb-5">
                                <h2>Manage Multiple Streams At The Same Time</h2>
                                <p className="lead">Monitor chats, comments, tips, and other user engagement actions from multiple sources on one page as you multicast to several platforms.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <img src="/assets/images/creators/engagement_streams.gif" alt="Manage Multiple Streams" className="img-fluid rounded shadow" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <CreatorLinks />

                    <CreatorAppDownload />

                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default CreatorsMulticatingPage;
