import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faUsers, faChartLine, faRobot, faVideo, faImage, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

function PublisherDevlogPage() {
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Turn Your Dev Logs Into Marketing Content | Glitch Gaming</title>
                <meta
                    name="description"
                    content="Balance development and marketing effortlessly by turning dev logs into engaging social media content with the Social Scheduler."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Turn Your Dev Logs Into Marketing Content | Glitch"  />
                <meta property="og:description" content="Balance development and marketing effortlessly by turning dev logs into engaging social media content with the Social Scheduler."  />
                <meta property="og:type" content="website" data-rh="true"  />
                <meta property="og:url" content={currentUrl} data-rh="true"  />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/devlog_banner.webp" data-rh="true"  />
            </Helmet>
            <Header />
            <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 className='mt-5' style={{ fontSize: "2.5rem" }} >Turn Dev Logs Into Marketing Magic</h1>
                <p className="lead strong"><strong>Effortlessly grow your audience while focusing on game development.</strong></p>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-7">
                            <img
                                src="/assets/images/publishers/game_devs_dont_market.jpg"
                                alt="Dev Log to Social"
                                className="img-fluid rounded shadow mb-3 border border-white border-2"
                            />
                        </div>
                        <div className="col-lg-5">
                            <h2 className="mb-4">Marketing Is Crucial For Games</h2>
                            <p className="lead">
                                54% of game developers spend no time on marketing, leading to poor launches. The Social Scheduler makes it easy to promote your game consistently while you focus on development.
                            </p>
                            <ul className="list-unstyled lead">
                                <li>
                                    <FontAwesomeIcon icon={faRobot} className="text-primary me-2" />
                                    Drive wishlist sign-ups
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faBullhorn} className="text-success me-2" />
                                    Generate content for multiple platforms
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faUsers} className="text-warning me-2" />
                                    Collaborate with team members
                                </li>
                            </ul>
                            <Link className="default-button reverse-effect mt-3" to={Navigate.publishersOnboardingStep1Page()}><span>Start Using the Scheduler</span></Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-2 text-black">We Turn Dev Logs Into Social Content</h2>
                    <p className='text-black text-center lead'><u>How It Works</u></p>
                    <div className="row text-center text-black">
                        <div className="col-md-6 mb-4">
                            <img src="/assets/images/publishers/scheduler_submit.png" className='img-fluid w-50' />
                            <h4 className='text-black'>Step 1: You Submit An Update</h4>
                            <p>Once a week, submit a short update of a new feature, fixed bug, progress, etc.</p>
                        </div>
                        <div className="col-md-6 mb-4">
                            <img src="/assets/images/publishers/scheduler_results.png" className='img-fluid w-50' />
                            <h4 className='text-black'>Step 2: We Create The Content</h4>
                            <p>We turn that update into an engaging post and share it on all social platforms.</p>
                        </div>

                    </div>
                    <h3 className='text-black text-center mt-5'><img className='img-fluid me-2 mr-2' style={{ width: '35px' }} src="/assets/images/publishers/clock.png" /> 5 Minutes Once A Week Is All It Takes!</h3>
                </div>
            </section>
            <div className="container  pt-5 rounded mt-2 mb-5" key={'key3'}>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center"></div>

                    <div className="d-flex justify-content-center align-items-center mb-2" >
                        <h2 className="w-50 text-center">Your Content Will Go To All The Major Platforms</h2>

                    </div>

                    <div className="col-12 d-flex justify-content-center">
                        <img src={'/assets/images/creators/youtube.png'} alt="YouTube Icon" className="img-fluid mb-2 m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/tiktok.png" alt="TikTok" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/reddit.png" alt="Reddit" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/facebook.png" alt="Facebook" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/twitter.png" alt="Twitter" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/youtube.png" alt="YouTube" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/instagram.png" alt="Instagram" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                    </div>
                </div>
            </div>

            <div className="container text-center mb-5">
                <h4>Watch How It Works Below</h4>
                <div className="ratio ratio-16x9 border border-white border-2 mb-4">
                    <iframe
                        src="https://www.youtube.com/embed/7yKPRmbr66Y"
                        allowFullScreen
                        title="Dev Log Promo Video"
                    ></iframe>
                </div>
                <Link className="default-button reverse-effect mt-3" to={Navigate.publishersOnboardingStep1Page()}><span>Get Started for Free</span></Link>

            </div>

            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-5 text-black">Features Designed for Developers</h2>
                    <div className="row text-center text-black">
                        <div className="col-md-4 mb-4">
                            <FontAwesomeIcon icon={faCalendarAlt} size="3x" className="text-primary mb-3" />
                            <h4 className='text-black'>Weekly Reminders</h4>
                            <p>Receive timely email reminders to keep your dev logs up-to-date.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <FontAwesomeIcon icon={faImage} size="3x" className="text-success mb-3" />
                            <h4 className='text-black'>Visual Content</h4>
                            <p>Upload images or videos to make your updates more engaging.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <FontAwesomeIcon icon={faChartLine} size="3x" className="text-warning mb-3" />
                            <h4 className='text-black'>Audience Insights</h4>
                            <p>Target specific demographics with personalized content.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="mb-5 pt-4 text-center bg-light rounded" style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}>
                <div className='container'>
                    <h2 className="mb-4">Gamified Motivation</h2>
                    <FontAwesomeIcon icon={faChartLine} size="3x" className="mb-3 text-success" />
                    <p className="lead">We’ve turned the process of posting on social media into a game, complete with weekly goals to keep you motivated and guide you on what to do.</p>
                    <img src="/assets/images/publishers/gamified_social_media.png" alt="Platform Utilization" className="img-fluid rounded shadow border border-white border-2 " />

                    <form className="contact-form mt-4" id="contact-form" method="POST">
                        <div className="form-group w-100 text-center">
                            <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Sign Up And Use For Free</span></Link>
                        </div>
                    </form>
                </div>
            </div>


            <IndieSubmenu />

            <div className='pt-5 pb-5 bg-dark text-white text-center rounded' style={{ backgroundImage: "url(/assets/images/video/bg.jpg)" }}>
                <h2 className="text-danger mb-4">Learn More Strategies In Our Blog </h2>
                <div className='container'>
                    <p className="lead">Start marketing early in the development process by building in public and transforming dev logs into promotional material. Read our blog below to take a deep dive into builing in public and devlogs.</p>
                </div>
                <Link to={"https://glitch.ghost.io/how-to-makert-your-game-with-devlogs-and-building-in-public/"} target='_blank' className="btn btn-lg btn-danger text-white">Read Full Article</Link>

            </div>


            <Footer />
        </>
    );
}

const bannerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/devlog_banner.webp")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
};

export default PublisherDevlogPage;
