import { Component } from "react";
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import Navigate from "../../../../util/Navigate";
import Glitch from 'glitch-javascript-sdk';
import withRouter from "../../../../util/withRouter";

let SocialMideaList = []

if (process.env.REACT_APP_SOCIAL_FACEBOOK_PAGE) {
    SocialMideaList.push({
        IconName: 'icofont-facebook',
        IconLink: process.env.REACT_APP_SOCIAL_FACEBOOK_PAGE,
    });
}

if (process.env.REACT_APP_SOCIAL_INSTAGRAM_PAGE) {
    SocialMideaList.push({
        IconName: 'icofont-instagram',
        IconLink: process.env.REACT_APP_SOCIAL_INSTAGRAM_PAGE,
    });
}

if (process.env.REACT_APP_SOCIAL_GITHUB_PAGE) {
    SocialMideaList.push({
        IconName: 'icofont-github',
        IconLink: process.env.REACT_APP_SOCIAL_GITHUB_PAGE,
    });
}

if (process.env.REACT_APP_SOCIAL_TWITTER_PAGE) {
    SocialMideaList.push({
        IconName: 'icofont-twitter',
        IconLink: process.env.REACT_APP_SOCIAL_TWITTER_PAGE,
    });
}

if (process.env.REACT_APP_SOCIAL_YOUTUBE_PAGE) {
    SocialMideaList.push({
        IconName: 'icofont-youtube',
        IconLink: process.env.REACT_APP_SOCIAL_YOUTUBE_PAGE,
    });
}

class PublisherHeader extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    menuTrigger() {
        document.querySelector('.menu')?.classList?.toggle('active')
        document.querySelector('.header-bar')?.classList?.toggle('active')
    }
    menuTriggerTwo() {
        document.querySelector('.header-top')?.classList?.toggle('open')
        // document.querySelector('.header-bar')?.classList.toggle('active')
    }

    pathToRegex(path) {
        return new RegExp(`^${path.replace(/:[^\s/]+/g, '([^/]+)')}$`);
    }

    // Helper method to check if the current route matches any of the provided paths
    isActive(paths) {
        const { location } = this.props.router;
        return paths.some(path => this.pathToRegex(path).test(location.pathname));
    }

    render() {

        if(typeof window !== 'undefined'){
            window?.addEventListener('scroll', function () {
                var value = window?.scrollY;
                if (value > 200) {
                    //document.querySelector('.header-section')?.classList?.add(['header-fixed'], ['fadeInUp'])
                } else if (document.querySelector('.header-section') != null) {
                    //document.querySelector('.header-section')?.classList?.remove(['header-fixed'], ['fadeInUp'])
                }
            });
        }

        let loginOrAccount = '';

        let loginOrAccountMobile = '';

        if (Glitch.util.Session.isLoggedIn()) {
            loginOrAccount = (<>
                <Link to={Navigate.publishersTutorialsPage()} style={{ padding: '13px', backgroundColor: 'red' }} className="help"><i className="fas fa-question"></i> </Link>
                <Link to={Navigate.publishersMessagesListPage()} className="messages"><i className="fas fa-comments"></i> </Link>
                <Link to={Navigate.accountMainPage()} className="login"><i className="icofont-user"></i> <span>Account</span> </Link>
                <Link onClick={(e) => { e.preventDefault(); Glitch.util.Session.end(); window.location = Navigate.homePage() }} className="signup"><i className="fas fa-sign-out-alt"></i> <span>Logout</span></Link>

            </>);

            loginOrAccountMobile = (<>
                <li className="d-block d-sm-none" >
                    <Link to={Navigate.publishersMessagesListPage()} >Messages</Link>
                </li>
                <li className="d-block d-sm-none" >
                    <Link to={Navigate.accountMainPage()} >Account</Link>
                </li>
                <li className="d-block d-sm-none" >
                    <Link to={"#"} onClick={(e) => { e.preventDefault(); Glitch.util.Session.end(); window.location = Navigate.homePage() }} >Logout</Link>
                </li>

            </>);
        } else {
            loginOrAccount = (<>
                <Link to={Navigate.authLogin()} className="login"><i className="icofont-user"></i> <span>LOG IN</span> </Link>
                <Link to={Navigate.authRegister()} className="signup"><i className="icofont-users"></i> <span>SIGN UP</span></Link>
            </>);

            loginOrAccountMobile = (<>
                <li className="d-block d-sm-none" >
                    <Link to={Navigate.authLogin()} >Login</Link>
                </li>
                <li className="d-block d-sm-none" >
                    <Link to={Navigate.authRegister()} >Register</Link>
                </li>

            </>);

        }

        const { position = 'absolute' } = this.props;

        return (
            <header className="header-section" style={{ position }}>
                <div className="container-fluid">
                    <div className="header-holder d-flex flex-wrap justify-content-between align-items-center">
                        <div className="brand-logo d-none d-lg-inline-block">
                            <div className="logo">
                                <Link to="/">
                                    <img className="img-fluid" width="150px" src="/assets/images/logo/glitch_3.png" alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="header-menu-part">
                            <div className="header-top">
                                <div className="header-top-area">
                                    <ul className="left">
                                        <li>
                                            <span><u><h5>Publisher Dashboard</h5></u></span>
                                        </li>
                                        <li>
                                            <span><Link className="btn btn-primary btn-sm" to={Navigate.influencersMyCampaignsPage()}>Influencer Dashboard</Link></span>
                                        </li>
                                        <li>
                                            <span><Link className="btn btn-primary btn-sm" to={Navigate.publishersDashboardPage()}>Publishers Dashboard</Link></span>
                                        </li>

                                    </ul>
                                    <ul className="social-icons d-flex align-items-center">
                                        {SocialMideaList.map((val, i) => (
                                            <li key={i}>
                                                <a href={`${val.IconLink}`} className="fb"><i className={`${val.IconName}`}></i></a>
                                            </li>
                                        ))}
                                    </ul>
                                    <div>
                                        {loginOrAccount}
                                    </div>
                                </div>
                            </div>
                            <div className="header-bottom">
                                <div className="header-wrapper justify-content-lg-end">
                                    <div className="mobile-logo d-lg-none">
                                        <Link to="/"><img src="/assets/images/logo/glitch_3.png" alt="logo" /></Link>

                                    </div>
                                    <h2 className="d-lg-none" style={{ display: 'inline-block' }}>Publisher Dasbhoard</h2>
                                    <div className="menu-area">
                                        <ul className="menu">
                                            <li className={this.isActive([Navigate.campaignsPage(), Navigate.campaignsCreatePage(), Navigate.campaignsFindInfluencers(), Navigate.campaignsContentPage(), Navigate.campaignsContractsInfluencer(), Navigate.campaignsUpdateLinksPage(), Navigate.campaignsInviteInfluencer(), Navigate.campaignsLedgerPage(), Navigate.campaignsViewPage(), Navigate.campaignsUpdatePage(), Navigate.campaignsManageInfluencer(), Navigate.campaignsViewInfluencer(), Navigate.campaignsInfluencersPage(), Navigate.campaignsRecommendedInfluencers(), Navigate.campaignsInvitesPage(), Navigate.campaignsStatisticsPage(), Navigate.campaignsStartPage(), Navigate.campaignsResearchInfluencer(), Navigate.campaignsPerformanceInfluencer(), Navigate.campaignsUpdateInfluencerInviteCompensation(), Navigate.campaignsUpdateInfluencerInviteContract(), Navigate.campaignsUpdateInfluencer(), Navigate.campaignsPaymentInfluencer(), Navigate.campaignsViewInfluencerInvite(), Navigate.campaignsUpdateInfluencerInviteContract(), Navigate.campaignsUpdateInfluencerInviteCompensation()]) ? 'active' : ''} >
                                                <Link to={Navigate.campaignsPage()} >Influencers</Link>
                                            </li>
                                            <li className={this.isActive([Navigate.schedulerListPage(), Navigate.schedulerCreatePage(), Navigate.schedulerInvitePage(), Navigate.schedulerPostsPage(), Navigate.schedulerUpdatePage(), Navigate.schedulerUpdatesCreatePage(), Navigate.schedulerUpdatesListPage(), Navigate.schedulerUpdatesViewPage(), Navigate.schedulerUsersPage(), Navigate.schedulerViewPage(), Navigate.schedulerUpdatesUpdatePage(), Navigate.schedulerSocialsPage(), Navigate.schedulerTonePage(), Navigate.schedulerReportsPage(), Navigate.schedulerBrandingPage(), Navigate.schedulerGoalsPage(), Navigate.schedulerPostsViewPage()]) ? 'active' : ''} >
                                                <Link to={Navigate.schedulerListPage()} >Social</Link>
                                            </li>
                                            <li className={this.isActive([Navigate.playtestingPublishersListPage(), Navigate.playtestingPublishersCreatePage(), Navigate.playtestingPublishersDeletePage(), Navigate.playtestingPublishersInvitePage(), Navigate.playtestingPublishersSharePage(), Navigate.playtestingPublishersListPage(), Navigate.playtestingPublishersTestersPage(), Navigate.playtestingPublishersViewTestPage(), Navigate.playtestingPublishersViewQuestionsPage(), Navigate.playtestingPublishersViewReviewPage(), Navigate.playtestingPublishersPreviewPage(), Navigate.playtestingPublishersViewPage(), Navigate.playtestingPublishersMessagesPage()]) ? 'active' : ''} >
                                                <Link to={Navigate.playtestingPublishersListPage()} >Testers</Link>
                                            </li>
                                            <li className={this.isActive([Navigate.newsletterListPage(), Navigate.newsletterCampaignCreatePage(), Navigate.newsletterCampaignListPage(), Navigate.newsletterCampaignSendPage(), Navigate.newsletterCampaignUpdatePage(), Navigate.newsletterCampaignViewPage(), Navigate.newsletterCreatePage(), Navigate.newsletterImportPage(), Navigate.newsletterSubscribePage(), Navigate.newsletterSubscribersPage(), Navigate.newsletterUpdatePage(), Navigate.newsletterViewPage()]) ? 'active' : ''} >
                                                <Link to={Navigate.newsletterListPage()} >Newsletters</Link>
                                            </li>
                                            <li className={this.isActive([Navigate.mediakitsPublishersListPage(), Navigate.mediakitsPublishersUpdatePage(), Navigate.mediakitsPublishersSharePage(), Navigate.mediakitsPublishersCreatePage(), Navigate.mediakitsPublishersViewPage()]) ? 'active' : ''} >
                                                <Link to={Navigate.mediakitsPublishersListPage()} >Media Kits</Link>
                                            </li>
                                            <li className={this.isActive([Navigate.reportsMainPage(), Navigate.reportsCodeTitlePage(), Navigate.reportsListCommunitiesPage(), Navigate.reportsOAuthTitlePage(), Navigate.reportsTokensTitlePage(), Navigate.reportsUploadTitlePage(), Navigate.reportsViewTitlePage()]) ? 'active' : ''} >
                                                <Link to={Navigate.reportsMainPage()} >Reports</Link>
                                            </li>
                                            <li className={this.isActive([Navigate.communitiesAdminListPage(), Navigate.communitiesAccessibilityPage(), Navigate.communitiesAdminListPage(), Navigate.communitiesCardsPage(), Navigate.communitiesCnamePage(), Navigate.communitiesContractstPage(), Navigate.communitiesCreatePage(), Navigate.communitiesCssPage(), Navigate.communitiesDeletePage(), Navigate.communitiesEmailTemplatesPage(), Navigate.communitiesFeaturesPage(), Navigate.communitiesManagePage(), Navigate.communitiesMediaPage(), Navigate.communitiesOverviewPage(), Navigate.communitiesSocialPage(), Navigate.communitiesUpdatePage(), Navigate.communitiesUsersInvitePage(), Navigate.communitiesUsersListPage(), Navigate.communitiesUsersManagePage(), Navigate.communitiesViewPage(), Navigate.communitiesSubscriptionsPage(), Navigate.communitiesSubscribePage()]) ? 'active' : ''}>
                                                <Link to={Navigate.communitiesAdminListPage()} >Communities</Link>
                                            </li>

                                            {loginOrAccountMobile}
                                        </ul>


                                        <div className="header-bar d-lg-none" onClick={this.menuTrigger}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                        <div className="ellepsis-bar d-lg-none" onClick={this.menuTriggerTwo}>
                                            <i className="icofont-info-square"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(PublisherHeader);
