import React from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function PublisherOverviewPage() {
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet prioritizeSeoTags>
        {/* ========== SEO Optimization ========== */}
        <meta charSet="utf-8" />
        <title>All-in-One Game Marketing Overview | Glitch Gaming</title>
        <meta
          name="description"
          content="Explore Glitch’s comprehensive suite of marketing solutions for game developers and publishers. From influencer campaigns to social scheduling, we have everything you need to market your game."
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="All-in-One Game Marketing Overview | Glitch" />
        <meta
          property="og:description"
          content="Explore Glitch’s comprehensive suite of marketing solutions for game developers and publishers. From influencer campaigns to social scheduling, we have everything you need to market your game."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/banner_1.png" />
      </Helmet>

      <Header />

      {/* ========== Hero/Banner Section ========== */}
      <section
        className="container-fluid text-center text-white py-5"
        style={bannerStyle}
      >
        <h1 className="mt-5" style={{ fontSize: '2.5rem' }}>
          The Ultimate Marketing Hub For Your Game
        </h1>
        <p className="lead">
          Everything you need to build, engage, and grow your game’s audience.
        </p>
      </section>

      {/* ========== Brief Intro Section ========== */}
      <section className="py-5" style={{ backgroundColor: '#232a5c' }}>
        <div className="container text-center text-white">
          <h2 className="mb-4">Why Choose Glitch?</h2>
          <p className="lead">
            Glitch is a one-stop platform built to help game developers and publishers
            overcome the biggest marketing hurdles. Whether you’re a solo indie or a large studio,
            we unify all key marketing tools under one roof—so you can focus on making games, not
            juggling endless apps.
          </p>
        </div>
      </section>

      {/* ========== Core Features Section ========== */}
      <section className="py-5 bg-dark">
        <div className="container">
          <h2 className="text-center text-white mb-5">Core Features</h2>
          <div className="row text-center">
            {/* Feature 1 */}
            <div className="col-md-4 mb-4">
              <div className="card bg-transparent border-0 h-100 text-white">
                <div className="card-body">
                  <h4>Influencer CRM & Database</h4>
                  <img src="/assets/images/publishers/overview-crm.png" style={{maxHeight: '200px'}} className='img-fluid rounded' />
                  <p>
                    Discover, track, and manage thousands of creator relationships with our
                    outreach tools and performance analytics.
                  </p>
                  <Link className="default-button reverse-effect mt-3" to={Navigate.publishersCRMPage()}>
                    <span>Read More</span>
                  </Link>
                </div>
              </div>
            </div>
            {/* Feature 2 */}
            <div className="col-md-4 mb-4">
              <div className="card bg-transparent border-0 h-100 text-white">
                <div className="card-body">
                  <h4>Social Scheduler & Devlogs</h4>
                  <img src="/assets/images/publishers/overview-social.jpg" style={{maxHeight: '200px'}} className='img-fluid rounded' />
                  <p>
                    Automate your social content with our weekly scheduler. Turn dev logs into
                    multiple social posts in just minutes.
                  </p>
                  <Link className="default-button reverse-effect mt-3" to={Navigate.publishersSocialPage()}>
                    <span>Learn More</span>
                  </Link>
                </div>
              </div>
            </div>
            {/* Feature 3 */}
            <div className="col-md-4 mb-4">
              <div className="card bg-transparent border-0 h-100 text-white">
                <div className="card-body">
                  <h4>Play Tester Database</h4>
                  <img src="/assets/images/publishers/overview-testers.png" style={{maxHeight: '200px'}} className='img-fluid rounded' />
                  <p>
                    Gather honest feedback before launch with our built-in network of avid gamers
                    eager to playtest.
                  </p>
                  <Link className="default-button reverse-effect mt-3" to={Navigate.publishersPlayTesterPage()}>
                    <span>Details Here</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-center mt-4">
            {/* Feature 4 */}
            <div className="col-md-4 mb-4">
              <div className="card bg-transparent border-0 h-100 text-white">
                <div className="card-body">
                  <h4>Media Kits</h4>
                  <img src="/assets/images/publishers/overview-press.jpg" style={{maxHeight: '200px'}} className='img-fluid rounded' />
                  <p>
                  Impress journalists and podcasters with a sleek media kits for your game that are created in a matter of minutes.
                  </p>
                  <Link className="default-button reverse-effect mt-3" to={Navigate.publishersMediakitPage()}>
                    <span>View Media Kits</span>
                  </Link>
                </div>
              </div>
            </div>
            {/* Feature 5 */}
            <div className="col-md-4 mb-4">
              <div className="card bg-transparent border-0 h-100 text-white">
                <div className="card-body">
                  <h4>Community Platform</h4>
                  <img src="/assets/images/publishers/overview-community.jpg" style={{maxHeight: '200px'}} className='img-fluid rounded' />
                  <p>
                    Build your fanbase with dedicated community tools—live streaming, short-form
                    content, user engagement, and more.
                  </p>
                  <Link className="default-button reverse-effect mt-3" to={Navigate.publishersCommunityPage()}>
                    <span>Check It Out</span>
                  </Link>
                </div>
              </div>
            </div>
            {/* Feature 6 */}
            <div className="col-md-4 mb-4">
              <div className="card bg-transparent border-0 h-100 text-white">
                <div className="card-body">
                  <h4>Newsletter</h4>
                  <img src="/assets/images/publishers/overview-newsletter.jpg" style={{maxHeight: '200px'}} className='img-fluid rounded' />
                  <p>
                    Keep players in the loop with newsletters, with direct access to their contact information and inboxes.
                  </p>
                  <Link className="default-button reverse-effect mt-3" to={Navigate.publishersNewsletterPage()}>
                    <span>See Newsletters</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========== CTA Section ========== */}
      <section className="py-5">
        <div className="container text-center">
          <h2 className="mb-4">Ready to Level Up Your Marketing?</h2>
          <p className="lead">
            Sign up for free and discover how Glitch can transform your game’s reach. We’re here to
            streamline your marketing efforts so you can pour your time into creating the next
            gaming masterpiece.
          </p>
          <Link
            className="default-button reverse-effect mt-3"
            to={Navigate.publishersOnboardingStep1Page()}
          >
            <span>Create Your Free Account</span>
          </Link>
        </div>
      </section>

      <Footer />
    </>
  );
}

const bannerStyle = {
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/banner_1.png")',
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
};

export default PublisherOverviewPage;
