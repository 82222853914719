import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet-async';

const PublisherTutorialInviteCustomEmailsPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>How to Send Custom Emails and Use Email Templates | Glitch Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to create and send custom email invites to influencers using personalized email templates on Glitch. This tutorial covers everything from template creation to sending custom invites."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Send Custom Emails and Use Email Templates | Glitch Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial explains how to send custom emails using personalized templates to reach influencers on Glitch."
                />
                
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Send Custom Emails and Use Email Templates | Glitch Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn how to create and send custom email invites to influencers using personalized email templates on Glitch."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Send Custom Emails and Use Email Templates</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/kYEWvGlDL7Q"
                                title="Sending Custom Emails with Email Templates"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            In this tutorial, we’ll show you how to send custom emails and utilize email templates when inviting influencers. Often, influencers don’t respond to the first contact, so follow-up emails are essential. Our system automates this process, sending up to five emails to maximize your chances of a response.
                        </p>

                        <h4>Creating and Using Custom Email Templates</h4>
                        <p>
                            If you prefer to use your own email templates while still benefiting from the automation provided by our platform, you can easily create custom templates. Begin by navigating to your communities’s dashboard, selecting your business account, and accessing the Email Templates section.
                        </p>

                        <p>
                            Click the ‘Create’ button to start building your template. Name the template and select “Email Template” as the type. You’ll be able to create both HTML and plain text versions, though the HTML version is typically sufficient. Use the placeholder fields to dynamically insert values, such as the game name or influencer details, which will be replaced when the email is sent.
                        </p>

                        <h4>Sending Custom Invites</h4>
                        <p>
                            After creating a template, head to either the ‘Find’ section or the ‘Recommended’ section of your campaign to select influencers. When you’ve identified an influencer, click the ‘Send Custom Invite’ button. Select your newly created template, and the placeholders will automatically be replaced with specific values for the campaign and influencer.
                        </p>

                        <h4>Automating the Follow-Up Process</h4>
                        <p>
                            Once you’ve sent the custom invite, the system will continue sending follow-up emails if the influencer hasn’t responded. Up to four additional emails will be generated and sent automatically until the influencer replies, ensuring that you stay top of mind for potential collaborators.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialInviteCustomEmailsPage;
