import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faUsers, faDatabase, faThumbsUp, faTools, faGamepad, faDollarSign, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Glitch from 'glitch-javascript-sdk';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';
import IndieSubmenu from '../../component/section/publishers/IndieSubmenu';

const PublisherGamePRPage = () => {

    const [publications, setPublications] = useState([]);
    const [filteredPublications, setFilteredPublications] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [game, setGame] = useState('');
    const [filterType, setFilterType] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [modalVisible, setModalVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        getPublications();
    }, []);

    const getPublications = () => {
        Glitch.api.Publications.list().then((response) => {
            setPublications(response.data.data);
            setFilteredPublications(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    };

    const handleDownload = () => {
        if (name && email) {
            Glitch.api.Publications.download({ name, email, game, format: 'xls' })
                .then(response => {
                    setModalVisible(false);
                    setSuccessMessage('The worksheet has been sent to your email.');
                })
                .catch(error => {
                    console.error('Error generating download link:', error);
                });
        }
    };

    const handleFilterChange = (type) => {
        setFilterType(type);
        const filtered = publications.filter(publication => publication.type === type);
        setFilteredPublications(filtered);
    };

    const handleSortChange = () => {
        const sorted = [...filteredPublications].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
        setFilteredPublications(sorted);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta charSet="utf-8" />
                <title>Find PR for Your Game | Glitch Gaming</title>
                <meta name="description" content="Find PR for your game by browsing through a curated list of gaming-related publications, blogs, and podcasts." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Find PR for Your Game | Glitch" />
                <meta property="og:description" content="Browse a curated list of gaming-related publications, blogs, and podcasts. Download contact and workflow details." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/press/press.webp" />
            </Helmet>
            <Header />
            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Find PR For Your Game For Free</h1>
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>
            <div className="container mt-5">
                <p className='lead'>Sort through the list of publications, podcasts and blogs to find PR for your game. Also download our workbooks to get access to the contact information, email and workflow spreadsheet.</p>

                {successMessage && (
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                )}

                <div className="mb-3">
                    <label htmlFor="filterType" className="form-label">Filter by Type</label>
                    <select id="filterType" className="form-select" value={filterType} onChange={e => handleFilterChange(e.target.value)}>
                        <option value="">All</option>
                        <option value="blog">Blog</option>
                        <option value="podcast">Podcast</option>
                        <option value="publication">Publication</option>
                    </select>
                </div>

                <div className='mb-3'>
                    <button className="btn btn-primary me-2" onClick={handleSortChange}><i className="fas fa-sort"></i> Sort by Name ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})</button>
                    <button className="btn btn-success" onClick={() => setModalVisible(true)}><i className="fas fa-download"></i> Download Workbook</button>
                </div>


                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>URL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPublications.map((publication, index) => (
                            <tr key={index}>
                                <td>{publication.name}</td>
                                <td>{publication.type}</td>
                                <td><a href={publication.url} target="_blank" rel="noopener noreferrer">{publication.url}</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>

            {modalVisible && (
                <div className="modal show d-block" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Download Worksheet (Will be emailed)</h5>
                                <button type="button" className="btn-close" onClick={() => setModalVisible(false)}></button>
                            </div>
                            <div className="modal-body text-black">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" id="name" className="form-control" value={name} onChange={e => setName(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" id="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="game" className="form-label">Game (Optional)</label>
                                    <input type="text" id="game" className="form-control" value={game} onChange={e => setGame(e.target.value)} />
                                </div>
                                <p className="text-muted">Note: The list will sent to your email and be updated once a month.</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={handleDownload}>Download</button>
                                <button className="btn btn-secondary" onClick={() => setModalVisible(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <IndieSubmenu />
            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/press/press.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublisherGamePRPage;
