import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { Helmet } from 'react-helmet-async';

const InfluencerTutorialsInvitesPage = () => {
    useEffect(() => {
        // Any useEffect logic if needed
    }, []);

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>How to Accept an Invitation on Glitch | Glitch Tutorials</title>
                <meta name="description" content="Learn how to accept an invitation on Glitch and start promoting games as an influencer. Step-by-step guide and video tutorial included." />
                <meta name="robots" content="index, follow" />
                <meta charset="utf-8" />
                <meta property="og:title" content="How to Accept an Invitation on Glitch | Glitch Tutorials" />
                <meta property="og:description" content="Discover the process of accepting an invitation on Glitch and becoming an influencer for games. Watch our video and follow the tutorial." />
                <meta property="og:type" content="article" />
                
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <Fragment>
                <InfluencerHeader position={"relative"} />
                <section className="pageheader-section-min">
                    <div className="container mt-2">
                        <Breadcrumbs
                            items={[
                                { name: 'Tutorials', link: Navigate.influencersTutorials() },
                                { name: `How To Accept An Invite`, link: Navigate.influencersTutorialInvites() },
                            ]}
                        />
                        <div className="section-wrapper text-center">
                            <div className="pageheader-thumb mb-4"></div>
                            <h2 className="pageheader-title">How To Handle Invites</h2>
                            <p className="lead">Learn how to respond to an invite to play and promote a game.</p>
                        </div>
                    </div>
                </section>

                <div className="container">

                    <div className="ratio ratio-16x9 mb-5 border border-white border-2">
                        <iframe
                            src="https://www.youtube.com/embed/u-qxijHM_Xw"
                            allowFullScreen
                            title="YouTube Video"
                        ></iframe>
                    </div>

                    <div className="tutorial-content">
                        <h3>Step-by-Step Guide to Accepting an Invitation on Glitch</h3>
                        <p>
                            Hello! In this tutorial, we’re going to go over how to accept an invitation on Glitch.
                        </p>
                        <p>
                            On Glitch, we connect game publishers and developers with influencers who can create content for their games. So, you may have received an invitation to promote a game.
                        </p>
                        <p>
                            Our invitations are pretty straightforward. They’ll tell you what game is being promoted, your potential earnings for that game, and what platforms you’ll be able to create content on.
                        </p>
                        <p>
                            If this sounds interesting to you, go ahead and click <strong>“Learn More”</strong> in the email. This will take you to the game’s page, where you can find more in-depth information about the game. There, you can watch trailers, look at images, and check out other aspects of the game.
                        </p>
                        <h4>Understanding Your Earnings</h4>
                        <p>
                            Next, you can learn more about your earning potential. Your earnings are estimated based on your follower count, engagement rate, and the rate card set by the game developer or publisher.
                        </p>
                        <p>
                            Let’s start with the rate card. The rate card specifies how much the developer is willing to pay, and it can be based on flat fees, performance-based metrics, or both. For example, they might offer a $500 flat fee and that’s it. Or they might offer a fee based on the metrics your content produces, such as shares, likes, clicks, comments, and installs. They could also offer a hybrid model of both flat fees and performance metrics.
                        </p>
                        <p>
                            The performance metrics have a mutually beneficial aspect; the longer your content produces results, the longer you earn revenue from it. This means that if people are still engaging with the content you produced three months later, you’re still making money from it, and the game is still getting publicity.
                        </p>
                        <p>
                            You may notice that payment rates differ for different platforms. Instagram may offer different payment amounts than TikTok, which may differ from Reddit and others.
                        </p>
                        <h4>Your Social Accounts</h4>
                        <p>
                            Now, with your social accounts, you have both a follower count and an engagement rate. Your potential earnings are calculated based on the rate card, your follower count, and your engagement rate. You can negotiate this, but we’ll get to that later.
                        </p>
                        <h4>Campaign Deliverables</h4>
                        <p>
                            You’ll also have the opportunity to learn about the expectations for you. The most important area to look at is the deliverables. This is what will be expected of you for the campaign to be considered complete. You can also view other requirements, such as hashtags, affiliate links, and other items.
                        </p>
                        <p>
                            If you have questions or want to make adjustments, you can respond to the invite email. The publisher or game developer can explain things further and make changes specifically for your campaign.
                        </p>
                        <h4>Finalizing Your Participation</h4>
                        <p>
                            Once everything is set, scroll to the bottom of the page and click the <strong>“Apply”</strong> button. If this is your first time, you’ll be brought to the registration process, which is simple, easy, and quick. If you’ve already registered, you just need to confirm your interest in the game, and that’s it!
                        </p>
                        <p>
                            You’re all set and ready to start playing the game and creating. Happy streaming!
                        </p>
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default InfluencerTutorialsInvitesPage;
