import React, { useState, useEffect } from 'react';

function CreatorAppDownload() {

    const trackDownload = () => {
        if (window.fbq) {
            window.fbq('track', 'Download');
        }
        if (window.ttq) {
            window.ttq.track('Download', {
                "contents": [
                    {
                        "content_id": "1.6.3",
                        "content_type": "product",
                        "content_name": "Glitch App"
                    }
                ],
                "value": 0,
                "currency": "USD"
            });
        }
    };
   

    return (
        <section className="about-team py-5 bg-gradient text-white" id="download">
                <div className="container">
                    <div className="section-header text-center">
                        <h2>Download Glitch Streaming Studio<br /> for Multiple Platforms</h2>
                        <p className="lead">Stream and record your games from your desktop with up to 120 FPS to capture every moment in vivid detail.</p>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="text-center m-3">
                            <a href="https://github.com/Glitch-Gaming-Platform/Glitch-Desktop-App">
                                <img src="/assets/images/creators/github.png" alt="Github Downloads" style={{ width: '100px' }} />
                                <p className="mt-2">Github Downloads</p>
                            </a>
                        </div>
                        <div className="text-center m-3">
                            <img src="/assets/images/creators/apple.png" alt="Mac Downloads" style={{ width: '100px' }} />
                            <ul className="list-unstyled mt-2">
                                <li><a href="https://github.com/Glitch-Gaming-Platform/Glitch-Desktop-App/releases/download/v1.8.5/Glitch-Streaming-Studio-1.8.5-arm64.dmg" onClick={trackDownload}>Mac Silicon Version (ARM)</a></li>
                                <li><a href="https://github.com/Glitch-Gaming-Platform/Glitch-Desktop-App/releases/download/v1.8.5/Glitch-Streaming-Studio-1.8.5.dmg" onClick={trackDownload}>Mac Intel Version (x86)</a></li>
                            </ul>
                        </div>
                        <div className="text-center m-3">
                        <a href="https://github.com/Glitch-Gaming-Platform/Glitch-Desktop-App/releases/download/v1.8.5/Glitch-Streaming-Studio-Web-Setup-1.8.5.exe" onClick={trackDownload}><img src="/assets/images/creators/windows.png" alt="Windows Downloads" style={{ width: '100px' }} /></a>
                            <ul className="list-unstyled mt-2">
                                <li><a href="https://github.com/Glitch-Gaming-Platform/Glitch-Desktop-App/releases/download/v1.8.5/Glitch-Streaming-Studio-Web-Setup-1.8.5.exe" onClick={trackDownload}>Windows Exe</a></li>
                            </ul>
                        </div>
                        <div className="text-center m-3">
                            <img src="/assets/images/creators/linux.png" alt="Linux Downloads" style={{ width: '100px' }} />
                            <ul className="list-unstyled mt-2">
                                <li><a href="https://github.com/Glitch-Gaming-Platform/Glitch-Desktop-App/releases/download/v1.8.5/Glitch-Streaming-Studio-1.8.5-arm64.AppImage" onClick={trackDownload}>Linux ARM App Image (AMD)</a></li>
                                <li><a href="https://github.com/Glitch-Gaming-Platform/Glitch-Desktop-App/releases/download/v1.8.5/Glitch-Streaming-Studio-1.8.5.AppImage" onClick={trackDownload}>Linux Intel App Image (x86)</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default CreatorAppDownload;
