import React, { useState, useEffect } from "react";
import Glitch from "glitch-javascript-sdk";
import RingLoader from "react-spinners/RingLoader";

// Import the default marketing template at the top so it's always available
import MarketingTemplate from "./templates/marketing";

let mixpanel;

const App = ({ data, routeData }) => {
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Dynamically import Mixpanel so it only loads in the browser
    const loadMixpanel = async () => {
      if (typeof window !== "undefined") {
        const { default: Mixpanel } = await import("mixpanel-browser");
        mixpanel = Mixpanel;
        mixpanel.init("e4566227541691bc68bebf9b291854ca", {
          debug: true, // Enable debug mode for verbose output
        });
      }
    };

    const loadSite = async () => {
      try {
        await loadMixpanel();

        let templateDir = null;

        // If SSR data (community) was provided, we don't need to re-fetch from domain
        if (data) {
          // The SSR server already fetched community data
          Glitch.config.Config.setCommunity(data);
          Glitch.util.Storage.set("community_id", data.id);
          Glitch.util.Storage.set("community", data);

          // If the community has a custom template
          if (data.template?.directory && data.template?.entry_point_file) {
            templateDir = data.template.directory + data.template.entry_point_file;
          }

          // If the community has custom CSS, inject it
          if (data.custom_css && typeof document !== "undefined") {
            const styleElement = document.createElement("style");
            styleElement.innerHTML = data.custom_css;
            document.head.appendChild(styleElement);
          }
        } else {
          // Fallback: if no SSR data (or user navigated client-side to a new domain)
          if (typeof window !== "undefined") {
            const domain = getDomain();

            console.log("domain", domain);

            // If domain is root or 'www', presumably the marketing template
            if (domain === "www" || domain === process.env.REACT_APP_SITE_DOMAIN) {
              setLoading(false);
            } else {
              // Else fetch from the API
              const response = await Glitch.api.Communities.findByDomain(domain);

              Glitch.config.Config.setCommunity(response.data.data);
              Glitch.util.Storage.set("community_id", response.data.data.id);
              Glitch.util.Storage.set("community", response.data.data);

              let community = response.data.data;
              templateDir = community?.template?.directory + community?.template?.entry_point_file;

              if (community.custom_css) {
                const styleElement = document.createElement("style");
                styleElement.innerHTML = community.custom_css;
                document.head.appendChild(styleElement);
              }
            }
          }
        }

        // Dynamically import the custom template if specified
        if (templateDir) {
          const loadedTemplate = await import(`${templateDir}`);
          setTemplate(() => loadedTemplate.default);
        } else {
          // Fallback to marketing template
          setTemplate(() => MarketingTemplate);
        }
      } catch (error) {
        console.error("Error loading template:", error);
        // If there's any error, fall back to marketing template
        setTemplate(() => MarketingTemplate);
      }

      setLoading(false);
    };

    loadSite();

    // Set up Mixpanel click/change tracking only in the browser
    if (typeof window !== "undefined") {
      const handleClick = (event) => {
        const target = event.target;
        if (target.matches("button, a")) {
          const eventData = {
            elementType: target.tagName,
            elementText: target.innerText || target.textContent,
            elementName: target.name || target.getAttribute("name"),
            elementId: target.id || target.getAttribute("id"),
            elementClass: target.className,
            href: target.href || target.getAttribute("href"),
          };
          mixpanel?.track("Element Clicked", eventData);
        }
      };

      const handleInputChange = (event) => {
        const target = event.target;
        if (target.matches("input, select, textarea")) {
          const eventData = {
            elementType: target.tagName,
            elementName: target.name || target.getAttribute("name"),
            elementId: target.id || target.getAttribute("id"),
            elementClass: target.className,
            value: target.value,
          };
          mixpanel?.track("Input Changed", eventData);
        }
      };

      document.addEventListener("click", handleClick);
      document.addEventListener("change", handleInputChange);
      document.addEventListener("input", handleInputChange);

      return () => {
        document.removeEventListener("click", handleClick);
        document.removeEventListener("change", handleInputChange);
        document.removeEventListener("input", handleInputChange);
      };
    }
  }, [data]);

  // Detect domain client-side
  function getDomain() {
    const currentDomain = window.location.hostname;

    if (currentDomain === process.env.REACT_APP_SITE_DOMAIN) {
      const wwwDomain = `www.${process.env.REACT_APP_SITE_DOMAIN}`;
      window.location.replace(
        `${window.location.protocol}//${wwwDomain}${window.location.pathname}${window.location.search}`
      );
      return "www";
    } else if (currentDomain.endsWith(process.env.REACT_APP_SITE_DOMAIN)) {
      const subdomain = currentDomain.split(".")[0];
      return subdomain;
    } else {
      return currentDomain;
    }
  }

  // Display loader if still waiting
  if (loading) {
    return (
      <RingLoader
        loading={loading}
        cssOverride={{ display: "block", margin: "auto", marginTop: "45%" }}
        size={60}
        color="#0dcaf0"
      />
    );
  }

  // If we have a loaded template, render it
  if (template) {
    return React.createElement(template, { data, routeData });
  }

  // Final fallback: Always use the marketing template
  return <MarketingTemplate data={data} routeData={routeData} />;
};

export default App;
